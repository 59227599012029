import React from 'react'
import DataTable from 'react-data-table-component'

import * as logger from '../../../services/Logger'

import './ListingTable.css'
import ExpandableComponent from './ExpandableComponent/ExpandableComponent'
import { Link } from 'react-router-dom'
import TableButtonsComponent from '../TableButtons/TableButtons'

const config = require('../../../config.json')

class ListingsTable extends React.PureComponent {
  premiumTitle = () => {
    if (this.props.subscriptionStatus) {
      return 'Premium'
    } else {
      return 'Free Trial'
    }
  }
  premiumColumn = (title) => {
    return {
      name: title,
      cell: () => (
        <Link
          className="free-trial txt-blue txt-clickable"
          to="/pricing"
          target={'_blank'}
          rel="noreferrer noopener"
        >
          {this.premiumTitle()}
        </Link>
      ),
      width: '110px',
    }
  }

  shouldShowFeature = () => {
    return this.props.hasPremiumAccess || this.props.dataRows.length === 0
  }

  columns = () => {
    let col = [
      {
        name: '#',
        selector: 'rowNumber',
        sortable: true,
        width: '70px',
      },
      {
        name: 'IMAGE',
        selector: 'image',
        sortable: false,
        cell: (row) => (
          <div>
            {row.image && (
              <img src={row.image} alt="" className="row-image"></img>
            )}
          </div>
        ),
        width: '80px',
      },
      {
        name: 'TITLE',
        selector: 'title',
        cell: (row) => (
          <div className="row-title">
            <a
              className="row-title"
              href={row.link}
              target={'_blank'}
              rel="noreferrer noopener"
            >
              {row.title}
            </a>
          </div>
        ),
        wrap: false,
        sortable: true,
        width: '275px',
      },
    ]
    // NEW

    if (this.shouldShowFeature()) {
      col.push(
        {
          name: 'MONTHLY SALES ESTIMATE',
          selector: 'monthlySalesEstimate',
          sortable: true,
          width: '80px',
        },
        {
          name: 'MONTHLY REVENUE ESTIMATE',
          selector: 'monthlyRevenueEstimate',
          sortable: true,
          width: '100px',
        }
      )
    } else {
      col.push(this.premiumColumn('MONTHLY SALES ESTIMATE'))
      col.push(this.premiumColumn('MONTHLY REVENUE ESTIMATE'))
    }

    col.push({
      name: 'DAYS LISTED',
      selector: 'daysSinceCreated',
      sortable: true,
      width: '70px',
    })

    if (config.ENABLE_ETSY_API_USE[config.VALUE_KEY] === true) {
      if (this.shouldShowFeature()) {
        col.push(
          {
            name: 'NUMBER OF TIMES VIEWED',
            selector: 'views',
            sortable: true,
            width: '80px',
          },
          {
            name: 'AVERAGE VIEWS PER DAY',
            selector: 'averageViewsPerDay',
            sortable: true,
            width: '70px',
          }
        )
      } else {
        // col.push(this.premiumColumn('Number of Times Favorited'))
        col.push(this.premiumColumn('NUMBER OF TIMES VIEWED'))
        col.push(this.premiumColumn('AVERAGE VIEWS PER DAY'))
      }
    }
    col.push(
      {
        name: 'NUMBER OF TIMES FAVORITED',
        selector: 'num_favorers',
        sortable: true,
        width: '85px',
      },
      {
        name: 'BESTSELLER',
        selector: 'bestseller',
        sortable: true,
        width: '80px',
      },
      {
        name: 'NUMBER IN CARTS',
        selector: 'interestedCount',
        sortable: true,
        width: '65px',
      }
    )

    if (this.shouldShowFeature()) {
      col.push({
        name: 'RATINGS FOR LISTINGS',
        selector: 'listingRatingCount',
        sortable: true,
        width: '75px',
      })
    } else {
      col.push(this.premiumColumn('RATINGS FOR LISTINGS'))
    }

    col.push(
      {
        name: 'PRICE',
        selector: 'price',
        sortable: true,
        width: '80px',
      },
      {
        name: 'AVAILABLE COUNT',
        selector: 'quantity',
        sortable: true,
        width: '80px',
      },
      {
        name: 'IS AD',
        selector: 'isAd',
        sortable: true,
        width: '65px',
      },
      {
        name: 'ON SALE',
        selector: 'onSale',
        sortable: true,
        width: '65px',
      }
    )
    return col
  }

  sortingThings = (rows, field, direction) => {
    logger.log('sort')
    // return rows
    // logger.log(rows)
    // logger.log(field)
    // logger.log(direction)

    const copy = [...rows]
    copy.sort((a, b) => {
      let v1 = a.rowNumber
      let v2 = b.rowNumber

      switch (field) {
        case 'rowNumber':
          v1 = a.rowNumber
          v2 = b.rowNumber
          break
        case 'num_favorers':
          v1 = this.intValueForSort(a.num_favorers)
          v2 = this.intValueForSort(b.num_favorers)
          break
        case 'quantity':
          v1 = this.intValueForSort(a.quantity)
          v2 = this.intValueForSort(b.quantity)
          break
        case 'views':
          v1 = this.intValueForSort(a.views)
          v2 = this.intValueForSort(b.views)
          break
        case 'tag_string':
          v1 = a.tag_string.toLowerCase()
          v2 = b.tag_string.toLowerCase()
          break

        case 'listingRatingCount':
          v1 = this.intValueForSort(a.listingRatingCount)
          v2 = this.intValueForSort(b.listingRatingCount)
          break
        case 'image':
          v1 = a.image
          v2 = b.image
          break
        case 'title':
          v1 = a.title.toLowerCase()
          v2 = b.title.toLowerCase()
          break
        case 'monthlySalesEstimate':
          v1 = this.intValueForSort(a.monthlySalesEstimate)
          v2 = this.intValueForSort(b.monthlySalesEstimate)
          break
        case 'monthlyRevenueEstimate':
          v1 = this.intValueForSort(a.monthlyRevenueEstimate)
          v2 = this.intValueForSort(b.monthlyRevenueEstimate)
          break
        case 'daysSinceCreated':
          v1 = this.intValueForSort(a.daysSinceCreated)
          v2 = this.intValueForSort(b.daysSinceCreated)
          break
        case 'averageViewsPerDay':
          v1 = this.intValueForSort(a.averageViewsPerDay)
          v2 = this.intValueForSort(b.averageViewsPerDay)
          break
        case 'isAd':
          v1 = a.isAd.toLowerCase()
          v2 = b.isAd.toLowerCase()
          break
        case 'shopName':
          v1 = a.shopName.toLowerCase()
          v2 = b.shopName.toLowerCase()
          break
        case 'storeRating':
          v1 = a.storeRating
          v2 = b.storeRating
          break
        case 'storeRatingCount':
          v1 = this.intValueForSort(a.storeRatingCount)
          v2 = this.intValueForSort(b.storeRatingCount)
          break
        case 'storeSales':
          v1 = this.intValueForSort(a.storeSales)
          v2 = this.intValueForSort(b.storeSales)
          break
        case 'price':
          v1 = this.intValueForSort(a.price)
          v2 = this.intValueForSort(b.price)
          break
        case 'originalPrice':
          v1 = this.intValueForSort(a.originalPrice)
          v2 = this.intValueForSort(b.originalPrice)
          break
        case 'salePercentage':
          v1 = a.salePercentage
          v2 = b.salePercentage
          break
        case 'bestseller':
          v1 = a.bestseller
          v2 = b.bestseller
          break
        case 'interestedCount':
          v1 = a.interestedCount
          v2 = b.interestedCount

          if (v1 === '20+') {
            v1 = 21
          } else if (v1 === '') {
            v1 = 0
          }

          if (v2 === '20+') {
            v2 = 21
          } else if (v2 === '') {
            v2 = 0
          }

          break
        case 'fewRemaining':
          v1 = a.fewRemaining
          v2 = b.fewRemaining
          break
        case 'shippingSpecial':
          v1 = a.shippingSpecial
          v2 = b.shippingSpecial
          break
        default:
          //   logger.error(`sort was on unhandled column value: ${field}`)

          v1 = a.rowNumber
          v2 = b.rowNumber
          break
      }
      //   logger.log(`v1:${v1}\tv2:${v2}`)

      const val =
        (v1 === v2 ? 0 : v1 < v2 ? -1 : 1) * (direction === 'desc' ? -1 : 1)
      //   logger.log(
      //     `sort row order:${direction}:\tv1:${v1}\tv2:${v2}\tval:${val}`
      //   )
      return val
    })
    return copy
  }

  intValueForSort = (val) => {
    if (val === '-') {
      return 0
    }
    val = parseInt(val.replace(/[^0-9.-]+/g, ''))
    if (isNaN(val)) {
      val = -1
    }
    return val
  }

  getCustomStyle = () => {
    const customStyles = {
      headCells: {
        style: {
          color: this.props.dataRows.length === 0 ? 'gray' : '#354264',
          fontSize: '9px',
        },
      },
      rows: {
        style: {
          height: '60px',
          color: '#354264',
        },
        selectedHighlightStyle: {
          background: 'gray',
        },
      },
    }
    return customStyles
  }

  emptyasdf = {
    rowNumber: '1',
  }

  emptyTable = () => {
    return (
      <DataTable
        className="table"
        noHeader
        fixedHeader
        highlightOnHover={false}
        data={[{}]}
        columns={this.columns()}
        customStyles={this.getCustomStyle()}
      ></DataTable>
    )
  }

  fullTable = () => {
    return (
      <DataTable
        className="table"
        noHeader
        fixedHeader
        pointerOnHover
        highlightOnHover
        // onRowClicked={(row) => this.props.onRowClick(row)}
        expandableRows
        expandableRowsComponent={
          <ExpandableComponent
            onRowClick={this.props.onRowClick}
            hasPremiumAccess={this.props.hasPremiumAccess}
            onSubscribePress={this.props.onSubscribeButtonPressed}
          />
        }
        expandableRowsHideExpander
        expandOnRowClicked
        onRowExpandToggled={(expanded, row) => {
          logger.log(`row expanded: ${expanded}`)
          logger.log(row)
          this.props.tracker.trackEvent({
            category: 'Event',
            actionName: `Expanded Row: ${row.rowNumber}\n\t${row.title}`,
            sendTelegram: true,
          })
        }}
        data={this.props.dataRows}
        columns={this.columns()}
        // fixedHeaderScrollHeight={`${this.props.height - 550}px`}
        sortFunction={this.sortingThings}
        customStyles={this.getCustomStyle()}
        noDataComponent={this.emptyTable()}
        // overflowY
      ></DataTable>
    )
  }

  LoadIndicator = () => {
    return (
      <div className="table-loading-indicator text-center">
        <div className="my-5">
          <div
            className="mx-auto spinner-border text-secondary"
            style={{ width: '3rem', height: '3rem' }}
            role="status"
          ></div>
        </div>
      </div>
    )
  }

  render() {
    // console.log('render in listings table')
    // console.log(this.props.dataRows)
    // Make sure search bar remains centered if there is no data
    // if (this.props.dataRows.length === 0) {
    //   return <div></div>
    // }

    return (
      <div className="container-fluid">
        <div
          className="mx-auto table-full-area"
          style={{
            maxWidth: this.shouldShowFeature() ? '1430px' : '1580px',
          }}
        >
          <div className="ml-auto table-above-area">
            <TableButtonsComponent
              onSaveToCSVPressed={this.props.onSaveToCSVPressed}
              onSubscribeButtonPressed={this.props.onSubscribeButtonPressed}
              onExtractTagsPressed={this.props.onExtractTagsPressed}
              hasPremiumAccess={this.props.hasPremiumAccess}
              shouldShowPlaceholder={this.props.dataRows.length === 0}
              tracker={this.props.tracker}
              buttonsEnabled={this.props.buttonsEnabled}
            ></TableButtonsComponent>
            <div className="ml-auto mr-1 my-auto txt-blue">
              {this.props.loadingString}
            </div>
          </div>
          <div className="table-area mx-auto">
            {this.fullTable()}
            {this.props.showSearchIsLoading && <this.LoadIndicator />}
          </div>
        </div>
      </div>
    )
  }
}

export default ListingsTable
