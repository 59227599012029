import React, { Component } from 'react'

import line_divider from '../../img/line_divider.png'
import ExpanderTextComponent from '../../components/ExpanderTextComponent/ExpanderTextComponent'
import { Container } from 'react-bootstrap'

class AffiliateFAQ extends Component {
  render() {
    return (
      <div className="content-section bg-light pricing-faq">
        <Container>
          <div className="text-center my-auto">
            <h2 className="ch2 txt-blue">Frequently Asked Questions</h2>
            <img className="my-4" src={line_divider} alt="divider"></img>
            <ExpanderTextComponent
              header={'What is the commission payout? How much can I earn?'}
            >
              We currently offer a 50% recurring commission for the life of the
              customer. With membership priced at $19/mo, you would earn $114
              for a single customer if they stayed active for a year. A 100
              customers would be $11,400 - you can see how these commissions can
              really add up!
            </ExpanderTextComponent>
            <ExpanderTextComponent header={'How long is your cookie life?'}>
              We currently offer a 30-day cookie life. This means from link
              click to close of sale, if your referral purchases within 30 days
              (without clicking someone else's link), you're the affiliate of
              record. Once they've purchased, you continue to receive
              commissions until they end their membership.
            </ExpanderTextComponent>
            <ExpanderTextComponent
              header={'Can I cancel my subscription at any time?'}
            >
              You sure can. Your access to the premium features will last until
              the next pay period at which point your access will downgrade to
              the free plan.
            </ExpanderTextComponent>
            <ExpanderTextComponent header={'How do you track conversions?'}>
              Conversions are tracked through your affiliate link provided by
              our affiliate tracking software, Rewardful.
            </ExpanderTextComponent>
          </div>
        </Container>
      </div>
    )
  }
}

export default AffiliateFAQ
