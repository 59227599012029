import React, { useEffect, useState } from 'react'

import * as logger from '../../../services/Logger'

import { handleConnectToEtsy } from '../../../services/EtsyOAuthService'

import { useAuth } from '../../../services/contexts/AuthContext'

import { SignInModal } from '../SignIn/SignInModal'

import progress1 from '../../../img/progress1.png'
import progress2 from '../../../img/progress2.png'
import progress3 from '../../../img/progress3.png'
import mascot from '../../../img/mascot.png'
import sheerbaName from '../../../img/sheerba2.png'
import googleG from '../../../img/google-g.png'

import background from '../../../img/BlueSwooshBackground.png'

import history from '../../../history'

import './LoggedOut.css'
import { LoggedOutPresenter } from './LoggedOutPresenter'
import { LoggedOutInteractor } from './LoggedOutInteractor'
import { Container } from 'react-bootstrap'
// import * as tracker from '../../Services/Tracker'
import * as firebase from "../../../services/Authentication/firebase";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ViewStateEnum = Object.freeze({
  MAIN: 1,
  SIGNIN: 2,
  REGISTER: 3,
  // OAUTH_DONE: 12,
  // EXPLAIN_FUTURE_CHART: 13,
  // EXPLAIN_GOOGLE_CHART: 14,
  // FEEDBACK: 15,
})

const LoggedOut = () => {
  let interactor
  let presenter

  const [viewState, setViewState] = useState(ViewStateEnum.MAIN)

  const { currentUser, etsyToken } = useAuth()

  const { popup, tracker } = useAuth()

  useEffect(() => {
    tracker.trackEvent({
      category: 'View',
      actionName: 'Login Page',
      sendTelegram: true,
    })
  }, [tracker])

  const setupVIP = () => {
    presenter = new LoggedOutPresenter()
    interactor = new LoggedOutInteractor()

    interactor.presenter = presenter
    presenter.view = this
  }

  // constructor(props) {
  //   super(props)

  //   this.state = {
  //     step: 1,
  //     viewState: ViewStateEnum.MAIN,
  //   }

  //   this.setupVIP()
  // }

  const handleLoginButton = () => {
    tracker.trackEvent({
      category: 'Event',
      actionName: 'Login - Show Sign-in',
      sendTelegram: true,
    })
    setViewState(ViewStateEnum.SIGNIN)
  }

  const handleRegisterButton = () => {
    tracker.trackEvent({
      category: 'Event',
      actionName: 'Login - Show Register',
      sendTelegram: true,
    })
    setViewState(ViewStateEnum.REGISTER)
  }

  const handleSecondModalClose = () => {
    setViewState(ViewStateEnum.MAIN)
  }

  const handleSuccessfulLogin = () => {
    setViewState(ViewStateEnum.MAIN)
    // setStep(3)
  }

  const handleSuccessfulRegistration = () => {
    // save first sign in time
    // setStep(2)
  }

  const handleConnectToEtsyPressed = async () => {
    handleConnectToEtsy({
      tracker: tracker,
      email: currentUser.email,
    })
  }

  const onContinuePress = () => {
    history.push('/tag-search')
  }

  const handlePopup = () => {
    tracker.trackEvent({
      category: 'Event',
      actionName: 'Login - Show Google Popup',
      sendTelegram: true,
    })
    popup()
  }

  const ProgressStageComponent = (props) => {
    return (
      <Container>
        <div className="stage-main-area mt-5">
          <div className="stage-logo-section">
            <img className="logged-out-mascot" src={mascot} alt=""></img>
            <img className="logged-out-logo" src={sheerbaName} alt=""></img>
          </div>
          <div className="progress-positioning">{props.children}</div>
        </div>
      </Container>
    )
  }

  const stepOne = () => {
    // logger.log('stepOne');
    // logger.log(this.props.googleG);
    return (
      <ProgressStageComponent>
        <img
          src={progress1}
          className="progress-section"
          alt="progress-1"
        ></img>
        <div className="step-1-title">Step 1</div>
        <div className="step-1-description">Log In / Sign Up</div>
        <div className="log-in-button" onClick={handleLoginButton}>
          Log In
        </div>
        <div className="register-button" onClick={handleRegisterButton}>
          Register
        </div>
        <div className="google-button" onClick={handlePopup}>
          <img className="google-icon" src={googleG} alt=""></img> Log In with
          Google
        </div>
      </ProgressStageComponent>
    )
  }

  const stepTwo = () => {
    return (
      <ProgressStageComponent>
        <img
          src={progress2}
          className="progress-section"
          alt="progress-2"
        ></img>
        <div className="step-1-title">Step 2</div>
        <div className="step-1-description">Connect your Etsy Account</div>
        <div className="step-1-further-description">
          This is needed to retrieve further information from Etsy that isn't
          available except by having a connected Etsy Account.
        </div>
        <div className="log-in-button" onClick={handleConnectToEtsyPressed}>
          Connect Etsy
        </div>
      </ProgressStageComponent>
    )
  }

  const stepThree = () => {
    return (
      <ProgressStageComponent>
        <img
          src={progress3}
          className="progress-section"
          alt="progress-3"
        ></img>
        <div className="step-1-title">Step 3</div>
        <div className="step-1-description">
          Use the tool to level up your results
        </div>
        <div className="log-in-button" onClick={onContinuePress}>
          Continue
        </div>
      </ProgressStageComponent>
    )
  }

  const getSecondaryModal = () => {
    if (viewState === ViewStateEnum.SIGNIN && !currentUser) {
      logger.log('show sign in')
      return (
        <SignInModal
          handleClose={handleSecondModalClose}
          onSuccessfulLogin={handleSuccessfulLogin}
          onSuccessfulRegistration={(user, name) =>
            handleSuccessfulRegistration(user, name)
          }
          beginAtSignInState={true}
        ></SignInModal>
      )
    } else if (viewState === ViewStateEnum.REGISTER && !currentUser) {
      logger.log('register modal')
      return (
        <SignInModal
          handleClose={handleSecondModalClose}
          onSuccessfulLogin={handleSuccessfulLogin}
          onSuccessfulRegistration={(user, name) =>
            handleSuccessfulRegistration(user, name)
          }
          beginAtSignInState={false}
        ></SignInModal>
      )
    }
  }

  React.useEffect(() => {
    // Check if the email link is valid
    console.log("email check started!", currentUser)
    if (firebase.auth.isSignInWithEmailLink(window.location.href)) {
      // Get the email address from local storage
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        // If the email address is not found, display an error message
        console.log('Error: Email address not found.');
        return;
      }

      // Verify the sign-in link and sign in the user
      firebase.auth.signInWithEmailLink(email, window.location.href)
        .then(() => {
          // Clear the email address from local storage
          window.localStorage.removeItem('emailForSignIn');
          console.log('Sign-in successful!');
          toast.success('Sign in successful!', {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
        })
        .catch((error) => {
          console.log(`Error signing in: ${error.message}`);
          toast.error(`Error signing in: ${error.message}`);
        });
    }
  }, []);

  const mainPage = () => {
    if (currentUser == null) {
      return stepOne()
    } else if (etsyToken == null) {
      return stepTwo()
    } else {
      return stepThree()
    }
  }

  setupVIP()

  logger.log(`Logged out. currentUser: ${currentUser}, etsyToken: ${etsyToken}`)

  // tracker.pageview('not-logged-in')
  // logger.log('render logged out on step:')
  // logger.log(this.props.step)
  return (
    <div className="logged-out-container">
      <img
        src={background}
        alt="background"
        className="logged-out-background"
      ></img>
      {getSecondaryModal()}
      {mainPage()}
    </div>
  )
}
export default LoggedOut
