import React from 'react'
import { Container } from 'react-bootstrap'

import './FurtherQuestions.css'
import illustration from './Illustration.png'

const FurtherQuestionsComponent = (props) => {
  return (
    <Container>
      <div className="further-questions my-5">
        <div className="further-questions-area m-auto row">
          <div className="col-12 col-lg-3 text-center text-lg-left mt-5 mb-sm-5">
            <img className="" src={illustration} alt="illustration"></img>
          </div>
          <div className="col-12 col-lg-9 my-5">
            <div className="txt-white ch3 mb-4 mt-lg-2 text-center">
              Have Further Questions?
            </div>
            <div className="m-auto w-75 w-sm-25 text-center">
              <a
                className="btn-dark-blue btn-large"
                href="mailto:hello@sheerba.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default FurtherQuestionsComponent
