import React, { Component } from 'react'

import line_divider from '../../../img/line_divider.png'
import ExpanderTextComponent from '../../../components/ExpanderTextComponent/ExpanderTextComponent'
import { Container } from 'react-bootstrap'

export default class PricingFAQ extends Component {
  render() {
    return (
      <Container>
        <div className="text-center my-auto">
          <h2 className="ch2 txt-blue">Frequently Asked Questions</h2>
          <img className="mb-2" src={line_divider} alt="divider"></img>
          <ExpanderTextComponent
            header={'Do I need to sign up with my credit card?'}
          >
            Download and use the free version without any futher signup
            required.
          </ExpanderTextComponent>
          <ExpanderTextComponent header={'Where do I upgrade to Premium?'}>
            Conveniently upgrade right from the Sheerba tool itself.
          </ExpanderTextComponent>
          <ExpanderTextComponent
            header={'Can I cancel my subscription at any time?'}
          >
            You sure can. Your access to the premium features will last until
            the next pay period at which point your access will downgrade to the
            free plan.
          </ExpanderTextComponent>
          <ExpanderTextComponent header={'What is your refund policy?'}>
            Signing up for Premium access comes with a 7 day free trial. You can
            cancel within this period for any reason and you will not be charged
            one cent. You can cancel a plan for any reason whenever you want.
            You will not be billed again and you will have access to the Premium
            features until the next billing period, at which point your access
            will drop to the free plan.
          </ExpanderTextComponent>
          <ExpanderTextComponent header={'How are payments processed?'}>
            Payments are processed securely through Stripe, keeping your payment
            information locked tight. All major credit cards are accepted: VISA,
            VISA Debit, MasterCard, Discover, JCB, American Express.
          </ExpanderTextComponent>
        </div>
      </Container>
    )
  }
}
