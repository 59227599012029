const config = require('../config.json')

export function log(message) {
  if (config.SHOW_LOGS[config.VALUE_KEY] === true) console.log(message)
}

export function error(message) {
  if (config.SHOW_LOGS[config.VALUE_KEY] === true) {
    console.error(message)
  }
}
