import React from 'react'
import { withRouter } from 'react-router-dom'

import sheerba from '../../img/sheerba2.png'
import icon from '../../img/icon128.png'

import './Navbar.css'
// import pdf from '../resources/The Complete Guide to Etsy Product Research.pdf'

const Navigation = (props) => {
  // console.log(`navigation: ${document.URL}`)

  const user = localStorage.getItem('user')

  let pricing = false
  let account = false
  let search = false
  let home = false

  if (document.URL.indexOf('/pricing') > 0) {
    pricing = true
  } else if (document.URL.indexOf('/account') > 0) {
    account = true
  } else if (document.URL.indexOf('/tag-search') > 0) {
    search = true
  } else {
    home = true
  }

  const getLinks = () => {
    if (!search || user == null) {
      return (
        <ul className="navbar-nav mr-auto">
          {/* <li className={`nav-item ${home ? 'active' : ''}`}>
                <a className="nav-link" href="/">
                  Home <span className="sr-only">(current)</span>
                </a>
              </li> */}
          <li className={`nav-item`}>
            <a className=" mx-5 nav-link custom-nav-item" href="/tag-search">
              Search
            </a>
          </li>
          <li className={`nav-item ${pricing ? 'active' : ''}`}>
            <a className=" mx-5 nav-link custom-nav-item" href="/pricing">
              Pricing
            </a>
          </li>
        </ul>
      )
    }
  }

  return (
    <div>
      <nav className="navbar navbar-expand-md navbar-light bg-light">
        <a className="navbar-brand" href="/">
          <img
            alt=""
            src={icon}
            height="60"
            width="60"
            // className="d-inline-block align-top"
          />
          <img
            alt=""
            src={sheerba}
            height="60"
            width="140"
            // className="d-inline-block align-top"
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          {getLinks()}
          <ul className="navbar-nav ml-auto">
            <li className={`nav-item ${account ? 'active' : ''}`}>
              <a
                className="blue-button login-button btn-text-h4"
                href="/account"
              >
                Account
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  )
}

export default withRouter(Navigation)

// // dropdown button
// <li class="nav-item dropdown">
// <a
//   class="nav-link dropdown-toggle"
//   href="#"
//   id="navbarDropdown"
//   role="button"
//   data-toggle="dropdown"
//   aria-haspopup="true"
//   aria-expanded="false"
// >
//   Dropdown
// </a>
// <div class="dropdown-menu" aria-labelledby="navbarDropdown">
//   <a class="dropdown-item" href="#">
//     Action
//   </a>
//   <a class="dropdown-item" href="#">
//     Another action
//   </a>
//   <div class="dropdown-divider"></div>
//   <a class="dropdown-item" href="#">
//     Something else here
//   </a>
// </div>
// </li>

// Search Bar
// <form class="form-inline my-2 my-lg-0">
//   <input
//     class="form-control mr-sm-2"
//     type="search"
//     placeholder="Search"
//     aria-label="Search"
//   ></input>
//   <button class="btn btn-outline-success my-2 my-sm-0" type="submit">
//     Search
//   </button>
// </form>

// Disabled
// <li class="nav-item">
//   <a class="nav-link disabled" href="#">
//     Disabled
//   </a>
// </li>
