import React, { Component } from 'react'
import { Container } from 'react-bootstrap'
import Feature from './Feature'

export default class PricingFeatures extends Component {
  render() {
    return (
      <Container>
        <div
          className="text-center my-5 row justify-content-center"
          // style={{ backgroundColor: 'red' }}
        >
          <Feature
            imageSrc={require('../../../img/icons8-search-50.png')}
            title="Most Data, Anywhere!"
            description="The Most information in one spot for Etsy listings you can
              find ANYWHERE."
          ></Feature>
          <Feature
            imageSrc={require('../../../img/icons8-puzzle-50.png')}
            title="Only the important data"
            description="Only the information that matters. Cut through the noise and
              understand the big picture."
          ></Feature>
          <Feature
            imageSrc={require('../../../img/icons8-info-50.png')}
            title="Tag Analysis"
            description="Tag information on listings you can't find through normal
              searching."
          ></Feature>
          <Feature
            imageSrc={require('../../../img/icons8-binoculars-50.png')}
            title="View and Favorite Counts"
            description="Views and Favorer counts for all listings to gain deeper
              insight into what peple actually are wanting."
          ></Feature>
          <Feature
            imageSrc={require('../../../img/icons8-opened-folder-50.png')}
            title="Data Export"
            description="Conveniently be able to export all the data to a spreadsheet
              for later."
          ></Feature>
          <Feature
            imageSrc={require('../../../img/icons8-news-50.png')}
            title="Tag Extractor"
            description="Extract all the tags of the listings with the click of a
              button."
          ></Feature>
          <Feature
            imageSrc={require('../../../img/icons8-sun-50.png')}
            title="Search Forecast"
            description="Machine Learning powered 12 month forecast on every search.
              Know when you can expect the most demand."
          ></Feature>
          <Feature
            imageSrc={require('../../../img/icons8-box-50.png')}
            title="Historic Traffic"
            description="Examine years of previous search demand as well."
          ></Feature>
          <Feature
            imageSrc={require('../../../img/icons8-checked-50.png')}
            title="Choose the Winners"
            description="Be more confident than ever that you truly know what will sell."
          ></Feature>
        </div>
      </Container>
    )
  }
}
