import React, { PureComponent } from 'react'
import ListingsTable from '../../components/Duplicated/ListingTable/ListingsTable'
import TagSearchBar from './SearchBar/TagSearchBar'

import './TagSearchView.css'
import AnalyticDataComponent from '../../components/Duplicated/AnalyticDataComponent/AnalyticDataComponent'
import { Footer } from '../../components/Footer/Footer'

export class TagSearchView extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      height: '',
    }
    this.onResize = this.onResize.bind(this)
    this.onResize()
  }

  getWindowHeight() {
    return Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    )
  }
  onResize() {
    window.requestAnimationFrame(() => {
      const height = this.getWindowHeight()
      this.setState({
        height: height,
      })
    })
  }

  componentDidMount() {
    this.setState({
      height: this.getWindowHeight(),
    })
    window.addEventListener('resize', this.onResize)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize)
  }

  render() {
    // console.log('view render')
    // console.log(this.props.error)
    return (
      <div
        className="search-page d-flex flex-column"
        style={{ minHeight: '95vh' }}
      >
        {!this.props.etsyIsConnected && (
          <div className="alert alert-danger row" role="alert">
            <div className="col-12 text-center ch5">Etsy is not connected</div>
            <div className="col-12 text-center">
              This is needed to retrieve further information from Etsy that
              isn't available except by having a connected Etsy Account.
            </div>
            <div
              className="mx-auto mt-3 btn-dark-blue btn-regular"
              onClick={this.props.onConnectEtsyPressed}
            >
              Connect Etsy Account
            </div>
          </div>
        )}
        {!this.props.hasPremiumAccess && (
          <div className="alert alert-danger row" role="alert">
            <div className="col-12 text-center ch5">Not Subscribed</div>
            <div className="col-12 text-center">
              Certain information is only available with a subscription. All
              subscriptions come with a free week trial with full access.
            </div>
            <div
              className="mx-auto mt-3 btn-dark-blue btn-regular"
              onClick={this.props.onSubscribeButtonPressed}
            >
              Subscribe
            </div>
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger row" role="alert">
            <div className="col-12 text-center ch5">
              Etsy Access has expired
            </div>
            <div className="col-12 text-center">
              Please click below to reconnect your Etsy account to Sheerba. This
              is needed to retrieve further information from Etsy that isn't
              available except by having a connected Etsy Account.
            </div>
            <div className="mt-3 col-12 text-center">
              After reconnecting to your Etsy account, you may need to refresh
              the page for search to work again.
            </div>
            <div
              className="mx-auto mt-3 btn-dark-blue btn-regular"
              onClick={this.props.onConnectEtsyPressed}
            >
              Connect Etsy Account
            </div>
          </div>
        )}
        {/* <div
            className="feedback-button"
            onClick={this.props.onFeedbackButtonPress}
          >
            Contact Us
          </div>
          <Link
            className="subscribe-button"
            to="/pricing "
            target={'_blank'}
            rel="noreferrer noopener"
          >
            {'<Subscribe>'}
          </Link> */}
        <div className="">
          <TagSearchBar
            // className="tag-search-view-search-bar"
            isLoading={this.props.loadingString !== ''}
            showSearchIsLoading={this.props.showSearchIsLoading}
            onSearchTypeToggle={this.props.onSearchTypeToggle}
            onCategoryToggle={this.props.onCategoryToggle}
            onResultCountToggle={this.props.onResultCountToggle}
            onClearTogglePressed={this.props.onClearTogglePressed}
            clearOnSearchIsSelected={this.props.clearOnSearchIsSelected}
            onSearchPress={(input) => this.props.onSearchPress(input)}
            searchType={this.props.searchType}
            searchIsEnabled={this.props.etsyIsConnected}
          ></TagSearchBar>
        </div>
        <AnalyticDataComponent
          tableData={this.props.tableData}
          chartsAreLoading={this.props.chartsAreLoading}
          ratingIsLoading={this.props.ratingIsLoading}
          averagesAreLoading={this.props.averagesAreLoading}
          searchTerm={this.props.searchTerm}
          predictedData={this.props.predictedData}
          hasPremiumAccess={this.props.hasPremiumAccess}
          onGoogleChartPress={this.props.onGoogleChartPress}
          onFutureChartPress={this.props.onFutureChartPress}
          onBadgePress={this.props.onBadgePress}
          rating={this.props.rating}
          averageFavorers={this.props.averageFavorers}
          averagePrice={this.props.averagePrice}
          totalSales={this.props.totalSales}
          onSubscribeButtonPressed={this.props.onSubscribeButtonPressed}
        ></AnalyticDataComponent>

        <div>
          <ListingsTable
            className="mx-auto flex-grow-1 mb-auto"
            showSearchIsLoading={this.props.showSearchIsLoading}
            loadingString={this.props.loadingString}
            dataRows={this.props.results}
            height={this.state.height}
            hasPremiumAccess={this.props.hasPremiumAccess}
            subscriptionStatus={this.props.subscriptionStatus}
            tracker={this.props.tracker}
            //TableButtonsComponent
            onSaveToCSVPressed={this.props.onSaveToCSVPressed}
            onSubscribeButtonPressed={this.props.onSubscribeButtonPressed}
            onExtractTagsPressed={this.props.onExtractTagsPressed}
            buttonsEnabled={this.props.buttonsEnabled}
          ></ListingsTable>
        </div>
        <div className="my-auto"></div>
        <Footer></Footer>
      </div>
    )
  }
}
