import * as logger from '../Logger'
import { getMainPage } from '../BackendService'

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

async function getSearchCountForSearchTerm(searchTerm, sleepDuration = 0) {
  logger.log(searchTerm)

  const value = await getMainPage({ searchTerm: searchTerm })
    .then((html) => {
      logger.log('received html')
      let doc = document.implementation.createHTMLDocument()
      doc.write(html.html)
      const results = getNumberOfResults(doc)
      results['searchTerm'] = searchTerm
      logger.log(
        `searchTerm: ${searchTerm} - Results: ${results.count} ${results.ads}`
      )
      return results
    })
    .catch(async (error) => {
      if (`${error}` === 'Error: 429') {
        logger.log(
          `getSearchCountForSearchTerm. will retry shop after sleeping for ${
            sleepDuration + 1
          } seconds`
        )
        await sleep(1000 * sleepDuration + 1)
        return this.getSearchCountForSearchTerm(searchTerm, sleepDuration + 1)
      } else {
        logger.error('unhandled error for getSearchCountForSearchTerm')
        logger.error(searchTerm)
        logger.error(error)
        logger.error(`${error}`)
        return {
          count: 0,
          ads: false,
        }
      }
    })

  // const encodedTerm = encodeURIComponent(`${searchTerm}`)
  // logger.log(encodedTerm)
  // const link = `https://www.etsy.com/search?q=${encodedTerm}`
  // const value = await fetch(link)
  // .then((response) => {
  //   if (!response.ok) {
  //     throw Error(response.status)
  //   }
  //   return response.text()
  // })
  // .then((html) => {
  //   logger.log('received html')
  //   let doc = document.implementation.createHTMLDocument()
  //   doc.write(html)
  //   const results = getNumberOfResults(doc)
  //   results['searchTerm'] = searchTerm
  //   logger.log(
  //     `searchTerm: ${searchTerm} - Results: ${results.count} ${results.ads}`
  //   )
  //   return results
  // })
  // .catch(async (error) => {
  //   if (`${error}` === 'Error: 429') {
  //     logger.log(
  //       `getSearchCountForSearchTerm. will retry shop after sleeping for ${
  //         sleepDuration + 1
  //       } seconds`
  //     )
  //     await sleep(1000 * sleepDuration + 1)
  //     return this.getSearchCountForSearchTerm(searchTerm, sleepDuration + 1)
  //   } else {
  //     logger.error('unhandled error for getSearchCountForSearchTerm')
  //     logger.error(link)
  //     logger.error(error)
  //     logger.error(`${error}`)
  //     return {
  //       count: 0,
  //       ads: false,
  //     }
  //   }
  // })

  logger.log(`getSearchCountForSearchTerm return value:`)
  logger.log(value)
  return value
}

function getNumberOfResults(doc) {
  logger.log('get number of results')
  const className = 'wt-display-flex-xs wt-align-items-center wt-pr-lg-3'
  let attempt = doc.body.getElementsByClassName(className)
  if (!attempt) {
    logger.error(`did not find the following className: ${className}`)
    return
  }
  if (attempt.length <= 0) {
    return getResultsWhenThereAreNoAdvertisements(doc)
  } else {
    return getResultsWhenThereAreAdvertisements(attempt)
  }
}

function getResultsWhenThereAreAdvertisements(attempt) {
  logger.log('getResultsWhenThereAreAdvertisements')
  // logger.log(attempt)

  attempt = `${attempt[0].innerHTML}`
  // logger.log('result1234')
  // logger.log(attempt)

  const resultIndex = attempt.indexOf(' Result')
  attempt = attempt.substr(0, resultIndex)
  // logger.log('result')
  // logger.log(attempt)
  const startIndex = attempt.lastIndexOf('>')

  attempt = attempt.substr(startIndex + 1)
  // logger.log(attempt)

  return {
    count: attempt,
    ads: true,
  }
}

function getResultsWhenThereAreNoAdvertisements(doc) {
  logger.log('getReults no ads')
  let className = 'col-group pl-xs-0 search-listings-group pr-xs-1'
  const narrowingSearch = doc.body.getElementsByClassName(className)
  logger.log(narrowingSearch)
  let attempt = narrowingSearch[0].getElementsByClassName(
    'wt-text-caption wt-text-link-no-underline'
  )
  logger.log(attempt)
  if (!attempt) {
    logger.error(`did not find the following className: null: ${className}`)
    return 'not found'
  }
  if (attempt.length <= 0) {
    logger.error(`did not find the following className: length=0: ${className}`)
    return 'not found'
  }
  logger.log(attempt[1])

  if (attempt[1] === undefined) {
    return getResultsWhenThereAreNoAdvertisementsSecondPossibility(doc)
  }

  logger.log(attempt[1].innerHTML)
  attempt = `${attempt[1].innerHTML}`.trim()

  const indexOfSpace = attempt.indexOf(' Result')
  const number = attempt.substr(1, indexOfSpace)
  logger.log('no ads nubmer:')
  logger.log(number)
  return {
    count: number,
    ads: false,
  }
}

function getResultsWhenThereAreNoAdvertisementsSecondPossibility(doc) {
  logger.log('getResultsWhenThereAreNoAdvertisementsSecondPossibility')
  let firstClass =
    'wt-display-flex-xs mt-xs-1 mb-xs-2 wt-justify-content-space-between wt-align-items-center'
  let secondClass = 'wt-text-caption wt-text-link-no-underline'

  const narrowingSearch = doc.body.getElementsByClassName(firstClass)
  logger.log(narrowingSearch)
  if (narrowingSearch == null || narrowingSearch.length < 1) {
    logger.error(
      `did not find the following className: length=0: ${firstClass}`
    )
  }

  let attempt = narrowingSearch[0].getElementsByClassName(secondClass)
  logger.log(attempt)
  if (attempt == null || attempt.length < 1) {
    logger.error(
      `did not find the following className: length=0: ${secondClass}`
    )
  }

  logger.log(attempt[0].innerHTML)
  attempt = `${attempt[0].innerHTML}`.trim()

  const indexOfSpace = attempt.indexOf(' Result')
  const number = attempt.substr(0, indexOfSpace)
  logger.log('no ads nubmer:')
  logger.log(number)
  return {
    count: number,
    ads: false,
  }
}

export {
  getSearchCountForSearchTerm,
  getNumberOfResults,
  // parseMainPage,
  // getValidListings,
  // getInfoAboutListing,
  // getTableDivFromDocument,
  // parseMainSearchListing,
}
