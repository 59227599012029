import React, { Component } from 'react'

import demo1 from './FeatureImage1.png'
import demo2 from './FeatureImage2.png'
import demo3 from './FeatureImage3.png'

import './Circles.css'

export default class Circles extends Component {
  feature = (icon, title, text, regPosition) => {
    return (
      <div className="container py-5">
        <div className="row align-items-center">
          <div
            className={`col-lg-6 order-first ${
              regPosition ? 'order-lg-last' : 'order-lg-first'
            }`}
          >
            <img className="callout-image" src={icon} alt=""></img>
          </div>
          <div className="col-lg-6">
            <div className="display-4 my-4 txt-white">{title}</div>
            <div className="cp txt-white">{text}</div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="circleFeatures">
        {this.feature(
          demo1,
          'Save weeks of time in your research process',
          'Sheerba will do in seconds what would otherwise take hours. Instead of wasting days compiling information about niches and products, be able to find winning niches and products and move on to creating and selling.',
          true
        )}
        {this.feature(
          demo2,
          'Make Informed Decisions',
          'Knowing what products to make is the key to success. With Sheerba you can drastically reduce the chance of spending time developing products that are doomed to fail, and increase the number of products you make that are in high demand.',
          false
        )}
        {this.feature(
          demo3,
          'Spend your time on what matters',
          'By using Sheerba you can cut your research time down to a fraction of the time it used to take. That lets you focus on what really matters: your craft and creativity. Your research will be done almost instantly!',
          true
        )}
      </div>
    )
  }
}
