import React, { Component } from 'react'

import cover from '../../../img/cover.png'
import ReactGA from 'react-ga'

import { Form, Button } from 'react-bootstrap'

import writeToUserEbookDatabase from '../../../services/DatabaseService'
import * as backend from '../../../services/BackendService'

import pdf from '../../../resources/The Complete Guide to Etsy Product Research.pdf'

import './EmailForm.css'

export default class EmailForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      submitEnabled: false,
      email: '',
      submitted: false,
    }
  }

  handleEBookClick = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Get ebook',
    })

    const writeData = {
      email: this.state.email,
      date: new Date().toISOString(),
    }

    backend.sendEbookDownloadTelegram({
      email: this.state.email,
    })

    writeToUserEbookDatabase({ writeData: writeData })

    this.setState({
      submitted: true,
    })
    window.open(pdf, '_blank')
  }
  handleCloseClick = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Close',
    })
    this.props.onFormSubmitted()
  }
  handleChange = (text) => {
    // console.log(text)
    const emailIsValid = this.validateEmail(text)
    this.setState({
      submitEnabled: emailIsValid,
      email: text,
    })

    // console.log(this.state.submitEnabled)
  }
  validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }
  // handleKeyPress(target) {
  //   if (target.charCode === 13) {
  //     alert('Enter clicked!!!')
  //   }
  // }
  handleSubmit = (event) => {
    event.preventDefault()

    if (this.validateEmail(this.state.email)) {
      const button = document.getElementById('download')
      button.click()
    }
  }

  hideForm = () => {
    return (
      <div>
        <img
          src={cover}
          alt=""
          className="d-inline-block align-top w-responsive w-50 pb-2"
        ></img>
        <h3 className="mb-4">
          <em>Downloading...</em>
        </h3>
        <div>You can close this window.</div>
      </div>
    )
  }
  showForm = () => {
    return (
      <div>
        <img
          src={cover}
          alt=""
          className="d-inline-block align-top w-responsive w-50 pb-2"
        ></img>
        <h3 className="mb-4">
          <em>Learn everything you need to know about product research.</em>
        </h3>
        <Form
          onSubmit={(event) => {
            this.handleSubmit(event)
          }}
        >
          <Form.Group controlId="formBasicEmail">
            <Form.Label>
              Enter your email and the eBook will begin to download.
            </Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              onChange={(event) => {
                this.handleChange(event.target.value)
              }}
            />
            <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>
        </Form>
        <Button
          id="download"
          variant="primary"
          type="submit"
          onClick={this.handleEBookClick}
          disabled={this.state.submitEnabled === false}
          // href={pdf}
          // download="The Complete Guide to Etsy Product Research.pdf"
          // target="_blank"
          // rel="noopener noreferrer"
        >
          Get
        </Button>
      </div>
    )
  }

  //   <div className="video-area row align-items-center">
  //   <div className="video-background"></div>
  //   <div className="video-modal">
  //     <div className="video-modal-close" onClick={props.onClose}>
  //       ✕
  //     </div>
  //     <div className="video-modal-reg">
  //       <div className="container text-center">{videoPlayer()}</div>
  //     </div>
  //   </div>
  // </div>

  render() {
    return (
      <div>
        <div className="secondary-modal-blackout-background"></div>
        <div className="video-background"></div>
        <div className="emailForm">
          {/* <div className="mr-5 pr-5"> */}
          <div className="video-modal-close" onClick={this.handleCloseClick}>
            ✕
          </div>
          {/* </div> */}
          <div className="container text-center my-auto pt-4">
            {this.state.submitted ? this.hideForm() : this.showForm()}
          </div>
        </div>
      </div>
    )
  }
}
