import React, { Component, useEffect } from 'react'
import TextPage from '../components/TextPage'
import { useAuth } from '../services/contexts/AuthContext'

export const PrivacyPolicy = (props) => {
  const { isLoading, tracker } = useAuth()

  useEffect(() => {
    tracker.trackEvent({
      category: 'View',
      actionName: 'Privacy Policy',
      sendTelegram: true,
    })
  }, [tracker])

  if (isLoading) {
    return <div></div>
  }

  return <PrivacyPolicyComponent></PrivacyPolicyComponent>
}

class PrivacyPolicyComponent extends Component {
  render() {
    return (
      <TextPage title="Privacy Policy" subtitle="Last Updated: June 1, 2020">
        <p>
          DISCLAIMER: Neither sheerba.com, nor its operators are affiliated with
          or funded by etsy.com in any way. This site and all of its content is
          owned by sheerba.com and only meant for educational purposes.
        </p>
        <p></p>
        <p>
          This Privacy Policy governs the manner in which
          sheerba.com（”we”）collects, uses, maintains and discloses information
          collected from users (“you”) of sheerba.com website (“Site”). This
          privacy policy applies to the Site，application(if any)，all products
          and services offered by sheerba.com (collectively “Services”).
        </p>
        <p>
          We have the discretion to update this privacy policy at any time. We
          encourage Users to frequently check this page for any changes to stay
          informed about how we are helping to protect the personal information
          we collect. You acknowledge and agree that it is your responsibility
          to review this privacy policy periodically and become aware of
          modifications.
        </p>
        <p></p>
        <p>
          By using this Site, you signify your acceptance of this policy. If you
          do not agree to this policy, please do not use our Site. Your
          continued use of the Site following the posting of changes to this
          policy will be deemed your acceptance of those changes.
        </p>
        <p>1.What information is being collected?</p>
        <p>Personal information</p>
        <p></p>
        <p>
          Under EU General Data Protection Regulation(“GDPR”), Personal
          Information means the information that relates to an identified or
          identifiable individual, which includes but not limited to name,
          gender, age, telephone number, email address, location, purchase and
          payment details, IP addresses. However, we may just collect your email
          address when you provide your email address to download the Sheerba
          extension.
        </p>
        <p>Non-personal information</p>
        <p></p>
        <p>
          We may collect non-personal information about you whenever you
          interact with our Site. Non-personal information may include the
          browser name, language preferences, Device type, settings and software
          used,the type of computer and technical information about your means
          of connection to our Site, such as the operating system and the
          Internet service providers utilized and other similar information.
        </p>
        <p>
          We may also use device identifiers, cookies, and other technologies on
          devices, applications and our web pages to collect browsing, usage or
          other technical information for fraud prevention purposes.
        </p>
        <p>2.How is the information collected?</p>
        <p></p>
        <p>
          We may obtain your information when you provide it to us across our
          Services, such as you provide your email address to download the
          Sheerba extension; participate in surveys; or contact us via email,
          telephone or by any other means, etc.
        </p>
        <p>
          We may obtain your information when you provide it to us across our
          Services, such as you provide your email address to download the
          Sheerba extension; participate in surveys; or contact us via email,
          telephone or by any other means, etc.
        </p>
        <p>
          We may automatically receive and store certain types of information
          when you use Services, Like many websites, we use “cookies” and other
          unique identifiers and we obtain certain types of information when
          your web browser or device accesses Services and other content served
          by us.
        </p>
        <p>3.For what purposes do we process your personal information?</p>
        <p></p>
        <p>
          We process your personal information to operate, provide, and improve
          the Services that we offer to our customers. These purposes include:
        </p>
        <p>–To provide services</p>
        <p></p>
        <p>
          We provide the Services from Sheerba or its partners to you. We use
          your personal information to communicate with you about services and
          promotional offers.
        </p>
        <p>–To personalize user experience</p>
        <p></p>
        <p>
          We may use information in the aggregate to understand how our Users as
          a group use the services and resources provided on our Site.
        </p>
        <p>–To improve services</p>
        <p></p>
        <p>
          We may use feedback you provide to improve our products and services.
          We use your information to provide functionality, analyze performance,
          fix errors, and improve usability and effectiveness of Services.
        </p>
        <p>–To run a promotion, contest, survey or other Site feature</p>
        <p></p>
        <p>
          To send Users the information they agreed to receive about topics we
          think will be of interest to them.
        </p>
        <p>–To send periodic emails</p>
        <p></p>
        <p>
          We may use the email address to respond to users’ inquiries,
          questions, and/or other requests. If you decide to opt-in to our
          mailing list, they will receive emails that may include company news,
          updates, related product or service information related to Sheerba or
          its partners, etc. If at any time you would like to unsubscribe from
          receiving future emails, we include detailed unsubscribe instructions
          at the bottom of each email.
        </p>
        <p>–Comply with legal obligations.</p>
        <p></p>
        <p>
          In certain cases, we have a legal obligation to collect and process
          your personal information.
        </p>
        <p>–Communicate with you.</p>
        <p></p>
        <p>
          We use your personal information to communicate with you in relation
          to Services via different channels (e.g., by phone, email, chat).
        </p>
        <p>–Advertising.</p>
        <p></p>
        <p>
          We use your personal information to display interest-based ads to you
          regarding the Services, upcoming promotions and other information that
          may be of interest to you, using the contact details that you have
          provided to us and always in compliance with applicable law. You may
          unsubscribe from our newsletter lists at any time by following the
          unsubscribe instructions included in every email we send.
        </p>
        <p>–Purposes for which we seek your consent.</p>
        <p></p>
        <p>
          We may also ask for your consent to process your personal information
          for a specific purpose that we communicate to you. When you consent to
          our processing your personal information for a specified purpose, you
          may withdraw your consent at any time and we will stop processing of
          your data for that purpose.
        </p>
        <p>4.Do we Share Your Personal Information?</p>
        <p></p>
        <p>
          We always do not sell, rent, or otherwise disclose your personal
          information to third parties for their marketing and advertising
          purposes without your consent. We attempt to minimize the amount of
          personal information we disclose to what is directly relevant and
          necessary to accomplish the specified purpose.
        </p>
        <p>Business Transfers</p>
        <p></p>
        <p>
          As we continue to develop our business, we might sell or buy other
          businesses or services. In such transactions, customer information
          generally is one of the transferred business assets but remains
          subject to the promises made in any pre-existing Privacy Policy
          (unless, of course, the customer consents otherwise). Also, in the
          unlikely event that sheerba.com or substantially all of its assets are
          acquired, customer information will of course be one of the
          transferred assets.
        </p>
        <p>Other third parties</p>
        <p></p>
        <p>
          Legal and regulatory authorities, upon request, or for the purposes of
          reporting any actual or suspected breach of applicable law or
          regulation;
        </p>
        <p></p>
        <p>
          Outside professional advisors (such as accountants, auditors, or
          lawyers), subject to binding contractual obligations of
          confidentiality;
        </p>
        <p></p>
        <p>
          Any relevant party, law enforcement agency or court, to the extent
          necessary for the establishment, exercise or defense of legal rights;
        </p>
        <p></p>
        <p>
          Any relevant party for the purposes of prevention, investigation,
          detection or prosecution of criminal offences or the execution of
          criminal penalties, including safeguarding against and the prevention
          of threats to public security;
        </p>
        <p>
          Other than as set out above, you will receive notice when personal
          information about you might be shared with third parties and you will
          have an opportunity to choose not to share the information.
        </p>
        <p></p>
        <p>
          International Transfers of Information: We may transfer your Personal
          Information to recipients in other countries. Whenever we transfer
          personal information from the European Economic Area (“EEA”) to a
          recipient outside the EEA that is not in an adequate jurisdiction, we
          will ensure that the information is transferred in accordance with
          this Privacy Policy and as permitted by the applicable laws on data
          protection.
        </p>
        <p>5.How long we keep your personal information?</p>
        <p></p>
        <p>
          We keep your personal information to enable your continued use of
          Services, for as long as it is required in order to fulfill the
          relevant purposes described in this Privacy Policy, or for other
          essential purposes such as complying with our legal obligations,
          resolving disputes, and enforcing our policies.
        </p>
        <p></p>
        <p>
          We take every reasonable step to ensure that your personal information
          is only processed for the minimum period necessary for the purposes
          set out in this Policy. The criteria for determining the duration for
          which we will keep your User Information are as follows: we will
          retain copies of your User Information in a form that permits
          identification only for as long as is necessary in connection with the
          purposes set out in this Policy, unless applicable law requires a
          longer retention period. Unless there is a specific legal requirement
          for us to keep the information, we plan to retain it for no longer
          than is necessary to fulfill a legitimate business need.
        </p>
        <p>6.How secure is information about you?</p>
        <p></p>
        <p>
          We have implemented appropriate technical and organizational security
          measures designed to protect your User Information against accidental
          or unlawful destruction, loss, alteration, unauthorized disclosure,
          unauthorized access, and other unlawful or unauthorized forms of
          Processing, in accordance with applicable law. In certain instances we
          may use Secure Sockets Layer encryption and/or transfer certain User
          Information in a non-human readable format to provide protection. We
          follow the Payment Card Industry Data Security Standard when handling
          credit card data. We maintain physical, electronic, and procedural
          safeguards in connection with the collection, storage, and disclosure
          of personal customer information. Our security procedures mean that we
          may occasionally request proof of identity before we disclose personal
          information to you.
        </p>
        <p>
          However, the internet is an open system, the transmission of
          information via the internet is not completely secure, so we cannot
          guarantee there will not be a breach, and we are not responsible for
          any breach of security or for the actions of any third parties.
          Certainly, we will do our best to implement reasonable measures to
          protect your information.
        </p>
        <p>
          When a personal data breach has occurred, we will establish the
          likelihood and severity of the resulting risk to people’s rights and
          freedoms. If it’s likely that there will be a risk then we will notify
          the organization under the applicable law; if it’s unlikely then we
          don’t have to report it.
        </p>
        <p>7.Data Accuracy and Data Minimization</p>
        <p></p>
        <p>
          We take every reasonable step to ensure that your information that we
          process is accurate and, where necessary, kept up to date, and any of
          your information that we process that you inform us is inaccurate
          (having regard to the purposes for which they are processed) is erased
          or rectified.
        </p>
        <p></p>
        <p>
          We take every reasonable step to ensure that your information that we
          process is limited to the information reasonably necessary in
          connection with the purposes set out in this policy or as required to
          provide you services or access to the Services.
        </p>
        <p>8.What choices do you have?</p>
        <p></p>
        <p>
          If you have any question or objection as to how we collect and process
          your personal information, please contact our Customer Service
          (hello@sheerba.com). Many of our Services also include settings that
          provide you with options as to how your data are being used.
        </p>
        <p>
          As discussed above, you can choose not to provide certain information
          but then you might be unable to use the Services.
        </p>
        <p>
          If you don’t want to receive e-mail or other communication from us,
          please adjust your Customer Communication Preferences. If you don’t
          want to receive in-app notifications from us, please adjust your
          notification settings in the app or your device.
        </p>
        <p>
          If you don’t want us to use personal information to display
          interest-based ads, please adjust your Advertising Preferences.
        </p>
        <p>
          The Help feature on most browsers and devices will tell you how to
          prevent your browser or device from accepting new cookies, how to have
          the browser notify you when you receive a new cookie or how to disable
          cookies altogether. Because cookies allow you to take advantage of
          some essential Services, we recommend that you leave them turned on.
        </p>
        <p>
          You will also be able to opt out of certain other types of data
          processing by updating your settings on the applicable Site, device,
          or application. Most devices also provide users with the ability to
          change device permissions (e.g., disable/access location services,
          contacts). For most devices, these controls are located in the
          device’s settings menu. If you have questions about how to change your
          device permissions on devices manufactured by third parties, we
          recommend you contact your mobile service carrier or your device
          manufacturer as different devices may have different permission
          settings.
        </p>
        <p>
          If you are an EU resident, you may have certain rights including: the
          right to object to provide or process your personal information to us;
          the right of access to your personal information (other than email
          address); the right to request rectification of inaccuracies; the
          right to request the erasure, or restriction of processing of your
          Personal Information; the right to have your personal information
          transferred to another controller; the right to withdraw consent; and
          the right to lodge complaints with supervisory authorities. But we may
          require proof of or need to verify your identity before we can give
          effect to these rights.
        </p>
        <p>9.Third party websites</p>
        <p></p>
        <p>
          Users may find advertising or other content on our Site that link to
          the sites and services of our partners, suppliers, advertisers,
          sponsors, licensors and other third parties. We do not control the
          content or links that appear on these sites and are not responsible
          for the practices employed by websites linked to or from our Site. In
          addition, these sites or services, including their content and links,
          may be constantly changing. These sites and services may have their
          own privacy policies and customer service policies. Browsing and
          interaction on any other website, including websites which have a link
          to our Site, is subject to that website’s own terms and policies.
        </p>
        <p>10.Web browser cookies</p>
        <p></p>
        <p>
          Our Site may use “cookies” to enhance User experience. User’s web
          browser places cookies on their hard drive for record-keeping purposes
          and sometimes to track information about them. You can choose to set
          their web browser to refuse cookies, or to alert you when cookies are
          being sent. If you do so, note that some parts of the Site may not
          function properly.
        </p>
        <p>11.Contact Details</p>
        <p></p>
        <p>
          If you have any questions about this Privacy Policy, the practices of
          this site, or your dealings with this site, please contact us at:
        </p>
        <a
          href="mailto:hello@sheerba.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          hello@sheerba.com
        </a>
      </TextPage>
    )
  }
}

export default PrivacyPolicy
