import React, { Component } from 'react'

import { PremiumFeaturesComponent } from '../../../components/Duplicated/PremiumFeaturesComponent/PremiumFeaturesComponent'

import './UnsubscribeModal.css'

// import * as tracker from '../../Services/Tracker'

// import * as logger from '../../Services/Logger'
// import * as backend from '../../Services/BackendService'
// import * as database from '../../Services/DatabaseService.js'

import backgroundSrc from '../../../img/SignInBackground.png'

class UnsubscribeModal extends Component {
  constructor(props) {
    super(props)

    // tracker.pageview('unsubscribe-modal')

    this.state = {
      page: 1,
    }
  }

  // cancelSubscription = async () => {
  //   logger.log(`cancel subscription:`)
  // tracker.trackEvent({
  //   category: 'Button',
  //   actionName: 'Extract Tags Pressed',
  //   sendTelegram: true
  // })
  //   ReactGA.event({
  //     category: 'Button',
  //     action: 'Cancel Subscription',
  //   })

  //   if (this.state.subscriptionId == null) return

  //   // 1. call backend to cancel subscription
  //   const updatedSubscription = await backend
  //     .cancelSubscription({
  //       subscriptionId: this.state.subscriptionId,
  //     })
  //     .catch((error) => {
  //       logger.log('error message:')
  //       logger.log(error)
  //       this.setState({
  //         needToRecheckConnectionToStripe: true,
  //         showError: error.message,
  //         viewState: viewStateEnum.SEARCH,
  //       })
  //       return
  //     })

  //   if (updatedSubscription == null) return

  //   // 2. then update database
  //   database.cancelSubscription({
  //     email: userEmail,
  //     cancelAt: updatedSubscription.cancel_at,
  //   })

  //   // 3. then update view state
  //   this.setState({
  //     viewState: viewStateEnum.SEARCH,
  //     subscriptionCancelAt: updatedSubscription.cancel_at,
  //   })
  // }

  onContinuePress = () => {
    this.setState({
      page: 2,
    })
  }

  onUnsubscribePress = () => {
    // Tracking is done in the prop function. No need to do it here.
    this.props.onCancelSubscription()
  }

  getPage = () => {
    if (this.state.page === 1) {
      return this.page1()
    } else if (this.state.page === 2) {
      return this.page2()
    } else {
      return this.page3()
    }
  }

  page1 = () => {
    return (
      <div>
        <div className="unsubscribe-modal-title">Unsubscribe</div>
        <div className="unsubscribe-modal-body">
          <div className="unsubscribe-modal-body-text-need-confirmation">
            Confirm you want to unsubscribe. You will not be billed again and
            will retain access through the pay period.
          </div>
        </div>
        <div
          className="unsubscribe-modal-button blue-button"
          onClick={this.onContinuePress}
        >
          Unsubscribe
        </div>
      </div>
    )
  }

  page2 = () => {
    return (
      <div>
        <div className="unsubscribe-modal-title">Unsubscribe</div>
        <div className="unsubscribe-modal-subtitle">
          You will lose access to all these Premium Features:
        </div>
        <div className="unsubscribe-modal-body">
          <div className="unsubscribe-body-white-background">
            <PremiumFeaturesComponent></PremiumFeaturesComponent>
          </div>
        </div>
        <div
          className="unsubscribe-modal-button blue-button"
          onClick={this.onUnsubscribePress}
        >
          Yes, I still want to unsubscribe
        </div>
      </div>
    )
  }

  page3 = () => {
    return (
      <div>
        <div className="unsubscribe-modal-title">Unsubscribe Complete</div>
        <div className="unsubscribe-modal-subtitle">
          Unsubscribe Successful.
        </div>
        <div className="unsubscribe-modal-body">
          <div className="unsubscribe-modal-body-text">
            You will not be billed at the next scheduled payment date.
          </div>
          <div className="unsubscribe-modal-body-text">
            You will retain access to all premium features until then.
          </div>
        </div>
      </div>
    )
  }

  render = () => {
    return (
      <div>
        <div className="unsubscribe-modal-background"></div>
        <div className="unsubscribe-modal-container">
          <div
            className="unsubscribe-modal"
            style={{
              // backgroundImage: `url(${
              //   this.state.page === 1
              //     ? this.props.menuBackground
              //     : backgroundSrc
              // })`,
              backgroundPositionY: 'left',
            }}
          >
            <div
              className="unsubscribe-modal-close"
              onClick={this.props.onClose}
            >
              ✕
            </div>
            {this.getPage()}
          </div>
        </div>
      </div>
    )
  }
}

export default UnsubscribeModal
