import React, { PureComponent } from 'react'

import './SearchCategoryPicker.css'

class SearchCategoryPicker extends PureComponent {
  render() {
    return (
      <div>
        <div className="txt-blue cp2">Category</div>
        <select
          className="selectpicker-primary txt-blue"
          onChange={this.props.onCategoryToggle}
        >
          <option value={0}>All</option>
          <option value={1}>Accessories</option>
          <option value={2}>Art & Collectibles</option>
          <option value={3}>Bags & Purses</option>
          <option value={4}>Bath & Beauty</option>
          <option value={5}>Books, Movies & Music</option>
          <option value={6}>Clothing</option>
          <option value={7}>Craft Supplies and Tools</option>
          <option value={8}>Electronics & Accessories</option>
          <option value={9}>Home & Living</option>
          <option value={10}>Jewelry</option>
          <option value={11}>Paper & Party Supplies</option>
          <option value={12}>Pet Supplies</option>
          <option value={13}>Shoes</option>
          <option value={14}>Toys & Games</option>
          <option value={15}>Weddings</option>
        </select>
      </div>
    )
  }
}
export default SearchCategoryPicker
