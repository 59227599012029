import React, { PureComponent } from 'react'

import UnsubscribeModal from './Unsubscribe/UnsubscribeModal'

import './AccountPageView.css'
import ResubscribeModal from './Resubscribe/ResubscribeModal'
import { Container } from 'react-bootstrap'
import { Footer } from '../../components/Footer/Footer'

import background from '../../img/BlueSwooshBackground.png'

export class AccountPageView extends PureComponent {
  getSubscriptionButton = () => {
    if (this.props.subscriptionIsCancelled === false) {
      return (
        <div
          className="btn-dark-blue btn-regular"
          onClick={this.props.onShowUnsubscribe}
        >
          Cancel Subscription
        </div>
      )
    } else if (this.props.subscriptionIsCancelled === true) {
      return (
        <div
          className="btn-dark-blue btn-regular"
          onClick={this.props.onShowResubscribe}
        >
          Renew Subscription
        </div>
      )
    } else if (this.props.subscriptionId == null) {
      return (
        <div
          className="btn-dark-blue btn-regular"
          onClick={this.props.onSubscribePressed}
        >
          Subscribe
        </div>
      )
    } else {
      return <div>Shouldnt be possible</div>
    }
  }

  getEtsyStatusSection = () => {
    return (
      <div>
        <div>Etsy is Connected: {this.props.etsyIsConnected}</div>
        {!this.props.etsyToken ? (
          <div>
            <div className="cp2 txt-orange">
              This is needed to retrieve further information from Etsy that
              isn't available except by having a connected Etsy Account.
            </div>
            <div
              className="mt-2 btn-orange btn-regular text-center"
              onClick={this.props.onConnectToEtsyPressed}
            >
              Connect Etsy
            </div>
          </div>
        ) : (
          <div
            className="mt-2 btn-dark-blue btn-regular text-center"
            onClick={this.props.onConnectToEtsyPressed}
          >
            Refresh Etsy Connection
          </div>
        )}
      </div>
    )
  }

  extraDevInfo = () => {
    if (false) {
      return (
        <div>
          <div>subscriptionStatus: {`${this.props.subscriptionStatus}`}</div>
          <div>trailEnd: {`${this.props.trialEnd}`}</div>
          <div>
            Subscription Cancelled?: {`${this.props.subscriptionIsCancelled}`}
          </div>
          <div>Etsy Token: {`${this.props.etsyToken}`}</div>
        </div>
      )
    } else {
      return <div></div>
    }
  }

  render() {
    return (
      <div>
        {this.props.showUnsubscribe && (
          <UnsubscribeModal
            onClose={this.props.onClose}
            onCancelSubscription={this.props.onCancelSubscription}
          ></UnsubscribeModal>
        )}
        {this.props.showResubscribe && (
          <ResubscribeModal
            onClose={this.props.onClose}
            renewSubscription={this.props.onRenewSubscription}
          ></ResubscribeModal>
        )}
        <div
          className="account-page d-flex flex-column"
          // style={{ minHeight: '90vh' }}
        >
          <div className="my-auto"></div>
          <Container>
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-8 col-xl-6 info-view-main-area-2">
                <div className="col-12 mt-1 mx-auto account-message-space text-center">
                  {this.props.errorMessage && (
                    <div
                      className="mx-auto alert alert-danger account-alert"
                      role="alert"
                    >
                      {this.props.errorMessage}
                    </div>
                  )}
                  {this.props.successMessage && (
                    <div
                      className="mx-auto alert alert-primary account-alert"
                      role="alert"
                    >
                      {this.props.successMessage}
                    </div>
                  )}
                </div>
                <div
                  className="col-12 my-2 cp txt-dark-blue"
                  // style={{ backgroundColor: 'red' }}
                >
                  Name:
                </div>
                <div className="col-12 my-2 pb-3">
                  <input
                    type="text"
                    className="sheerba-input w-100"
                    id="inputTag"
                    aria-describedby="tagInput"
                    defaultValue={this.props.name}
                    onKeyUp={this.props.onNameKeyUp}
                  ></input>
                  <div
                    className={`mt-3 btn-regular ${
                      this.props.updateButtonIsEnabled === true
                        ? 'btn-dark-blue'
                        : 'btn-dark-blue-disabled'
                    }`}
                    onClick={this.props.onUpdatePressed}
                  >
                    Update
                  </div>
                </div>
                {/* <div className="col-12 mt-1 d-flex"> */}
                {/* </div> */}
                <div
                  className="col-12 mt-3 cp txt-dark-blue"
                  // style={{ backgroundColor: 'red' }}
                >
                  Email: {this.props.email}
                </div>
                <div className="col-12 mt-3 cp txt-dark-blue">
                  {this.getEtsyStatusSection()}
                </div>
                <div className="col-12 mt-3 cp txt-dark-blue">
                  Subscription status: {this.props.subscriptionStatusString}
                </div>
                {/* <button onClick={this.props.onFeedbackButtonPress}>
                Contact Us
              </button> */}
                <div className="col-12 my-3 text-center">
                  {this.getSubscriptionButton()}
                </div>
                <div className="col-12 my-3" onClick={this.props.logout}>
                  <div className="mx-auto btn-dark-blue btn-regular">
                    log out
                  </div>
                </div>
                {this.extraDevInfo()}
              </div>
            </div>
          </Container>
          <img
            src={background}
            alt="background"
            className="info-view-background"
          ></img>

          <div className="my-auto"></div>
          {/* <Footer></Footer> */}
        </div>
      </div>
    )
  }
}
