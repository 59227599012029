import React from 'react'
import ReactGA from 'react-ga'
import { useAuth } from '../../../services/contexts/AuthContext'

import history from '../../../history'

import './FooterCallout.css'

export const FooterCallout = (props) => {
  const { tracker } = useAuth()

  const handleOnClick = () => {
    tracker.trackEvent({
      category: 'Event',
      actionName: 'FooterCallout - Get Extension Pressed',
      sendTelegram: true,
    })
    ReactGA.event({
      category: 'Button',
      action: 'Footer CTA',
    })
    history.push('/tag-search')
    window.scrollTo(0, 0)
  }

  return (
    <section className="content-section footer-area text-white">
      <div className="container text-center">
        <h2 className="mb-4">Run straight from your browser</h2>
        <a
          className="btn-dark-blue btn-large w-50 m-auto mt-5"
          href="https://chrome.google.com/webstore/detail/sheerba/effgadpnajejiggfiimjjnkhaggcgdfb"
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleOnClick}
        >
          Sheerba - Try for Free!
        </a>
      </div>
    </section>
  )
}
