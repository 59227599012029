import React, { Component } from 'react'

import affiliateCommission from './AffiliateCommission.png'
import './AffiliateCommission.css'

class AffiliateCommission extends Component {
  render() {
    return (
      <section className="content-page affiliate-commission">
        <div className="container text-center">
          <div className="row">
            <div className="col-12 col-md-7 my-auto">
              <img
                src={affiliateCommission}
                alt="affiliate"
                className="affiliate-commission-image"
              ></img>
            </div>
            <div className="col-12 col-md-5 text-center text-sm-left m-auto">
              <div className="pb-3 ch4 txt-dark-blue">
                Earn 50% Recurring Commission - What?!
              </div>
              <div className="cp2 txt-dark-blue">
                Essentially, it's revenue sharing. You introduce Sheerba to
                customers that you think might be interested and once they
                become a member you'll earn 50% recurring commissions for as
                long as your referral remains a paid Sheerba customer. Sounds
                fair, right?
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default AffiliateCommission
