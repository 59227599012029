import React from 'react'
import { Router, Switch, Route } from 'react-router-dom'

import { TermsOfService } from './pages/TermsOfService'
import { PrivacyPolicy } from './pages/PrivacyPolicy'
import { FAQ } from './pages/FAQ'
import { Home } from './pages/HomePage/Home'
import history from './history'
import { Affiliate } from './pages/AffiliatePage/Affiliate'
import { Pricing } from './pages/PricingPage/PricingPageController'
import { TagSearch } from './pages/TagSearch/TagSearchController'
import PrivateRoute from './PrivateRoute'
import LoggedOut from './components/Duplicated/LoggedOut/LoggedOut'
import { Account } from './pages/AccountPage/AccountPageController'
import { OAuthComplete } from './pages/OAuthComplete'

export const Routes = (props) => {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/terms-of-service" component={TermsOfService} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/faq" component={FAQ} />
        <Route path="/affiliate" component={Affiliate} />
        <Route path="/pricing" component={Pricing} />
        <Route path="/login" component={LoggedOut} />
        <PrivateRoute path="/tag-search" component={TagSearch} />
        <PrivateRoute path="/account" component={Account} />
        <PrivateRoute path="/oauth-complete" component={OAuthComplete} />
      </Switch>
    </Router>
  )
}
