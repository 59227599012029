import React, { Component } from 'react'
import ReactGA from 'react-ga'

import icon from '../../img/icon128.png'
import sheerba from '../../img/sheerba2.png'
import instagram from '../../img/Instagram.png'
import youtube from '../../img/Youtube.png'
import divider from './Divider.png'
import './Footer.css'

export const Footer = () => {
  const link = (title, href) => {
    return (
      <a href={href} className="col-12 col-md-auto text-center my-1">
        <div className="txt-light">{title}</div>
      </a>
    )
  }

  const social = (src, href) => {
    return (
      <a
        className="col-12 text-center col-lg-auto py-2"
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img className="footer-social-icon" src={src} alt="ig"></img>
      </a>
    )
  }

  return (
    <div
      className="footer "
      // style={{ position: 'absolute', bottom: '0px' }}
    >
      <a className="row justify-content-center pt-4 pb-3" href="/">
        <img className="footer-icon" src={icon} alt="icon"></img>
        <img className="footer-sheerba" src={sheerba} alt="sheerba"></img>
      </a>
      <div className="row justify-content-md-center">
        {link('FAQ', '/faq')}
        <a
          className="col-12 col-md-auto text-center my-1"
          href="mailto:hello@sheerba.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="txt-light">Contact Us</div>
        </a>
        {link('Affiliate', '/affiliate')}
        {link('Pricing', '/pricing')}
        {link('Terms of Service', '/terms-of-service')}
        {link('Privacy Policy', '/privacy-policy')}
      </div>
      <div className="text-center m-auto">
        <img className="w-50" src={divider} alt="divider"></img>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-lg-7">
          <div className="row">
            <div className="col-12 text-center col-lg-auto mr-lg-auto py-2 txt-light">
              &copy; 2021 Sheerba. All rights reserved
            </div>
            {social(instagram, 'https://www.instagram.com/sheerba_research/')}
            {social(
              youtube,
              'https://www.youtube.com/channel/UCOzpYQ3-V-YWcLmWVgUhzfg'
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
