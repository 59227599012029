import React, { Component } from 'react'
import { Container } from 'react-bootstrap'

import './AffiliateDescription.css'

import affiliateDescription from './AffiliateDescription.png'

class AffiliateDescription extends Component {
  render() {
    return (
      <Container>
        <div className="affiliate-description">
          <div className="affiliate-description-background row">
            {/* <div className="affiliate-orange-block d-block d-sm-none"></div>
        <div className="affiliate-orange-block-sm d-none d-lg-block pt-sm-5"></div> */}
            <div className="col-12 col-lg-5 order-lg-last mx-auto my-auto text-center">
              <div className="mx-auto">
                <img
                  src={affiliateDescription}
                  alt="affiliateDescription"
                  className="affiliate-description-image pt-4 my-auto mx-auto"
                ></img>
              </div>
            </div>
            <div className="col-12 col-lg-7 text-center text-lg-left mx-auto txt-dark-blue pt-4 pb-5">
              <div className="ch4 mt-5 txt-dark-blue">PROGRAM DETAILS</div>
              <div className="ch5 pt-2 pb-1 txt-dark-blue">
                About Sheerba's Affiliate Program
              </div>
              <p className="txt-dark-blue">
                The Sheerba Affiliate Partner Program allows you to market our
                tools, which have helped multiple Etsy entrepreneurs create and
                grow their small online business.
              </p>
              <p className="txt-dark-blue">
                We know there are a lot of affiliate programs out there, but we
                strive to make ours the BEST.
              </p>
              <p className="txt-dark-blue">
                That's why we offer 50% recurring commission on all sales you
                generate, for as long as your referrals remain customers,
                including renewals and potential upgrades of their Sheerba plan.
                We also support your referrals with Etsy relevant marketing
                education, tech support, and great software to keep them excited
                to use our product (which means more commissions for you!).
              </p>
            </div>
          </div>
        </div>
      </Container>
    )
  }
}

export default AffiliateDescription
