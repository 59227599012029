import React from 'react'
import ReactPlayer from 'react-player'
import ReactGA from 'react-ga'

import './VideoModal.css'

export const VideoModal = (props) => {
  const handleOnPlayTracking = () => {
    // console.log('track')
    ReactGA.event({
      category: 'Button',
      action: 'Video Started',
    })
  }

  const videoPlayer = () => {
    if (window.innerWidth < 576) {
      return <div className="mx-auto">{smallVideo()}</div>
    } else {
      return <div className="mx-auto">{regVideo()}</div>
    }
  }

  const smallVideo = () => {
    return (
      <ReactPlayer
        width="100%"
        // height='300px'
        className=""
        url="https://www.youtube.com/watch?v=xSagtxtVU5M&t=1s&ab_channel=Sheerba"
        controls={true}
        onPlay={handleOnPlayTracking}
        // playing={true}
      ></ReactPlayer>
    )
  }

  const regVideo = () => {
    return (
      <ReactPlayer
        // width="400px"
        // height="300px"
        className="px-1"
        url="https://www.youtube.com/watch?v=xSagtxtVU5M&t=1s&ab_channel=Sheerba"
        controls={true}
        onPlay={handleOnPlayTracking}
      ></ReactPlayer>
    )
  }

  const getVideo = () => {
    // if (props.size === 'sm') {
    return (
      <div className="video-area row align-items-center">
        <div className="video-background"></div>
        <div className="video-modal">
          <div className="video-modal-close" onClick={props.onClose}>
            ✕
          </div>
          <div className="video-modal-reg">
            <div className="container text-center">{videoPlayer()}</div>
          </div>
        </div>
      </div>
    )
    // } else if (props.size === 'med') {
    //   return (
    //     <section className="video-section-reg bg-light">
    //       <div className="container text-center">
    //         <div className="mx-auto">{regVideo()}</div>
    //       </div>
    //     </section>
    //   )
    // } else {
    //   return <div></div>
    // }
  }

  return getVideo()
}
