import React from 'react'

// import * as logger from '../../../../services/Logger'

import './ExpandableComponent.css'
import { TagsComponent } from '../../Tags/TagsController'

const ExpandableComponent = ({
  onRowClick,
  data,
  hasPremiumAccess,
  onSubscribePress,
}) => {
  const priceItemComponent = (title, data) => {
    return (
      <div className="expandableViewPriceSection">
        <div className="expandableViewPriceSectionHeader">{title}</div>
        <div className="expandableViewPriceSectionBody">{data}</div>
      </div>
    )
  }

  const priceComponent = () => {
    return (
      <div className="expandableViewPriceInfoSection">
        <div className="expandableViewPriceFirstSection">
          <div className="expandableViewPriceSectionHeader">PRICE</div>
          <div className="expandableViewPriceSectionBody">{data.price}</div>
        </div>
        {priceItemComponent('ORIGINAL PRICE', data.originalPrice)}
        {priceItemComponent('SALE PERCENTAGE', data.salePercentage)}
      </div>
    )
  }

  const shippingComponent = () => {
    return (
      <div className="expandableViewShippingSection">
        <div className="expandableViewPriceSectionHeader">SHIPPING SPECIAL</div>
        <div className="expandableViewShippingInfoField">
          {data.shippingSpecial === '-' ? 'None' : data.shippingSpecial}
        </div>
      </div>
    )
  }

  const shopComponent = () => {
    return (
      <a
        href={`https://www.etsy.com/shop/${data.shopName}`}
        target={'_blank'}
        rel="noreferrer noopener"
        className="expandableViewStoreSection"
      >
        <div className="expandableViewStoreTitle">{data.shopName}</div>
        <div className="expandableViewStoreSalesHeader">SALES</div>
        <div className="expandableViewStoreRatingHeader">RATING</div>
        <div className="expandableViewStoreRatingCountHeader">RATINGS</div>
        <div className="expandableViewStoreSalesInfo">{data.storeSales}</div>
        <div className="expandableViewStoreRatingInfo">{data.storeRating}</div>
        <div className="expandableViewStoreRatingCountInfo">
          {data.storeRatingCount}
        </div>
      </a>
    )
  }

  const creationDateComponent = () => {
    return (
      <div className="expandableViewCreationDateSection">
        <div className="expandableViewInfoTitle">
          {'Original Creation Date'.toUpperCase()}
        </div>
        <div className="expandableViewCreationDateDateString">
          {data.creationDate}
        </div>
        <div className="expandableViewCreationDateTimeString">
          {data.creationTime}
        </div>
      </div>
    )
  }

  return (
    <div className="expandableView">
      <img className="expandableViewImage" src={data.image} alt="" />
      <div className="expandableViewCenterSection">
        <a
          href={data.link}
          target={'_blank'}
          rel="noreferrer noopener"
          className="expandableViewTitleSection"
        >
          <div className="expandableViewTitleSection">{data.title}</div>
        </a>
        {shopComponent()}
        {shippingComponent()}
        {creationDateComponent()}
        {priceComponent()}
        {/* <div
          onClick={() => onRowClick(data)}
          className='expandableViewListingFavoriteSection'
        >
          <div className='expandableView-favorersButton'>
            <div className='expandableViewListingHeader'>See Who Favorited</div>
            <div className='expandableViewListingInfoField'>This Item</div>
          </div>
        </div> */}
      </div>
      <div className="expandableViewRightSection">
        {
          <TagsComponent
            tags={data.tags}
            hasPremiumAccess={hasPremiumAccess}
            onSubscribePress={onSubscribePress}
          ></TagsComponent>
        }
      </div>
    </div>
    // </div>
  )
}

export default ExpandableComponent
