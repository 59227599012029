import React, { Component, useEffect } from 'react'
import TextPage from '../components/TextPage'
import { useAuth } from '../services/contexts/AuthContext'

export const TermsOfService = (props) => {
  const { isLoading, tracker } = useAuth()

  useEffect(() => {
    tracker.trackEvent({
      category: 'View',
      actionName: 'Terms of Service',
      sendTelegram: true,
    })
  }, [tracker])

  if (isLoading) {
    return <div></div>
  }

  return <TermsOfServiceComponent></TermsOfServiceComponent>
}

class TermsOfServiceComponent extends Component {
  render() {
    return (
      <TextPage
        title="Terms of Service"
        subtitle="Effective Date: June 1, 2020"
      >
        <p>
          DISCLAIMER: Neither sheerba.com, nor its operators are affiliated with
          or funded by etsy.com in any way. This site and all of its content is
          owned by sheerba.com and only meant for educational purposes.
        </p>
        <p>
          This Terms of Service is an agreement between you (“you” or “User”)
          and Sheerba (“Sheerba” or “we”), describing the terms and conditions
          which you accept by using our Website located at www.sheerba.com
          (“Site”).
        </p>
        <p>
          By using this Site, you signify your acceptance of this agreement. If
          you do not agree to this agreement, please do not use our Site. Your
          continued use of the Site following the posting of changes to this
          agreement will be deemed your acceptance of those changes.
        </p>
        <p>
          We have the discretion to update this Terms of Service at any time. We
          encourage you to frequently check this page for any changes. You
          acknowledge and agree that it is your responsibility to review this
          Terms of Service periodically and become aware of modifications.
        </p>
        <p>
          1. Service. Sheerba is an extension only available on Google Chrome,
          which helps Etsy sellers estimate product sales and choose the
          estimated best-selling products according to the data Sheerba tracks
          and shows on Etsy, such as product title, brand, price, category,
          rank, estimated monthly sales, estimated monthly revenue, reviews,
          listing rating, shipment method.
        </p>
        <p>
          Note: All the data we track is either open to everyone who can visit
          Etsy websites or authorized by information owners.
        </p>
        <p>
          2. User. If you want to get Sheerba, you can go to www.sheerba.com or
          Chrome Web Store, and when you complete payment and successfully
          download the Sheerba on Chrome Web Store, you will get the service of
          Sheerba. After you successfully download Sheerba, you become a “User”
          according to this agreement.
        </p>
        <p>
          In addition, to get Sheerba, you must provide your name (real or
          unreal), valid email address and any other information we or Chrome
          Web Store requests to complete your payment and downloading.
        </p>
        <p>
          3. Payment. When you want to get Sheerba on Chrome Web Store, we will
          charge fees via Stripe payment from you for using the plug-in. In the
          process of using the service of Sheerba, we will charge no further
          fees from you. If a payment is not successfully settled, the extension
          functionality will be unavailable. Check with your Payment Method
          service provider for details. Please note: PAYMENT IS ONE-OFF AND
          NONREFUNDABLE.
        </p>
        <p>
          4. Mutual Non-Disparagement Covenant. You agree that you will not, at
          any time, make directly or indirectly, any oral or written public
          statements that are disparaging of us, our products or services, and
          any of our present or former Team. We (limited to its officers and
          directors) agree that we will not, at any time, make, directly or
          indirectly, any oral or written public statements that are disparaging
          of you. Disparagement shall be defined as any oral or written public
          statements that impugn the qualities, character, honesty, integrity,
          morality, business acumen or abilities of the subject matter. The
          Parties acknowledge and agree that it would be difficult or impossible
          to determine with absolute precision the amount of damages that would
          or might be incurred as a result of a party’s violation of this
          covenant. The Parties agree that the liquidated damages in the region
          of $50,000 per violation provided under this agreement, are in lieu of
          actual damages and are the Parties’ reasonable estimates of fair
          compensation for the Losses that may reasonably be incurred by each
          violation of this covenant.
        </p>
        <p>5. Warranties.</p>
        <p>You agree not to :</p>
        <p>a) send Unsolicited Bulk Mail (SPAM) or other communications;</p>
        <p>
          b) upload, post, email, or otherwise transmit any material that
          contains software viruses or any other computer code, files, or
          programs designed to interrupt, destroy, or limit the functionality of
          any computer software or hardware or telecommunications equipment;
        </p>
        <p>
          c) send or transmit pornography or other sexually explicit
          communications, communications offering to sell illegal goods or
          services, communications that violate the related laws of Hong Kong,
          or marketing or commercial communications without permission;
        </p>
        <p>
          d) breach or circumvent any laws, third party rights or our systems,
          policies, or determinations of Your account status;
        </p>
        <p>
          e) use any robot, spider, scraper or other automated means to access
          our Service for any purpose;
        </p>
        <p>
          f) harvest or otherwise collect information about members without
          their consent; and
        </p>
        <p>
          g) upload or transmit harassing, offensive, obscene, defamatory,
          threatening, or malicious content or communications.
        </p>
        <p>
          6. Disclaimer of Warranties. TO THE FULLEST EXTENT PERMITTED BY THE
          APPLICABLE LAW, WE OFFER THE WEBSITE AND SERVICES AS-IS AND MAKE NO
          REPRESENTATIONS OR WARRANTIES OF ANY KIND CONCERNING THE WEBSITE OR
          ITS SERVICES, EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING,
          WITHOUT LIMITATION, MERCHANTIBILITY, FITNESS FOR A PARTICULAR PURPOSE.
          WE DO NOT WARRANT THE FUNCTIONS OR CONTENT CONTAINED ON THE WEBSITE OR
          SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE
          CORRECTED.
        </p>
        <p>
          7. Intellectual Property. The Site, its original Content, features,
          functionality (including look), and Service Content (“Intellectual
          Property”) remain the property of Sheerba. You shall not acquire any
          intellectual property rights under this Agreement except the limited
          right to use as set forth above. You agree to not copy, modify, create
          derivative works of, publicly display, publicly perform, or republish
          any of our copyrighted material. The term “Content” includes, without
          limitation, information, data, text, photographs, videos, audio clips,
          written posts and comments, software, scripts, graphics, ad words, and
          interactive features generated, provided, or otherwise made accessible
          on or through Sheerba.
        </p>
        <p>
          8. Privacy Policy. Sheerba may use and disclose your information
          according to the Privacy Policy (The Privacy Policy is posted on
          www.sheerba.com).
        </p>
        <p>
          9. Attorneys Fees. In any legal action between the parties concerning
          this Agreement, the prevailing party shall be entitled to recover
          damages, other relief may be awarded, its costs and expenses,
          including reasonable attorneys fees, litigation costs, subpoena costs.
        </p>
        <p>
          10. Indemnity. You will indemnify us (and our officers, directors,
          agents, subsidiaries, joint venture and employees) against any claim
          or demand, including legal fees and costs, made against us by any
          third party due to or arising out of your breach of this Agreement, or
          your infringement of any law or the rights of a third party in using
          the Site and Site Services.
        </p>
        <p>
          11. Governing Law and Jurisdiction. This Agreement and any dispute or
          claim arising out of or in connection with it or its subject matter or
          formation (including non-contractual disputes or claims) shall be
          governed by and construed in accordance with the law of the United
          States of America. The courts of United States of America shall have
          exclusive jurisdiction to settle any dispute or claim arising out of
          or in connection with this agreement or its subject matter or
          formation (including non-contractual disputes or claims).
        </p>
        <p>
          12. Force Majeure. We shall not be liable for any failure of or delay
          in the performance of this Agreement for the period that such failure
          or delay is due to causes beyond our reasonable control, including but
          not limited to acts of God, war, hackers, third party internet
          providers, government orders, power failures, nuclear destruction, or
          any other force majeure event.
        </p>
        <p>
          13. Captions. The section headings in this Agreement are provided for
          convenience only and are not to be considered in the interpretation of
          this Agreement.
        </p>
        <p>
          14. Waiver. Our failure to act with respect to an anticipated or
          actual breach by you or others does not waive our right to act with
          respect to subsequent or similar breaches. Nothing in this section
          shall exclude or restrict your liability arising out of fraud or
          fraudulent misrepresentation.
        </p>
        <p>
          15. Assignment. The purchase, sale, assignment or transfer of any
          right is prohibited. You shall not assign any of your rights under
          this agreement to anyone else.
        </p>
        <p>
          16. Entire Agreement. This Agreement together with the Privacy Policy
          and any additional terms you have agreed to constitute(s) the entire
          agreement with respect to its subject matter and constitutes and
          supersedes all prior agreements, representations and understandings of
          the Parties, written or oral.
        </p>
        <p>
          17. Severability. In the event that any of the provisions contained in
          this Agreement shall, for any reason, be declared or held to be
          unreasonable, unlawful, unenforceable or otherwise invalid in any
          respect, such term or provision shall be deemed modified to the extent
          necessary to make it enforceable, and in no event shall such
          declaration or holding affect the validity of any other provision of
          this Agreement, all of which provisions shall continue with effect in
          accordance with their terms.
        </p>
        <p>
          18. Notice. All notices to you will be effective when we send it to
          the last email or physical address you gave us or posted on our Site
          (You consent to receive communications from us electronically, and we
          will communicate with you in a variety of ways, such as by e-mail,
          text, or by posting notices and messages on the Site). Any notice to
          us will be effective when delivered to us at: hello@sheerba.com.
        </p>
        <p></p>
        <p></p>
      </TextPage>
    )
  }
}
