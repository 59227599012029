import * as logger from './Logger'

import { doOauth } from './BackendService'

export const handleConnectToEtsy = async ({ tracker, email }) => {
  logger.log('handleOAuth')
  tracker.trackEvent({
    category: 'Event',
    actionName: 'Etsy OAuth Button Pressed - Begin',
    sendTelegram: true,
  })

  // var event = new CustomEvent('oauthBegan')
  // window.dispatchEvent(event)

  logger.log('begin Oauth process by requesting link for user to use to login.')
  logger.log(email)
  const apiRedirect = await doOauth({ email: email })
  logger.log(apiRedirect)

  logger.log('save tokens in localstorage')
  localStorage.setItem('etsy_temp_token', apiRedirect.token)
  localStorage.setItem('etsy_temp_secret', apiRedirect.tokenSecret)

  window.open(apiRedirect.loginUrl, '_blank')
}
