import React, { useState } from 'react'

import { useAuth } from '../../../services/contexts/AuthContext'

// import ReactGA from 'react-ga'
import { Container, ToggleButton, ToggleButtonGroup } from 'react-bootstrap'

import './PricingHeader.css'
import '../../../css/stylish-portfolio.min.css'

import PriceComponent from '../../../components/Duplicated/Payment/PriceComponent'

const subscriptionTypeEnum = Object.freeze({
  ANNUAL: 1,
  MONTHLY: 2,
})

export const PricingHeader = (props) => {
  const [subscriptionType, setSubscriptionType] = useState(
    subscriptionTypeEnum.MONTHLY
  )
  const { userEmail, subscriptionId, subscriptionIsCancelled } = useAuth()

  const handleClick = () => {
    if (userEmail == null) {
      return props.onGetStartedPressed()
    } else if (subscriptionId != null && subscriptionIsCancelled === true) {
      return props.onRenewPressed()
    } else {
      return
    }
  }

  const shouldShowGetStarted = () => {
    return userEmail == null
  }

  const shouldShowResubscribe = () => {
    return subscriptionId != null && subscriptionIsCancelled === true
  }

  const getText = () => {
    if (shouldShowGetStarted()) {
      return 'Get Started For Free'
    } else if (shouldShowResubscribe()) {
      return 'Resubscribe'
    } else {
      return ''
    }
  }

  const getStartedButton = () => {
    if (shouldShowResubscribe() || shouldShowGetStarted()) {
      return (
        <div
          className="btn-orange btn-orange-old-text pricing-get-started-button my-5"
          onClick={handleClick}
        >
          {getText()}
        </div>
      )
    } else {
      return <div></div>
    }
  }

  return (
    <div className="pricing-header">
      <Container>
        <div className="row my-auto">
          <div
            className="pricing-title-section my-auto px-5 col-12 col-lg-6 text-center text-lg-left"
            // style={{ backgroundColor: 'red' }}
          >
            <div className="ch1 txt-dark-blue pricing-title-text">
              Choose a pricing plan that suits you
            </div>
            {getStartedButton()}
          </div>
          <div
            className="price-component-positioning col-12 col-lg-6"
            // style={{ backgroundColor: 'red' }}
          >
            <PriceComponent
              isSubscribed={subscriptionId}
              subscriptionIsCancelled={subscriptionIsCancelled}
              email={userEmail}
              onSubscriptionAdded={props.onSubscriptionAdded}
            ></PriceComponent>
          </div>
        </div>
      </Container>
    </div>
  )
}
