import React, { PureComponent } from 'react'

import './SearchTypeToggle.css'
import { SearchType } from '../SearchType'

class SearchTypeToggle extends PureComponent {
  handleChange = () => {
    // console.log('presesed')
  }
  render() {
    return (
      <div className="">
        <div className="txt-blue cp2">Search Type</div>
        <div className="btn-group">
          <div
            className={`btn ${
              parseInt(this.props.searchType) === parseInt(SearchType.TAG)
                ? 'btn-primary'
                : 'btn-outline-primary'
            }`}
            onClick={() => this.props.onSearchTypeToggle(1)}
          >
            Tag
          </div>
          <div
            className={`btn ${
              parseInt(this.props.searchType) === parseInt(SearchType.KEYWORD)
                ? 'btn-primary'
                : 'btn-outline-primary'
            }`}
            onClick={() => this.props.onSearchTypeToggle(2)}
          >
            Keyword
          </div>
        </div>
      </div>
    )
  }
}
export default SearchTypeToggle
