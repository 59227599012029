import React, { Component } from 'react'

import './SignInModal.css'

import backgroundSrc from '../../../img/SignInBackground.png'
import logoImgSrc from '../../../img/icon128.png'
import nameImgSrc from '../../../img/sheerba2.png'

const viewTypeEnum = Object.freeze({
  SIGNIN: 1,
  REGISTER: 2,
})

export default class SignInModalView extends Component {
  render() {
    return (
      // <Form>
      // </Form>
      <div>
        <div className="secondary-modal-blackout-background"></div>
        <div className="login-modal-container">
          <div
            className="login-modal"
            style={{
              backgroundImage: `url(${backgroundSrc})`,
              backgroundPositionY: 'left',
            }}
          >
            <div className="sign-in-modal-close" onClick={this.props.onClose}>
              ✕
            </div>
            <div className="sign-in-logo-section">
              <img className="sign-in-mascot" src={logoImgSrc} alt=""></img>
              <img className="sign-in-logo" src={nameImgSrc} alt=""></img>
            </div>
            <div
              className={
                this.props.viewType === viewTypeEnum.REGISTER
                  ? 'register-form'
                  : 'sign-in-form'
              }
            >
              <div className="title">
                {this.props.viewType === viewTypeEnum.REGISTER
                  ? 'Register'
                  : 'Sign In'}
              </div>
              {this.props.viewType === viewTypeEnum.REGISTER && (
                <input
                  className={`text-input ${this.props.nameError
                    ? `error-txt-color`
                    : `primary-txt-color`
                    }`}
                  placeholder="Full Name"
                  onChange={(event) => {
                    this.props.onNameChange(event.target.value)
                  }}
                ></input>
              )}
              <input
                type="text"
                className={`text-input ${this.props.emailError
                  ? 'error-txt-color'
                  : 'primary-txt-color'
                  }`}
                placeholder="Email"
                onChange={(event) => {
                  this.props.onEmailChange(event.target.value)
                }}
              ></input>
              {this.props.viewType === viewTypeEnum.REGISTER && (
                <input
                  type="password"
                  className={`text-input ${this.props.passwordError
                    ? 'error-txt-color'
                    : 'primary-txt-color'
                    }`}
                  placeholder={
                    this.props.viewType === viewTypeEnum.REGISTER
                      ? 'Create a Password'
                      : 'Password'
                  }
                  onChange={(event) => {
                    this.props.onPasswordChange(event.target.value)
                  }}
                ></input>
              )}
              <div
                className="submit-button blue-button"
                disabled={this.props.submitButtonIsDisabled}
                onClick={this.props.onSubmit}
              >
                {this.props.viewType === viewTypeEnum.REGISTER
                  ? 'Register'
                  : 'Sign In'}
              </div>
              <div
                className="switch-mode-button"
                onClick={this.props.onToggleState}
              >
                {this.props.viewType === viewTypeEnum.REGISTER
                  ? 'Already have an account?'
                  : 'No account yet?'}
              </div>
              <div
                className={`sign-in-error-message ${((this.props.errorNotificationText !== '' && this.props.viewType === viewTypeEnum.REGISTER) || this.props.message !== '')
                  ? 'show-sign-in-error'
                  : 'hide-sign-in-error'
                  }`}
              >
                {this.props.errorNotificationText !== '' && this.props.viewType === viewTypeEnum.REGISTER && (
                  <div className={'error-txt-color'}>
                    {this.props.errorNotificationText}
                  </div>
                )}
                {this.props.message !== '' && (
                  <div className={'success-txt-color'}>
                    {this.props.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
