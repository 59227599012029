import // useTransition,
// animated
'react-spring'

import React, { useState } from 'react'
import * as logger from '../../../services/Logger'

import './TagComponent.css'

const TagComponent = (props) => {
  // const [
  //   showCopied,
  //   // setShowCopied
  // ] = useState(false)
  const [tagText, setTagText] = useState(props.tag)

  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  const handleTagPress = async () => {
    logger.log(`tag pressed:`)
    logger.log(props.tag)

    if (props.copyOnClick) {
      navigator.clipboard.writeText(props.tag)
      // const currentState = showCopied
      setTagText('Copied!')
      props.onClick(props.tag)

      await sleep(2000)
      setTagText(props.tag)
      // logger.log('switching state again.')
      // setShowCopied(currentState)
    } else {
      props.onClick(props.tag)
    }
  }

  // const transition1 = useTransition(showCopied, null, {
  //   from: { opacity: 0 },
  //   enter: { opacity: 1 },
  //   leave: { opacity: 0 },
  //   config: { duration: 1000 },
  // })

  // const text = props.tag

  logger.log('transition')
  logger.log(props)

  return (
    <div className="tag-area">
      <div className="expandableViewTag" onClick={handleTagPress}>
        {tagText}
      </div>
      {/* {transition1.map(({ item, key, props }) =>
        item ? (
          <animated.div
            className='expandableViewTag'
            key={key}
            style={{ props, position: 'absolute' }}
          >
            Copied to Clipboard!
          </animated.div>
        ) : (
          <animated.div
            className='expandableViewTag'
            key={key}
            style={{ props, position: 'absolute' }}
            onClick={handleTagPress}
          >
            {text}
          </animated.div>
        )
      )} */}
    </div>
  )
}
export default TagComponent
