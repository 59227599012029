import * as backend from '../../../services/BackendService'
import { getMessages, writeToDatabase } from '../../../services/DatabaseService'
// import * as tracker from '../../../services/Tracker'
import * as logger from '../../../services/Logger'

export class FeedbackModalInteractor {
  presenter
  userName
  email
  valueToSubmit = ''

  constructor({ userName, email }) {
    this.userName = userName
    this.email = email

    // this.uid = uid
  }

  async getData(interactorData) {
    await getMessages({
      email: this.email,
      completion: (messages) => {
        logger.log('interactor got messages')
        logger.log(messages)

        this.messages = messages.sort((a, b) =>
          a.datetime < b.datetime ? 1 : -1
        )

        const presenterData = {
          messages: this.messages,
        }

        this.presenter.getData(presenterData)
      },
    })
  }

  handleInputChange = (event) => {
    // logger.log('handle input change')
    // logger.log(event.target.value)
    event.preventDefault()
    this.valueToSubmit = event.target.value

    this.presenter.updateText(this.valueToSubmit)
  }
  handleSubmit = (form) => {
    logger.log('submit pressed:')
    logger.log(form.target)
    logger.log(this.valueToSubmit)

    // this.trackSubmission()
    this.writeMessageToDatabase()
    this.sendMessageViaTelegram()
    this.addToMessages()
    this.eraseInput()
  }

  trackSubmission = () => {
    // tracker.trackEvent({
    //   category: 'Button',
    //   actionName: 'Submit Feedback Pressed',
    //   sendTelegram: true,
    // })
  }

  writeMessageToDatabase = () => {
    const data = encodeURIComponent(this.valueToSubmit)
    const msgData = {
      table: 'messages',
      writeData: {
        email: this.email,
        message: data,
      },
    }

    writeToDatabase({ details: msgData })
  }

  sendMessageViaTelegram = () => {
    backend.sendTelegramFeedbackEvent({
      feedback: this.valueToSubmit,
      userName: this.userName,
      userEmail: this.email,
    })
  }

  addToMessages = () => {
    const newMessage = {
      message: this.valueToSubmit,
    }
    this.messages.unshift(newMessage)
    const presenterData = {
      messages: this.messages,
    }
    this.presenter.getData(presenterData)
  }

  eraseInput = () => {
    this.valueToSubmit = ''
    this.presenter.updateText(this.valueToSubmit)
  }
}
