import {
  getAPISearchData,
  getDetailPage,
  getHTMLForUrl,
  getTrendData,
  analyzeTitleInformation,
} from '../../services/BackendService'
import { DetailPageParser } from '../../services/Duplicated/DetailPageParser'
import { getSearchCountForSearchTerm } from '../../services/Duplicated/MainPageParser'

import { handleConnectToEtsy } from '../../services/EtsyOAuthService'

import exportTagsToCSV from '../../services/Duplicated/ExportTags'
import exportToCSV from '../../services/Duplicated/ExportToCSV'
import * as logger from '../../services/Logger'

export class TagSearchInteractor {
  presenter

  mainPageData = {
    displayCount: 0,
    totalSales: 0,
    totalViews: 0,
    totalFavorers: 0,
    totalPrice: 0,
  }

  tracker
  etsyToken
  etsySecret

  // Example Loading Flow
  constructor({ tracker, etsyToken, etsySecret }) {
    this.tracker = tracker
    this.etsyToken = etsyToken
    this.etsySecret = etsySecret
  }
  // getData(interactorData) {
  //   // ... do interactor things

  //   const presenterData = {
  //     url: interactorData.url,
  //   }

  //   this.presenter.getData(presenterData)
  // }

  handleSearchTypeToggle(input) {}

  async handleSearchPress({
    input,
    resultCount,
    searchType,
    categoryNumber,
    currentResultCount,
    clearOnSearch,
  }) {
    logger.log('handleSearchPress')

    this.tracker.trackEvent({
      category: 'Event',
      actionName: `Tag-Search - Search Pressed: \n\tTerm: ${input} \n\tresult count: ${resultCount} \n\tcategory: ${categoryNumber} \n\ttype: ${
        searchType === 1 ? 'Tag' : 'Keyword'
      } \n\tclear on search: ${clearOnSearch}`,
      sendTelegram: true,
    })
    this.presenter.indicateRatingIsLoading(true)
    this.presenter.indicateChartsAreLoading(true)
    this.presenter.indicateAveragesAreLoading(true)
    this.presenter.setError()

    let rowNumber

    if (clearOnSearch) {
      this.presenter.clearResults()
      rowNumber = 0
    } else {
      rowNumber = currentResultCount ?? 0
    }

    if (input === '') {
      return
    }

    logger.log(
      `logger:  ${input}|${categoryNumber}|${searchType}|${this.etsyToken}|${this.etsySecret}`
    )

    const data = await getAPISearchData({
      search: input,
      categoryNumber: categoryNumber,
      type: searchType,
      limit: resultCount,
      etsyToken: this.etsyToken,
      etsySecret: this.etsySecret,
    }).catch((error) => {
      logger.log(error)
      if (error.toString() === 'Error: 403') {
        this.presenter.setError(error)
      }
      this.presenter.indicateRatingIsLoading(false)
      this.presenter.indicateChartsAreLoading(false)
      this.presenter.indicateAveragesAreLoading(false)
      return
    })

    // will be null on error
    if (data == null) return

    const results = data.results
    let processedCount = 0

    if (results.length > 0) {
      this.presenter.showLoadingCounts({
        totalCount: results.length,
        totalProcessed: processedCount,
      })
      // Get data for charts
      this.setRatingInfoForInput(input, data)
    } else {
      const searchDisplayData = {
        totalSales: 0,
        averageFavorers: 0,
        averagePrice: 0,
        // averageViews: pageData.totalViews / pageData.displayCount
      }

      this.presenter.showMainPageData(searchDisplayData)
    }
    this.getTrendDataForSearch(input)

    // console.log('got data back')
    // console.log(data)

    const detailPageParser = new DetailPageParser()
    // const finalResults = []

    // TODO: handle multiple more gracefully.
    // < data.length
    results.forEach((result) => {
      // })
      // for (let i = 0; i < results.length; i++) {
      const listingDetails = result

      const url = `${listingDetails.url}`

      logger.log('get detail page for url')
      logger.log(url)

      const start = url.indexOf('listing/') + 8
      const end = url.indexOf('?')
      const snippet = url.substring(start, end)
      logger.log(snippet)

      // This is being done in completion so that we aren't waiting on multiple awaits in a row. This will mean that they are not necessarily added in order.
      // eslint-disable-next-line no-loop-func
      // TODO: turn on below
      getDetailPage({ url: snippet }).then((html) => {
        // logger.log('got html')
        // logger.log(html)
        // logger.log(html.html)
        const doc = document.implementation.createHTMLDocument()
        doc.write(html.html)

        //update loading count
        processedCount += 1
        if (processedCount < results.length) {
          this.presenter.showLoadingCounts({
            totalCount: results.length,
            totalProcessed: processedCount,
          })
        } else {
          this.presenter.hideLoadingCount()
        }

        const newdata = detailPageParser.parseDetailPageDoc(doc)

        const fullDataObject = Object.assign({}, result, newdata)
        // eslint: this 'dangerously' accesses rowNumber; but since this is where we explicitly need it, this is fine.
        rowNumber += 1
        fullDataObject.dps_rowNumber = rowNumber // dps_rowNumber is used in DisplayObjectFactory. Since there is a high risk of me copy-pasting from the chrome extension, it seems less bug prone to use the weird title in this place rather than changes to the DOF getting overwritten.
        this.mainPageData = this.updateAverages(
          this.mainPageData,
          fullDataObject
        )
        this.showMainPageData(this.mainPageData)

        this.presenter.showResult(fullDataObject)
      })
    })
    // console.log(finalResults)
  }

  updateAverages = (previousPageData, displayObject) => {
    const thisAdCount = displayObject.dp_salePercentage ? 1 : 0
    // const thisViewCount = parseInt(displayObject.views.replace(/[^0-9.-]+/g, ''))
    let thisFavCount
    if (isNaN(displayObject.num_favorers)) {
      thisFavCount = parseInt(
        displayObject.num_favorers.replace(/[^0-9.-]+/g, '')
      )
    } else {
      thisFavCount = displayObject.num_favorers
    }

    let thisPrice
    if (displayObject.price === '-') {
      thisPrice = 0
    } else {
      thisPrice = parseInt(displayObject.price.replace(/[^0-9.-]+/g, ''))
    }

    const pageData = {
      displayCount: (previousPageData.displayCount += 1),
      totalSales: previousPageData.totalSales + thisAdCount,
      // totalViews: previousPageData.totalViews + thisViewCount,
      totalFavorers: previousPageData.totalFavorers + thisFavCount,
      totalPrice: previousPageData.totalPrice + thisPrice,
    }

    return pageData
  }
  showMainPageData = (pageData) => {
    const searchDisplayData = {
      totalSales: pageData.totalSales,
      averageFavorers: pageData.totalFavorers / pageData.displayCount,
      averagePrice: pageData.totalPrice / pageData.displayCount,
      // averageViews: pageData.totalViews / pageData.displayCount
    }

    this.presenter.showMainPageData(searchDisplayData)
  }

  // Get Rating
  setRatingInfoForInput = async (input, data) => {
    logger.log('setRatingInfoForInput')
    const rating = await this.analyzeTitle(input, data).catch((error) => {
      logger.error('error getting rating')
      logger.error(error)
      this.presenter.setMainSearchRating({ rating: '-' })
    })
    logger.log('rating for main search')
    logger.log(rating)
    if (rating == null) return

    const props = {
      rating: rating.rating,
    }
    this.presenter.setMainSearchRating(props)
  }

  analyzeTitle = async (title, data) => {
    // if (this.isAuthenticated() === false) {
    //   logger.error('analyzeTitle but not authenticated')
    // }
    logger.log('analyze title')

    const titleMap = await getSearchCountForSearchTerm(title)
    logger.log('info for analyze title:')
    logger.log(title)
    logger.log(titleMap)
    logger.log(data)

    const titleAnalysis = await analyzeTitleInformation({
      count: titleMap.count,
      ads: titleMap.ads,
      searchTerm: titleMap.searchTerm,
      views: data.views,
      favorers: data.favorers,
    }).catch((error) => {
      logger.error('error getting title info')
      logger.error(error)
      logger.error(title)
      logger.error(titleMap)
      logger.error(data)
      throw error
    })

    // logger.log('title analysis')
    // logger.log(titleAnalysis)

    return titleAnalysis
  }

  async getTrendDataForSearch(term) {
    logger.log('content section mounted')
    logger.log(term)
    const data = await getTrendData({
      currentSearch: term,
      previousSearch: term,
    }).catch((error) => {
      logger.error('get trend data error')
      logger.error(error)
      return
    })

    if (data === undefined) {
      return
    }

    logger.log('get trend data')
    logger.log(data)

    // this.previousSearch = 'dog'
    // this.currentSearch = term

    this.presenter.showChartData({
      pastChartData: data.googleTrend,
      predictedChartData: data.sheerbaPrediction,
    })
  }

  handleConnectEtsyPressed = (email) => {
    if (email == null) return

    handleConnectToEtsy({
      tracker: this.tracker,
      email: email,
    })
  }

  // Table Button Functions
  handleSaveToCSVPressed = (dataRows) => {
    logger.log('handleSaveToCSV')
    exportToCSV(dataRows)
  }

  handleSubscribeButtonPressed = () => {
    logger.log('handleSubscribeButtonPressed')
  }

  handleExtractTagsPressed = (dataRows) => {
    logger.log('handleExtractTagsPressed')
    let tags = new Set()

    dataRows.forEach((dataRow) => {
      const spittingToTags = dataRow['tags'] //.split(',')

      if (Array.isArray(spittingToTags)) {
        spittingToTags.forEach((tag) => {
          const cleaned = tag.trim().replace(/^"|"$/g, '')
          if (cleaned !== '') {
            tags.add(cleaned)
          }
        })
      }
    })
    exportTagsToCSV(tags)
  }
}
