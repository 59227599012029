export class PricingPagePresenter {
  view

  // constructor({ etsyToken, etsySecret, url, onDetailPage }) {
  //     this.etsyToken = etsyToken
  //     this.etsySecret = etsySecret
  //     this.onDetailPage = onDetailPage
  //   }

  // getData = (presenterData) => {
  //   // ... do presenter things

  //   const viewProps = {
  //     urlString: presenterData.url,
  //   };

  //   this.view.setViewState(viewProps);
  // };

  removeModal = () => {
    this.view.removeModal()
  }
}
