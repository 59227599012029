import React, { useEffect, PureComponent } from 'react'
// import TagSearch from '../../pages/TagSearch'

import { TagSearchInteractor } from './TagSearchInteractor'
import { TagSearchPresenter } from './TagSearchPresenter'
import { TagSearchView } from './TagSearchView'

import { FeedbackModalComponent } from '../../components/Duplicated/FeedbackModal/FeedbackModalController'

import { SearchType } from './SearchType'

import * as logger from '../../services/Logger'

import { useAuth } from '../../services/contexts/AuthContext'

export const TagSearch = (props) => {
  const {
    isLoading,
    hasPremiumAccess,
    subscriptionStatus,
    etsyToken,
    etsySecret,
    userEmail,
    tracker,
  } = useAuth()

  logger.log('hasPremiumAccess')
  logger.log(hasPremiumAccess)

  useEffect(() => {
    tracker.trackEvent({
      category: 'View',
      actionName: 'Tag Search',
      sendTelegram: true,
    })
  }, [tracker])

  if (isLoading) {
    return <div></div>
  }

  return (
    <TagSearchComponent
      userEmail={userEmail}
      hasPremiumAccess={hasPremiumAccess}
      etsyToken={etsyToken}
      etsySecret={etsySecret}
      tracker={tracker}
      subscriptionStatus={subscriptionStatus}
    ></TagSearchComponent>
  )
}

class TagSearchComponent extends PureComponent {
  interactor
  presenter

  loadingState = {
    results: [],
    tableData: [],
    clearOnSearch: false,
    searchType: SearchType.TAG,
    categoryNumber: 0,
    pastChartData: [],
    predictedChartData: [],
    // search collective data
    mainSearchRating: '',
    averageFavorers: '',
    averagePrice: '',
    totalSales: '',
    showFeedback: false,
    loadingString: '',
    chartsAreLoading: false,
    ratingIsLoading: false,
    averagesAreLoading: false,
    resultCount: 25, // should match the first value of # of results picker found in TagSearchBar
    error: undefined,
  }

  setupVIP = () => {
    this.presenter = new TagSearchPresenter()
    this.interactor = new TagSearchInteractor({
      tracker: this.props.tracker,
      etsyToken: this.props.etsyToken,
      etsySecret: this.props.etsySecret,
    })

    this.interactor.presenter = this.presenter
    this.presenter.view = this
  }

  constructor(props) {
    super(props)
    this.setupVIP()

    this.state = this.loadingState

    // this.searchTerm = '12'
    // this.interactor.handleSearchPress({
    //   input: '12',
    //   searchType: this.state.searchType,
    //   categoryNumber: this.state.categoryNumber,
    //   currentResultCount: this.state.results.length,
    //   clearOnSearch: this.state.clearOnSearch,
    // })
  }

  // Example Loading Flow
  // componentDidMount() {
  //   const propUrl = 'example.com' //this.props.url
  //   const interactorData = {
  //     url: propUrl,
  //   }
  //   this.interactor.getData(interactorData)
  // }
  //   componentDidUpdate(previousProps) {
  //     if (previousProps.url !== this.props.url) {
  //       this.setState(this.initialState)
  //       this.interactor.getData(this.props.url)
  //     }
  //   }
  // setViewState(viewProps) {
  //   this.setState({
  //     urlString: viewProps.urlString,
  //   })
  // }

  setLoadingString({ loadingString }) {
    this.setState({
      loadingString: loadingString,
    })
  }

  showResultsAndAveragesInTable(shouldShow) {
    this.setState({
      tableData: shouldShow ? this.state.results : [],
      averagesAreLoading: !shouldShow,
    })
  }

  clearResults() {
    this.setState({
      results: [],
    })
  }

  showResult(result) {
    this.setState({
      results: [...this.state.results, result],
    })
  }

  setAveragesLoading(areLoading) {
    this.setState({
      averagesAreLoading: areLoading,
    })
  }
  setRatingLoading = ({ isLoading }) => {
    this.setState({
      ratingIsLoading: isLoading,
    })
  }
  setChartLoading = ({ areLoading }) => {
    this.setState({
      chartsAreLoading: areLoading,
    })
  }
  showChartData = ({ pastChartData, predictedChartData, areLoading }) => {
    this.setState({
      pastChartData: pastChartData,
      predictedChartData: predictedChartData,
      chartsAreLoading: areLoading,
    })
  }
  showMainSearchTermRating = ({ rating }) => {
    this.setState({
      mainSearchRating: rating,
    })
  }

  showPageData = (displayPageData) => {
    this.setState({
      averageFavorers: displayPageData.averageFavorers,
      averagePrice: displayPageData.averagePrice,
      totalSales: displayPageData.totalSales,
    })
  }

  setError = (error) => {
    this.setState({
      error: error,
    })
  }

  handleSearchTypeChange = (value) => {
    logger.log('onSearchTypeToggle')
    logger.log(value)
    this.setState({
      searchType: value,
    })
  }

  handleCategoryToggle = (event) => {
    this.setState({
      categoryNumber: event.target.value,
    })
  }
  handleResultCountToggle = (newResultCount) => {
    logger.log('onResultCountToggle')
    logger.log(newResultCount)
    this.setState({
      resultCount: newResultCount,
    })
  }

  handleClearTogglePressed = () => {
    this.setState({
      clearOnSearch: !this.state.clearOnSearch,
    })
  }

  handleFeedbackButtonPress = () => {
    this.props.tracker.trackEvent({
      category: 'Event',
      actionName: 'Tag-Search - Contact Us Pressed',
      sendTelegram: true,
    })
    logger.log('handleFeedbackButtonPress')
    this.setState({
      showFeedback: true,
    })
  }
  handleModalClose = () => {
    this.setState({
      showFeedback: false,
    })
  }

  handleSubscribeButtonPressed = () => {
    window.open('https://sheerba.com/pricing', '_blank')
  }

  searchTerm = ''
  render() {
    return (
      <div>
        {/* {this.state.showFeedback && (
          <FeedbackModalComponent
            onClose={() => {
              this.handleModalClose()
            }}
            userName={this.props.currentUser.name}
            email={this.props.currentUser.email}
          ></FeedbackModalComponent>
        )} */}
        <TagSearchView
          etsyIsConnected={this.props.etsyToken != null}
          error={this.state.error}
          loadingString={this.state.loadingString}
          showFeedback={this.state.showFeedback}
          onFeedbackButtonPress={this.handleFeedbackButtonPress}
          onClearTogglePressed={this.handleClearTogglePressed}
          clearOnSearchIsSelected={this.state.clearOnSearch}
          onSearchTypeToggle={this.handleSearchTypeChange} //{(input) => this.interactor.handleSearchTypeToggle}
          onCategoryToggle={this.handleCategoryToggle}
          onResultCountToggle={this.handleResultCountToggle}
          onConnectEtsyPressed={() =>
            this.interactor.handleConnectEtsyPressed(this.props.userEmail)
          }
          onSearchPress={(input) => {
            this.searchTerm = input
            this.interactor.handleSearchPress({
              input: input,
              resultCount: this.state.resultCount,
              searchType: this.state.searchType,
              categoryNumber: this.state.categoryNumber,
              currentResultCount: this.state.tableData.length,
              clearOnSearch: this.state.clearOnSearch,
            })
          }}
          results={this.state.tableData}
          searchType={this.state.searchType}
          // AnalyticDataComponent
          tableData={this.state.pastChartData}
          searchTerm={this.searchTerm}
          predictedData={this.state.predictedChartData}
          chartsAreLoading={this.state.chartsAreLoading}
          ratingIsLoading={this.state.ratingIsLoading}
          averagesAreLoading={this.state.averagesAreLoading}
          showSearchIsLoading={
            this.state.chartsAreLoading === true ||
            this.state.loadingString !== ''
          }
          hasPremiumAccess={this.props.hasPremiumAccess}
          subscriptionStatus={this.props.subscriptionStatus}
          onGoogleChartPress={this.props.onGoogleChartPress}
          onFutureChartPress={this.props.onFutureChartPress}
          onBadgePress={this.props.onBadgePress}
          rating={this.state.mainSearchRating}
          averageFavorers={this.state.averageFavorers}
          averagePrice={this.state.averagePrice}
          totalSales={this.state.totalSales}
          tracker={this.props.tracker}
          onSubscribeButtonPressed={this.handleSubscribeButtonPressed}
          // Table Button Params
          onSaveToCSVPressed={() =>
            this.interactor.handleSaveToCSVPressed(this.state.tableData)
          }
          onExtractTagsPressed={() =>
            this.interactor.handleExtractTagsPressed(this.state.tableData)
          }
          buttonsEnabled={this.state.tableData.length > 0}
        ></TagSearchView>
      </div>
    )
  }
}
export default TagSearchComponent
