import React from 'react'

import './Loader.css'

export const Loader = () => {
  return (
    <div className="row justify-content-center">
      <div className="snippet" data-title=".dot-flashing">
        <div className="stage">
          <div className="dot-flashing"></div>
        </div>
      </div>
    </div>
  )
}
