import React, { Component } from 'react'
import { Container } from 'react-bootstrap'
import { Footer } from './Footer/Footer'

class TextPage extends Component {
  render() {
    return (
      <section>
        <Container className="my-5">
          <div style={{ textAlign: 'center', padding: 30 }}>
            <h2>{this.props.title}</h2>
            <p>{this.props.subtitle}</p>
          </div>
          <div style={{ padding: 10 }}></div>
          {this.props.children}
        </Container>
        <Footer></Footer>
      </section>
    )
  }
}

export default TextPage
