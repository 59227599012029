// import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

import * as logger from './Logger'
import * as config from '../config.json'

// const firebaseApp = firebase.initializeApp(firebaseConfig)
// const db = firebaseApp.firestore()
import app from './Authentication/firebase'
const db = app.firestore()

export const getMessages = async ({ email, completion }) => {
  logger.log('geting messages')
  logger.log(email)
  const querySnapshot = await db
    .collection('messages')
    .where('email', '==', email) // hardcode uid here
    .get()
    .then((querySnapshot) => {
      logger.log('returning response of get user info for email:')
      return querySnapshot
    })
    .catch(function (error) {
      logger.log('Error getting documents: ')
      logger.log(error)
      throw error
    })

  let messages = []
  logger.log('get messages query')
  querySnapshot.forEach((doc) => {
    const text = decodeURIComponent(doc.data().message)
    logger.log(text)
    logger.log(doc.data().datetime)

    let fromSheerba = false

    if (doc.data().from_sheerba) {
      fromSheerba = true
    }

    const message = {
      message: text,
      datetime: doc.data().datetime,
      fromSheerba: fromSheerba,
    }
    messages.push(message)
  })
  logger.log('returning messages')
  logger.log(messages)
  completion(messages)
}

export async function writeToDatabase({ details }) {
  const table = getTableName(details.table)

  details.writeData['datetime'] = new Date().toISOString()

  logger.log('writing data:')
  logger.log(details)
  logger.log(details.writeData)

  return await db
    .collection(table)
    .add(details.writeData)
    .then((result) => {
      logger.log('successful db write:')
      logger.log(details)
      logger.log(result)
      return result
    })
    .catch((error) => {
      logger.error('unsuccessful db write')
      logger.error(details)
      logger.error(error)
      throw error
    })
}

function getTableName(table) {
  const tableName =
    config.WRITE_TO_DATABASE[config.VALUE_KEY] === true || table === 'messages'
      ? table
      : `TEST_${table}`

  return tableName
}

export default async function writeToUserEbookDatabase({ writeData }) {
  // logger.log('write to database:')
  // logger.log(writeData)
  return await db
    .collection('ebook_users')
    .add(writeData)
    .then((result) => {
      // logger.log('successful db write:')
      // logger.log(result)
      return result
    })
    .catch((error) => {
      // logger.error('unsuccessful db write')
      // logger.error(error)
      throw error
    })
}

// GET
export const getCustomerInfo = async ({ email }) => {
  logger.log('getCustomerInfo start')
  const querySnapshot = await getUserInfoForEmail({ email: email }).catch(
    (error) => {
      logger.error('unsuccessful getUserInfo')
      logger.error(error)
      throw error
    }
  )

  if (querySnapshot.size === 0) return null

  return await getOldestRecordAndDeleteMoreRecent(querySnapshot)
}

export const getInfoForUser = async ({ email }) => {
  logger.log(`DB: GetOAuthForUser: ${email}`)
  const querySnapshot = await getUserInfoForEmail({ email: email })
  logger.log('checking querySnapshot')
  logger.log(querySnapshot)

  if (querySnapshot.size === 0) return null

  // This assumes there could be duplicate records but that the customerId will always be the same (one customer per email)
  const customerRecord = await getOldestRecordAndDeleteMoreRecent(querySnapshot)

  logger.log('customerRecord')
  logger.log(customerRecord.data())
  return customerRecord.data()
}

// Save User
export async function getUserInfoForEmail({ email }) {
  logger.log('getUserInfoForEmail')
  logger.log(email)
  const querySnapshot = await db
    .collection('users')
    .where('email', '==', email)
    .get()
    .then((querySnapshot) => {
      logger.log('returning response of get user info for email:')
      return querySnapshot
    })
    .catch(function (error) {
      logger.log('Error getting documents: ')
      logger.log(error)
      throw error
    })

  if (querySnapshot.size > 1) {
    const oldest = await getOldestRecordAndDeleteMoreRecent(querySnapshot)
    return oldest
  } else {
    return querySnapshot
  }
}

export async function getUserListener({ email, handleUpdate }) {
  const unsubscribe = db
    .collection('users')
    .where('email', '==', email)
    .onSnapshot((snapshot) => {
      logger.log('got changes 1')
      let changes = snapshot.docChanges()
      logger.log(changes)

      changes.forEach((change) => {
        // logger.log(change.doc.data())
        handleUpdate(change.doc.data())
      })
    })

  return unsubscribe
}

export const getOldestRecordAndDeleteMoreRecent = async (querySnapshot) => {
  logger.log('getOldestRecordAndDeleteMoreRecent. querySnapshot:')
  logger.log(querySnapshot)
  logger.log(querySnapshot.size)

  if (querySnapshot.size === undefined) {
    return querySnapshot
  }

  let oldest
  querySnapshot.forEach(async (doc) => {
    const signOnTime = doc.data().firstSignOnTime

    if (signOnTime == null) {
      // Delete from db.
      doc.ref.delete()
    } else if (oldest == null) {
      oldest = doc
    } else {
      // keep oldest
      // Technically <= could lead to the unpredictable deletion of one record vs. the other. I don't think this matters, but consider this if there is a bug.
      if (oldest.data().firstSignOnTime <= signOnTime) {
        doc.ref.delete()
      } else {
        oldest.ref.delete()
        oldest = doc
      }
    }
  })
  return oldest
}

export async function writeToUserDatabase({ writeData }) {
  return await db
    .collection('users')
    .add(writeData)
    .then((result) => {
      return result
    })
    .catch((error) => {
      throw error
    })
}

export async function updateUserName({ email, newName }) {
  logger.log('updating user name')
  logger.log(email)
  logger.log(newName)
  const snapshot = await getUserInfoForEmail({ email: email })
  logger.log('snapshot')
  logger.log(snapshot)
  logger.log(snapshot.doc)
  logger.log(snapshot.length)

  if (snapshot.length > 1) {
    throw Error({ message: 'too many users' })
  }

  logger.log('updating a user')
  logger.log(snapshot.docs[0].id, '=> ', snapshot.docs[0].data())
  await db
    .collection('users')
    .doc(snapshot.docs[0].id)
    .update({
      name: newName,
    })
    .then((e) => {
      logger.log('done here')
      return e
    })
    .catch((e) => {
      logger.log('caught error at db service')
      throw e
    })

  logger.log('done updating users')
  return true
}

export function update({
  collection,
  doc,
  customerId,
  subscriptionId,
  subscriptionCancelAt,
}) {
  db.collection(collection).doc(doc).update({
    customerId: customerId,
    subscriptionId: subscriptionId,
    subscriptionCancelAt: subscriptionCancelAt,
  })
}

export function updateCustomerId({ doc, customerId }) {
  db.collection('users').doc(doc).update({
    customerId: customerId,
  })
}

export function updateSubscriptionCancelAt({ doc, subscriptionCancelAt }) {
  db.collection('users').doc(doc).update({
    subscriptionCancelAt: subscriptionCancelAt,
  })
}

export const saveFirstSignOn = async ({
  email,
  name,
  firstSignOnTime,
  trialDurationSeconds,
  searchCount,
}) => {
  const querySnapshot = await getUserInfoForEmail({ email: email })

  // if there is no record, then save as this is the first time.
  if (querySnapshot == null || querySnapshot.size === 0) {
    logger.log('writing new customer from saveFirstSignOn')
    const writeData = {
      email: email,
      name: name,
      firstSignOnTime: firstSignOnTime,
      trialDurationSeconds: trialDurationSeconds,
      searchCount: searchCount,
    }
    logger.log('saving first sign on. writeData:')
    logger.log(writeData)
    writeToUserDatabase({ writeData: writeData })
  } else if (querySnapshot.size > 1) {
    logger.error(
      'Duplicate records should have been dealt by getUserInfoForEmail at saveFirstSignOn',
      email
    )
    getOldestRecordAndDeleteMoreRecent(querySnapshot)
  }
  // querySnapshot is expected to be be size 1; so falling here is fine
}
