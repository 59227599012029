export default function exportTagsToCSV(tags) {
  let csv = []

  const titles = ['Unique Tags']
  csv.push(titles.join(','))

  //loops through rows
  tags.forEach((tag) => {
    csv.push(tag)
  })

  let joinedCSV = csv.join('\n')
  downloadCSV(joinedCSV, 'Tags.csv')

  function downloadCSV(csv, filename) {
    let csvFile
    let downloadLink

    // CSV file
    csvFile = new Blob([csv], {
      type: 'text/csv',
    })

    // Download link
    downloadLink = document.createElement('a')

    // File name
    downloadLink.download = filename

    // Create a link to the file
    downloadLink.href = window.URL.createObjectURL(csvFile)

    // Hide download link
    downloadLink.style.display = 'none'

    // Add the link to DOM
    document.body.appendChild(downloadLink)

    // Click download link
    downloadLink.click()
  }
}
