import React from 'react'

import Navigation from '../components/Navbar/Navbar'

export default function Loading() {
  return (
    <div>
      <Navigation></Navigation>
    </div>
  )
}
