import history from '../../history'

import * as subscriptionService from '../../services/SubscriptionService'
import * as logger from '../../services/Logger'

export class PricingPageInteractor {
  presenter
  email

  subscriptionId
  subscriptionCancelAt

  handleGetStartedPressed = () => {
    history.push('/login')
  }

  handleSubscriptionAdded = (data) => {
    // tracker.trackEvent({
    //   category: 'Button',
    //   actionName: 'Subscription Added',
    //   sendTelegram: true,
    // })
    logger.log(`Subscription:`)
    logger.log(data)

    const { subscription } = data

    // TODO: 1. If we have a record, then we should update it with the new subscription. right?

    // 2. If there is no record for the customer yet, then we should make one.
    subscriptionService.saveSubscription({
      email: this.email,
      customerId: subscription.customer,
      subscriptionId: subscription.id,
      subscriptionCancelAt: null,
    })

    // 3. Then update the viewState
    this.subscriptionId = subscription.id
    this.subscriptionCancelAt = null

    // Go to main functionality
    history.push('/tag-search')
    // history.push('/')
  }

  handleRenewSubscription = ({
    email,
    subscriptionId,
    subscriptionCancelAt,
  }) => {
    // tracker.trackEvent({
    //   category: 'Button',
    //   actionName: 'Resubscribe Confirmation button Pressed',
    //   sendTelegram: true,
    // })
    if (subscriptionId == null && subscriptionCancelAt == null) return

    // 1. call backend to cancel subscription
    subscriptionService
      .renewSubscription({ email: email, subscriptionId: subscriptionId })
      .catch((error) => {
        logger.error('renew subscription hit error.')
        logger.error(error)
        // this.setState({
        //   showError: error.message,
        //   viewState: viewStateEnum.SEARCH,
        //   needToRecheckConnectionToStripe: true,
        // })
        return
      })

    // 3. then update view state
    // this.setState({
    //   viewState: viewStateEnum.SEARCH,
    //   subscriptionCancelAt: null,
    //   hasPremiumAccess: this.determingWhetherSubscriptionIsActive({
    //     subscriptionCancelAt: null,
    //     subscriptionId: this.state.subscriptionId,
    //     searchCount: this.state.searchCount,
    //   }),
    // })
    this.presenter.removeModal()
  }

  handleCustomerCreatedSubscriptionFailed = () => {
    console.log('handleCustomerCreatedSubscriptionFailed')
  }

  cancelSubscription = async () => {
    logger.log(`cancel subscription:`)
    // tracker.trackEvent({
    //   category: 'Button',
    //   actionName: 'Cancel Subscription',
    //   sendTelegram: true,
    // })
    if (this.subscriptionId == null) return

    // 1. call backend to cancel subscription
    const updatedSubscription = await subscriptionService
      .cancelSubscription({
        email: this.email,
        subscriptionId: this.subscriptionId,
      })
      .catch((error) => {
        logger.log('error message:')
        logger.log(error)
        // this.setState({
        //   needToRecheckConnectionToStripe: true,
        //   showError: error.message,
        //   viewState: viewStateEnum.SEARCH,
        // })
        return
      })

    if (updatedSubscription == null) return

    // 2. then update database
    subscriptionService.cancelSubscription({
      email: this.email,
      cancelAt: updatedSubscription.cancel_at,
    })

    // 3. then update view state
    this.subscriptionCancelAt = updatedSubscription.cancel_at
  }
  renewSubscription = async () => {
    logger.log(`Renew subscription:`)
    // tracker.trackEvent({
    //   category: 'Button',
    //   actionName: 'Resubscribe Confirmation button Pressed',
    //   sendTelegram: true,
    // })
    if (this.subscriptionId == null && this.subscriptionCancelAt == null) return

    // 1. call backend to cancel subscription
    subscriptionService
      .renewSubscription({ subscriptionId: this.subscriptionId })
      .catch((error) => {
        logger.error('renew subscription hit error.')
        logger.error(error)
        return
      })

    // 2. then update database
    subscriptionService.renewSubscription({
      email: this.email,
    })

    this.subscriptionCancelAt = null
  }
  onCustomerCreatedSubscriptionFailed = (data) => {
    logger.log(`Customer created but subscription failed:`)
    logger.log(data)

    const { subscription } = data

    // TODO: 1. If we have a record, then we should update it with the new subscription. Right?

    // 2. If there is no record for the customer yet, then we should make one.
    subscriptionService.writeFailedSubscription({
      email: this.email,
      customerId: subscription.customer,
    })

    // 3. Then update the viewState
    // TODO: It seems like viewState is about as right as I want it. It's unclear right now if I need to add any extra state to handle the weird subscription state.
  }
}
