import React from 'react'
import ReactGA from 'react-ga'
import { useAuth } from '../../../services/contexts/AuthContext'

import './BodyCallout.css'
import rocket from './rocket.png'
import bottomBackground from './BottomBackground.png'
import { Container } from 'react-bootstrap'

export const BodyCallout = (props) => {
  const { tracker } = useAuth()

  const handleOnClick = () => {
    tracker.trackEvent({
      category: 'Event',
      actionName: 'BodyCallout - Get Extension Pressed',
      sendTelegram: true,
    })
    ReactGA.event({
      category: 'Button',
      action: 'BodyCallout CTA',
    })
  }
  return (
    <div>
      <Container>
        <div className="text-center">
          <div className="row">
            <div className="col-12 col-sm-6 align-middle my-auto">
              <div className="mx-auto mb-5 ch1 txt-dark-blue">
                Skyrocket your results today!
              </div>
              <a
                className="btn-orange btn-orange-old-text"
                href="https://chrome.google.com/webstore/detail/sheerba/effgadpnajejiggfiimjjnkhaggcgdfb"
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleOnClick}
              >
                Download Now!
              </a>
            </div>
            <div className="col-12 col-sm-6 order-first order-sm-last">
              <img src={rocket} alt="rocket" className="w-100"></img>
            </div>
          </div>
        </div>
      </Container>
      <img src={bottomBackground} alt="background" className="w-100"></img>
    </div>
  )
}
