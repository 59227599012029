import React, { PureComponent } from 'react'
import { Form } from 'react-bootstrap'

import './FeedbackModalView.css'

// TODO: get background image
export class FeedbackModalView extends PureComponent {
  handleSubmit = (event) => {
    event.preventDefault()
  }

  count = 0

  noFeedbackYetComponent = () => {
    return (
      <div className="feedback-none-yet-area">
        <Form onSubmit={this.handleSubmit}>
          <Form.Group>
            <Form.Label className="feedback-header-text">
              Thanks for contacting us! Let us know what's up below.
            </Form.Label>
            <textarea
              className="feedback-form"
              type="text"
              rows={4}
              placeholder="Type your feedback here..."
              onChange={this.props.onInputChange}
            />
          </Form.Group>
        </Form>
        <div
          className="blue-button feedback-submit-button"
          onClick={this.props.onSubmit}
        >
          Submit
        </div>
      </div>
    )
  }

  createMarkup(message) {
    // const html = message.replace(/\n*\n/g, '<br>')
    // console.log(message.find());
    let text = message.replaceAll('/n', '<br>')
    text = text.replace(/\n/g, '<br>')

    return { __html: `${text}` }
  }

  sheerbaMessageComponent = (message) => {
    this.count += 1
    return (
      <div className="feedback-sheerba-message-box" id={this.count}>
        <div className="feedback-sheerba-message">
          <div className="feedback-sheerba-name">Sheerba - Patrick: </div>
          <div dangerouslySetInnerHTML={this.createMarkup(message)}>
            {/* {message} */}
          </div>
        </div>
      </div>
    )
  }

  userMessageComponent = (message) => {
    this.count += 1
    return (
      <div className="feedback-user-message-box" id={this.count}>
        <div className="feedback-user-message">
          {/* <div>YOU: </div> */}
          <div dangerouslySetInnerHTML={this.createMarkup(message)}></div>
        </div>
      </div>
    )
  }

  showFeedbackMessagesComponent = () => {
    return (
      <div>
        <div className="feedback-messages-area">
          {this.props.messages.map((message) =>
            message.fromSheerba
              ? this.sheerbaMessageComponent(message.message)
              : this.userMessageComponent(message.message)
          )}
        </div>
        <div className="feedback-messaging-area">
          <Form onSubmit={this.handleSubmit}>
            <Form.Group>
              <textarea
                className="feedback-form-with-messages"
                type="text"
                rows={2}
                placeholder=""
                value={this.props.text}
                onChange={this.props.onInputChange}
              />
            </Form.Group>
          </Form>
          <div
            className="blue-button feedback-submit-button"
            onClick={this.props.onSubmit}
          >
            Send
          </div>
        </div>
      </div>
    )
  }

  waitForInfoComponent = () => {
    return (
      <div className="feedback-loader-center">
        <div
          className="spinner-border float-center spinner-border-sm ml-2"
          role="status"
          aria-hidden="true"
        ></div>
      </div>
    )
  }

  render() {
    this.messagesToShow = this.props.messages.length > 0
    // console.log(this.messagesToShow)
    this.componentToShow = undefined
    if (this.props.retrievalComplete === false) {
      this.componentToShow = this.waitForInfoComponent()
    } else if (this.messagesToShow) {
      this.componentToShow = this.showFeedbackMessagesComponent()
    } else {
      this.componentToShow = this.noFeedbackYetComponent()
    }
    this.height = '450px'

    if (this.messagesToShow && this.props.retrievalComplete === true) {
      this.height = '670px'
    }

    return (
      <div>
        <div className="feedback-modal-background"></div>
        <div className="feedback-modal-container">
          <div
            className="feedback-modal"
            style={{
              height: this.height,
            }}
            // style={{
            //   backgroundImage: `url(${this.props.feedbackBackground})`,
            //   backgroundPositionY: 'left',
            // }}
          >
            <div className="feedback-modal-close" onClick={this.props.onClose}>
              ✕
            </div>
            <div className="feedback-title">Feedback</div>
            {this.componentToShow}
          </div>
        </div>
      </div>
    )
  }
}
