import React, { PureComponent } from 'react'

import {
  AreaChart,
  // Line,
  XAxis,
  YAxis,
  // CartesianGrid,
  Tooltip,
  // Legend,
  Area,
} from 'recharts'

import warningIcon from './warningIcon.png'
import lockImg from '../../../img/lockedIcon.png'
import { Loader } from './Loader/Loader'

class CustomizedDot extends PureComponent {
  render() {
    return <div></div>
  }
}

const NoFutureData = () => {
  return (
    <div className="future-chart-insufficient-data">
      <img
        src={warningIcon}
        alt=""
        className="future-chart-insufficient-data-img"
      ></img>
      <div className="future-chart-insufficient-title">
        The term doesn't have enough trend informationn to estimate a future
        trend.
      </div>
    </div>
  )
}

const LoadingTable = () => {
  return (
    <div className="future-chart-placeholder mr-auto ml-auto mr-lg-0">
      <div
        className="chart-title"
        // onClick={onFutureChartPress}
      >
        The Future, from Sheerba
      </div>
      <div className="mt-5">
        <Loader></Loader>
      </div>
    </div>
  )
}

const PlaceholderTable = () => {
  return (
    <div className="future-chart-placeholder mr-auto ml-auto mr-lg-0">
      <div
        className="chart-title-blurred"
        // onClick={onFutureChartPress}
      >
        The Future, from Sheerba
      </div>
    </div>
  )
}

const PaywallTable = (props) => {
  const PREVIEW_DATA = [
    { name: 'Oct 2020', 'Example Term': 90 },
    { name: 'Nov 2020', 'Example Term': 100 },
    { name: 'Dec 2020', 'Example Term': 20 },
    { name: 'Jan 2021', 'Example Term': 40 },
    { name: 'Feb 2021', 'Example Term': 70 },
    { name: 'Mar 2021', 'Example Term': 60 },
    { name: 'Apr 2021', 'Example Term': 40 },
    { name: 'May 2021', 'Example Term': 50 },
    { name: 'Jun 2021', 'Example Term': 60 },
    { name: 'Jul 2021', 'Example Term': 90 },
    { name: 'Aug 2021', 'Example Term': 100 },
    { name: 'Sep 2021', 'Example Term': 50 },
  ]

  return (
    <div
      className="future-chart-blurred mr-auto ml-auto mr-lg-0"
      onClick={props.onSubscribeButtonPressed}
    >
      <div className="chart-title-blurred">The Future, from Sheerba</div>
      <div className="blur-chart-area">
        <img src={lockImg} alt="locked" className="chart-locked-icon"></img>
      </div>
      <AreaChart
        className="chart-body"
        width={315}
        height={100}
        data={PREVIEW_DATA}
        margin={{
          top: 0,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <defs>
          <linearGradient id="colorUv2" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#F2A864" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#F2A864" stopOpacity={0} />
          </linearGradient>
        </defs>
        <Area
          dataKey={'Example Term'}
          stroke="#F2A864"
          fill="url(#colorUv2)"
          dot={<CustomizedDot />}
        ></Area>
      </AreaChart>
    </div>
  )
}

export const getFutureChart = ({
  searchTerm,
  chartsAreLoading,
  tableData,
  hasPremiumAccess,
  predictedData,
  onSubscribeButtonPressed,
}) => {
  if (chartsAreLoading && chartsAreLoading === true) {
    return <LoadingTable />
  } else if (searchTerm !== '' && chartsAreLoading === false) {
    if (hasPremiumAccess) {
      if (tableData.length === 0) {
        return <NoFutureData />
      } else {
        return (
          <div className="future-chart mr-auto ml-auto mr-lg-0">
            <div
              className="chart-title"
              // onClick={onFutureChartPress}
            >
              The Future, from Sheerba
            </div>
            <AreaChart
              className="chart-body"
              width={350}
              height={100}
              data={predictedData}
              margin={{
                top: 0,
                right: 60,
                left: -30,
                bottom: 0,
              }}
            >
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <defs>
                <linearGradient id="colorUv2" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#F2A864" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#F2A864" stopOpacity={0} />
                </linearGradient>
              </defs>
              <Area
                dataKey={searchTerm}
                stroke="#F2A864"
                fill="url(#colorUv2)"
                dot={<CustomizedDot />}
              ></Area>
            </AreaChart>
          </div>
        )
      }
    } else {
      return (
        <PaywallTable onSubscribeButtonPressed={onSubscribeButtonPressed} />
      )
    }
  } else {
    return <PlaceholderTable />
  }
}
