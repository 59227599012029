import React, { useEffect } from 'react'
import Navigation from './components//Navbar/Navbar'
import { Routes } from './Routes'
import ReactGA from 'react-ga'
import { AuthProvider } from './services/contexts/AuthContext'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  useEffect(() => {
    const trackingId = 'UA-169221160-1'
    ReactGA.initialize(trackingId)

    // report page view
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  return (
    <AuthProvider>
      <Navigation />
      <Routes />
      <ToastContainer />
    </AuthProvider>
  )
}

export default App
