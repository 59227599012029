const keys = [
  'listing_id',
  'state',
  'user_id',
  'category_id',
  'creation_tsz',
  'ending_tsz',
  'original_creation_tsz',
  'last_modified_tsz',
  'price',
  'currency_code',
  'sku',
  'tags',
  'materials',
  'shop_section_id',
  'featured_rank',
  'url',
  'shipping_template_id',
  'processing_min',
  'processing_max',
  'who_made',
  'is_supply',
  'when_made',
  'item_weight',
  'item_weight_unit',
  'item_length',
  'item_width',
  'item_height',
  'item_dimensions_unit',
  'is_private',
  'recipient',
  'occasion',
  'style',
  'non_taxable',
  'is_customizable',
  'is_digital',
  'file_data',
  'should_auto_renew',
  'language',
  'has_variations',
  'taxonomy_id',
  'taxonomy_path',
  'used_manufacturer',
  'is_vintage',
]

function makeDisplayObject(productMap) {
  let item = {}

  // from salesparser
  item['sales'] = productMap['sales']

  // from api
  item['num_favorers'] = numberWithCommas(productMap['num_favorers'], '0')
  item['quantity'] = numberWithCommas(productMap['quantity'], '-')
  item['tag_string'] = makeTagListString(productMap)
  item['views'] = numberWithCommas(productMap['views'], '0')
  item['monthlySalesEstimate'] = getEstimatedSales(
    productMap['monthly_sales_estimate']
  )
  item['creationDate'] = formatCreationDate(productMap['original_creation_tsz'])
  item['creationTime'] = formatCreationTime(productMap['original_creation_tsz'])

  const daysSinceListed = formatDaysSinceCreated(
    productMap['original_creation_tsz']
  )
  item['daysSinceCreated'] = numberWithCommas(daysSinceListed)
  item['averageViewsPerDay'] = getViewsPerDay(
    productMap['views'],
    daysSinceListed
  )

  item.monthlyRevenueEstimate = currencyFormatter.format(
    productMap['monthly_revenue_estimate']
  )
  item.whenMade = productMap.when_made
  item.whoMade = productMap.who_made
  item.usedManufacturer = productMap.used_manufacturer

  keys.forEach((key) => {
    item[key] = productMap[key]
  })

  // DataParsingService
  item['listingID'] = productMap['dps_listingID']
  item['rowNumber'] = productMap['dps_rowNumber']
    ? productMap['dps_rowNumber']
    : ''
  item['link'] = getCleanedUrl(productMap['url'])

  // Main Page Data
  // item['title'] = productMap['mp_title'].replace('&amp;', '&')
  // item['shopName'] = productMap['mp_shopName']
  item['isAd'] = productMap['mp_isAd'] ? 'Yes' : 'No'
  item['image'] = productMap['mp_image'] ? productMap['mp_image'] : '' // could get from detail page, but not helpful for ml algo
  if (item['image'] === '') {
    item['image'] = productMap['dp_image'] ? productMap['dp_image'] : ''
  }

  // item['price'] = getPrice(productMap)
  // item['originalPrice'] = getOriginalPrice(productMap)
  // item['salePercentage'] = getSalePercentage(productMap)
  // item['bestseller'] = productMap['mp_bestseller'] ? 'Yes' : 'No'

  // Detail Page Data
  item['title'] = cleanTitle(productMap)
  item['shopName'] = productMap['dp_shopName']
  item['price'] = getPrice(productMap)
  item['originalPrice'] = getOriginalPrice(productMap)
  item['salePercentage'] = getSalePercentage(productMap)
  item['quickShipping'] = getQuickShipping(productMap)
  item['interestedCount'] = getInterestedCount(productMap)
  item['fewRemaining'] = getFewRemaining(productMap)
  item['shippingSpecial'] = getShippingSpecial(productMap)
  item['bestseller'] = productMap['dp_bestseller'] ? 'Yes' : 'No'
  item['listingRatingCount'] = getNumberOfRatings(productMap)
  item['storeRating'] = getRating(productMap)
  item['storeRatingCount'] = numberWithCommas(
    productMap['dp_storeRatingCount'],
    '0'
  )
  item['storeSales'] = productMap['dp_sales']
  item['listingDate'] = productMap['listingDate']

  // item['dp_favorers'] = numberWithCommas(productMap['dp_favorers'], '0')

  item['favorers'] = []
  item['onSale'] = productMap['dp_salePercentage'] ? 'Yes' : 'No'

  return item
}

function formatCreationDate(creationDateTime) {
  const d = new Date(creationDateTime * 1000)
  const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
  const mo = new Intl.DateTimeFormat('en', { month: 'long' }).format(d)
  const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
  const creationDate = `${mo} ${da}, ${ye}`
  return creationDate
}

function formatCreationTime(creationDateTime) {
  const d = new Date(creationDateTime * 1000)

  let hours = d.getHours()
  const amPm = hours > 12 ? 'pm' : 'am'
  if (hours > 12) hours = hours - 12
  if (hours === 0) hours = 12

  let minutes = `${d.getMinutes()}`
  if (minutes.length === 1) {
    minutes = `0${minutes}`
  }

  const creationTime = `${hours}:${minutes} ${amPm}`
  return creationTime
}

function formatDaysSinceCreated(creationDateTime) {
  const today = new Date()
  const ONE_DAY = 1000 * 60 * 60 * 24
  const numberOfDaysString = Math.round(
    (today - creationDateTime * 1000) / ONE_DAY
  )
  return numberOfDaysString
}

function getViewsPerDay(views, daysSinceListed) {
  if (views === 0 || daysSinceListed === 0) return '0'

  let viewsPerDay = Math.round(views / daysSinceListed)
  const viewsPerDayString = numberWithCommas(viewsPerDay)
  return viewsPerDayString
}

function getCleanedUrl(urlString) {
  let endPosition = urlString.indexOf('?')
  urlString = urlString.substr(0, endPosition)
  return urlString
}

function getEstimatedSales(numberString) {
  numberString = (Math.round(numberString * 100) / 100).toFixed(2)
  numberString = numberWithCommas(numberString, '0')
  return numberString
}

function getNumberOfRatings(productMap) {
  if (productMap['dp_listingRatingCount']) {
    return numberWithCommas(productMap['dp_listingRatingCount'])
  } else {
    return '0'
  }
}

function getRating(productMap) {
  const rating = productMap['dp_storeRating']
  if (rating) {
    return rating
  } else {
    return '-'
  }
}

function getPrice(productMap) {
  const price = currencyFormatter.format(productMap['dp_price'])

  if (price && price !== '$NaN') {
    return price
  } else {
    return '-'
  }
}

function getOriginalPrice(productMap) {
  const originalPrice = productMap['dp_originalPrice']
  if (originalPrice) {
    return currencyFormatter.format(originalPrice)
  } else {
    return '-'
  }
}

function getSalePercentage(productMap) {
  const salePercentage = productMap['dp_salePercentage']
  if (salePercentage) {
    return salePercentage + '%'
  } else {
    return '-'
  }
}

function getInterestedCount(productMap) {
  let interestedCount = '0'
  if (productMap['over_twenty']) {
    interestedCount = '20+'
  } else if (productMap['few_left_but_20_plus_have_it']) {
    interestedCount = '20+'
  } else if (productMap['interested_count']) {
    interestedCount = productMap['interested_count']
  } else if (productMap['dont_miss_out-number_in_cart']) {
    interestedCount = productMap['dont_miss_out-number_in_cart']
  }

  return interestedCount
}

function getShippingSpecial(productMap) {
  let shippingSpecial = '-'
  if (productMap['free_shipping']) {
    shippingSpecial = 'Free Shipping'
  } else if (productMap['free_us_shipping']) {
    shippingSpecial = 'Free US Shipping'
  } else if (productMap['free_us_shipping_35']) {
    shippingSpecial = 'Free US Shipping over $35'
  }

  return shippingSpecial
}

function getFewRemaining(productMap) {
  let fewRemaining = '-'
  if (productMap['few_left_but_20_plus_have_it']) {
    fewRemaining = productMap['few_left_but_20_plus_have_it']
  } else if (productMap['dont_miss_out-number_left']) {
    fewRemaining = productMap['dont_miss_out-number_left']
  } else if (productMap['few_left']) {
    fewRemaining = productMap['few_left']
  } else if (productMap['rare_remaining']) {
    fewRemaining = productMap['rare_remaining']
  }

  return fewRemaining
}

function getQuickShipping(productMap) {
  if (productMap['quickShipping']) {
    return 'Yes'
  } else {
    return 'No'
  }
}

function makeTagListString(productMap) {
  if ('tags' in productMap === false || productMap['tags'] == null) {
    return ''
  }

  // If was an error retrieving:
  if (productMap['tags'] === 'Unavailable') {
    return productMap['tags']
  }

  return makeTagString(productMap['tags'])
}

function makeTagString(tags) {
  if (tags.length === 0) {
    return ''
  }

  let string = '"'

  string += tags.join('", "')
  string += '"'
  return string
}

let currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

function numberWithCommas(numberString, defaultValue) {
  if (numberString == null) {
    return defaultValue
  }
  const value = numberString.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  if (value === '') {
    return defaultValue
  }
  return value
}

function cleanTitle(productMap) {
  const title = productMap['dp_title']
  if (title) {
    return title.replace('&amp;', '&')
  } else {
    return ''
  }
}

// let parseText = function (text, limit) {
//   if (text.length > limit) {
//     for (let i = limit; i > 0; i--) {
//       if (
//         text.charAt(i) === ' ' &&
//         (text.charAt(i - 1) !== ',' ||
//           text.charAt(i - 1) !== '.' ||
//           text.charAt(i - 1) !== ';')
//       ) {
//         return text.substring(0, i) + '...'
//       }
//     }
//     return text.substring(0, limit) + '...'
//   } else return text
// }

export { makeDisplayObject, makeTagString, currencyFormatter, numberWithCommas }
