import React, { useContext, useEffect, useState, useCallback } from 'react'
import { auth } from '../Authentication/firebase'
import * as firebase from '../Authentication/firebase'

import * as tracker from '../Duplicated/Tracker'
import * as config from '../../config.json'

import { getSubscriptionForUser } from './GetSubscription'

import {
  getInfoForUser,
  getUserListener,
  saveFirstSignOn,
} from '../DatabaseService'

import * as logger from '../Logger'
import Loading from '../../pages/Loading'

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export const AuthProvider = ({ children }) => {
  const FREE_SEARCH_COUNT = 7

  const [currentUser, setCurrentUser] = useState()
  const [userName, setUserName] = useState()
  const [userEmail, setUserEmail] = useState()
  const [subscriptionId, setSubscriptionId] = useState()
  const [hasPremiumAccess, setHasPremiumAccess] = useState(false)
  const [isLoading, setLoading] = useState(true)

  const [subscriptionIsCancelled, setSubscriptionIsCancelled] = useState()
  const [subscriptionStatus, setSubscriptionStatus] = useState()
  const [trialEnd, setTrialEnd] = useState()

  const [etsyToken, setEtsyToken] = useState()
  const [etsySecret, setEtsySecret] = useState()
  const [
    unsubscribeFromListeningToUserChanges,
    setUnsubscribeFromListeningToUserChanges,
  ] = useState()

  const [needToTrackLogin, setNeedToTrackLogin] = useState()

  const setUserInfo = useCallback(
    (currentUser) => {
      setCurrentUser(currentUser)

      logger.log('get user info')
      logger.log(currentUser)

      if (currentUser) {
        getSubscriptionInfo(currentUser.email)
        setUserEmail(currentUser.email)
        setUserName(currentUser.name)
        setEtsyToken(currentUser.etsyToken)
        setEtsySecret(currentUser.etsyTokenSecret)
        const hasPremiumAccess = subscriptionIsActive({
          subscriptionCancelAt: currentUser.subscriptionCancelAt,
          subscriptionId: currentUser.subscriptionId,
          searchCount: currentUser.searchCount,
        })
        setHasPremiumAccess(hasPremiumAccess)
        tracker.set({
          userName: currentUser.name,
          userEmail: currentUser.email,
        })
        if (needToTrackLogin) {
          tracker.trackEvent({
            category: 'Event',
            actionName: needToTrackLogin,
            sendTelegram: true,
          })
          setNeedToTrackLogin()
        }
      } else {
        setUserEmail()
        setUserName()
        setEtsyToken()
        setEtsySecret()
        setHasPremiumAccess(false)
        localStorage.removeItem('user')
        tracker.set({
          userName: null,
          userEmail: null,
        })
      }
    },
    [needToTrackLogin]
  )

  async function signup(email, password) {
    logger.log('signup')
    const value = await auth.createUserWithEmailAndPassword(email, password)
    setNeedToTrackLogin('Login - Signup Flow')
    logger.log('signup done')
    // logger.log(value)
    return value
  }

  async function login(email, password) {
    logger.log('login')
    const value = await auth.signInWithEmailAndPassword(email, password)
    logger.log('login done')
    setNeedToTrackLogin('Login - Login Flow')
    // logger.log(value)
    return value
  }

  async function popup() {
    var provider = new firebase.auth.GoogleAuthProvider()
    const user = await auth.signInWithPopup(provider)
    logger.log('login done')
    setNeedToTrackLogin('Login - Popup Flow')

    return user
  }
  function logout() {
    if (unsubscribeFromListeningToUserChanges) {
      unsubscribeFromListeningToUserChanges.then((unsubscribe) => {
        unsubscribe()
      })
    }
    auth.signOut()
    setUserInfo()

    tracker.trackEvent({
      category: 'Event',
      actionName: 'Logout Successful',
      sendTelegram: true,
    })
    tracker.set({
      userName: null,
      userEmail: null,
    })
  }

  const saveRegistrationToDatabase = (email, name) => {
    const searchCount = 1

    const firstSignOnTime = Date.now()
    const trialDurationSeconds = 1000 * 60 * 60 * 24 * 7 // one week
    saveFirstSignOn({
      email: email,
      name: name,
      firstSignOnTime: firstSignOnTime,
      trialDurationSeconds: trialDurationSeconds,
      searchCount: searchCount, // save how many searches user has done. If this is first sign on, then the number is 1.
    })
  }

  const handleSuccessfulRegistration = useCallback(({ email, name }) => {
    logger.log('handle successful registration')
    logger.log(email)
    logger.log(`name: ${name}`)

    if (email === undefined) {
      logger.error('undefined email at registration shouldnt be possible')
      return
    }

    saveRegistrationToDatabase(email, name)

    //   // backend.sendRegistrationEmailEvent({ email: user.email })

    //   // this.setState({
    //   //   viewState: viewStateEnum.SEARCH,
    //   //   userEmail: user.email,
    //   //   userName: name,
    //   //   searchCount: searchCount,
    //   //   hasPremiumAccess: this.determingWhetherSubscriptionIsActive({
    //   //     subscriptionCancelAt: null,
    //   //     subscriptionId: null,
    //   //     searchCount: searchCount,
    //   //   }),
    //   // })
    //   // getSubscriptionInfo(user.email)
  }, [])

  const getSubscriptionInfo = async (email) => {
    const subscription = await getSubscriptionForUser(email)
    logger.log('subscription info retrieved:')
    logger.log(subscription)
    if (subscription) {
      setSubscriptionId(subscription.id)
      setSubscriptionIsCancelled(subscription.cancel_at != null)
      setSubscriptionStatus(subscription.status)
      setTrialEnd(subscription.trial_end)
    }

    // logger.log(isSubscribed)
  }

  useEffect(() => {
    // const handleSuccessfulSignIn = async (userInfo) => {
    //   logger.log('handle successful login')
    //   logger.log(userInfo)

    //   // const email = userInfo.email

    //   // setEtsyInfo(email)

    //   //   tracker.set({
    //   //     user: userInfo,
    //   //     name: userRecords.name,
    //   //   })
    //   //   tracker.trackEvent({
    //   //     category: 'Event',
    //   //     actionName: 'Login Successful',
    //   //     sendTelegram: true,
    //   //   })
    //   // }

    //   // this.setState({
    //   //   viewState: viewStateEnum.SEARCH,
    //   //   currentUser: email,
    //   //   userName: userRecords.name
    //   // })
    // }

    const processUser = async (user) => {
      if (user) {
        localStorage.setItem('user', user.email)
        const userInfo = await getUserInfo(user.email)
        logger.log('userinfo')
        logger.log(userInfo)
        // localStorage.setItem('user', user.email)
        // const userInfo = await getUserInfo(user.email)

        if (userInfo) {
          logger.log('found user')
          logger.log('user name at login')
          logger.log(userInfo)
          logger.log(userInfo.name)
          await getSubscriptionInfo(user.email)
          // await handleSuccessfulSignIn(user)
          setUserInfo(userInfo)
        } else if (user.emailVerified) {
          logger.log('write user')
          logger.log(user)
          handleSuccessfulRegistration({
            email: user.email,
            name: user.displayName,
          })
          setUserInfo(user)
        }
        const handleUpdate = (userRecord) => {
          logger.log('got changes 2')
          logger.log(userRecord)
          setUserInfo(userRecord)
        }
        const listenerUnsubscribe = getUserListener({
          email: user.email,
          handleUpdate: handleUpdate,
        })
        setUnsubscribeFromListeningToUserChanges(listenerUnsubscribe)
      }
    }

    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      // const testUser = {
      //   email: 'user@email.com',
      //   displayName: 'testing',
      // }

      logger.log(`on auth state changed. user: ${user}`)
      logger.log(user)

      // if (user === currentUser) {
      //   logger.log('already set. return')
      //   return
      // }

      // For Live, this should await the result, to make for smooth loading
      await processUser(user)

      // For Testing this should not wait in order for firebase access to be set properly
      // processUser(testUser)

      setLoading(false)
    })

    return unsubscribe
  }, [setUserInfo, handleSuccessfulRegistration])

  const subscriptionIsActive = ({
    subscriptionCancelAt,
    subscriptionId,
    searchCount,
  }) => {
    logger.log('subscription status:')
    logger.log(subscriptionCancelAt)
    logger.log(subscriptionId)
    logger.log(`search count: ${searchCount}`)

    if (
      (searchCount < FREE_SEARCH_COUNT ||
        config.USE_FREE_SEARCH_LIMIT[config.VALUE_KEY] === false) &&
      config.SUBSCRIPTION_FEATURE_TOGGLE_TESTING[config.VALUE_KEY] === false
    ) {
      return true
    }

    if (config.SUBSCRIPTION_FEATURE_TOGGLE_TESTING[config.VALUE_KEY] === true) {
      if (subscriptionId != null) {
        if (subscriptionCancelAt) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    }

    if (subscriptionId != null) {
      // If there is a subscription, then they may want to unsubscribe or resubscribe.
      // TODO: make this a call at the beginning of running to keep things updated better.
      // If there is a cancelAt time, and the current time is beyond the cancel-time, then it is no longer active
      const nowInSeconds = new Date().getTime() / 1000
      if (subscriptionCancelAt != null && nowInSeconds > subscriptionCancelAt) {
        return false
      }
      // If there is not a cancelAt time or it is in the future, then they can have full access.
      else {
        return true
      }
    }
    // if there is no subscription, then should have limited access
    else {
      return false
    }
  }

  const getUserInfo = async (email) => {
    logger.log('get customer at login')
    logger.log(email)

    const userRecords = await getInfoForUser({
      email: email,
    })
    return userRecords
  }

  const value = {
    currentUser,
    userEmail,
    userName,
    subscriptionId,
    hasPremiumAccess,
    isLoading,
    tracker,
    signup,
    login,
    popup,
    logout,
    subscriptionIsCancelled,
    subscriptionStatus,
    trialEnd,

    etsyToken,
    etsySecret,
    handleSuccessfulRegistration,
  }

  const viewBasedOnLoading = () => {
    if (isLoading) {
      return <Loading></Loading>
    } else {
      return children
    }
  }

  return (
    <AuthContext.Provider value={value}>
      {viewBasedOnLoading()}
    </AuthContext.Provider>
  )
}
