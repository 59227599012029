import React, { PureComponent } from 'react'

import { FeedbackModalInteractor } from './FeedbackModalInteractor'
import { FeedbackModalPresenter } from './FeedbackModalPresenter'
import { FeedbackModalView } from './FeedbackModalView'

export class FeedbackModalComponent extends PureComponent {
  interactor
  presenter

  loadingState = {
    retrievalComplete: false,
    messages: [],
  }

  setupVIP = () => {
    this.presenter = new FeedbackModalPresenter()

    this.interactor = new FeedbackModalInteractor({
      userName: this.props.userName,
      email: this.props.email,
    })

    this.interactor.presenter = this.presenter
    this.presenter.view = this
  }

  constructor(props) {
    super(props)
    this.setupVIP()

    this.state = this.loadingState
  }

  async componentDidMount() {
    // const propUrl = 'example.com' //this.props.url
    const interactorData = {}
    await this.interactor.getData(interactorData)
  }

  //   componentDidUpdate(previousProps) {
  //     if (previousProps.url !== this.props.url) {
  //       this.setState(this.initialState)
  //       this.interactor.getData(this.props.url)
  //     }
  //   }

  setViewState(viewProps) {
    this.setState({
      retrievalComplete: true,
      messages: viewProps.messages,
    })
  }

  setText(text) {
    this.setState({
      text: text,
    })
  }

  render() {
    return (
      <FeedbackModalView
        onClose={this.props.onClose}
        onSubmit={this.interactor.handleSubmit}
        onInputChange={this.interactor.handleInputChange}
        retrievalComplete={this.state.retrievalComplete}
        messages={this.state.messages}
        text={this.state.text}
      ></FeedbackModalView>
    )
  }
}
