import React, { useState, Component } from 'react'

// import * as tracker from '../../../services/Tracker'

import { loadStripe } from '@stripe/stripe-js'
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'

import * as subscriptionService from '../../../services/SubscriptionService'
import * as logger from '../../../services/Logger'

import './Payment.css'

const config = require('../../../config.json')

const subscriptionTypeEnum = Object.freeze({
  ANNUAL: 1,
  MONTHLY: 2,
})

let stripePromise
let ANNUAL_PLAN
let MONTHLY_PLAN

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
if (config.USE_TEST_STRIPE_KEYS[config.VALUE_KEY] === true) {
  logger.log('Stripe: Using test keys')
  stripePromise = loadStripe('pk_test_SUUwecjZaR1d96IJWee3mJuD00x1jWtIP5')
  ANNUAL_PLAN = 'price_HO8G20J52iST37'
  MONTHLY_PLAN = 'plan_HGbpEtIaZXXVPQ'
} else {
  logger.log('Stripe: Using live keys')
  stripePromise = loadStripe('pk_live_ns8frzl6g1qLmZc26jgPmwbK00nmKfptc9')
  ANNUAL_PLAN = 'price_1GsZg0ES0jeFpNKzCu0rree6' //59.99
  MONTHLY_PLAN = 'price_1GsZgWES0jeFpNKzMggvIVoJ' // 7.99
}

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: 'gray',
      color: 'black',
      backgroundColor: '#c1cff2',
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: 'gray',
        backgroundColor: '#c1cff2',
      },
      '::placeholder': {
        color: 'gray',
        backgroundColor: '#c1cff2',
      },
      // '::selection': {
      //   color: 'red',
      //   backgroundColor: 'blue',
      // },
      // ':focus': {
      //   color: 'green',
      //   backgroundColor: 'orange',
      // },
      // ':complete': {
      //   color: 'pink',
      //   backgroundColor: 'brown',
      // },
    },
    invalid: {
      iconColor: '#ce4b5c',
      color: '#ce4b5c',
    },
  },
}

const CardField = ({ onChange }) => (
  <div>
    <CardElement
      className="form-control"
      options={CARD_OPTIONS}
      onChange={onChange}
    />
  </div>
)

const SubmitButton = ({ processing, error, children, disabled, onSubmit }) => (
  <div
    className={`${error ? 'SubmitButton--error' : 'SubmitButton'}`}
    type="submit"
    disabled={processing || disabled || error}
    onClick={onSubmit}
  >
    {processing ? (
      <div>
        <div
          className="spinner-border spinner-border-sm loader-positioning"
          role="status"
          aria-hidden="true"
        ></div>
        Processing...
      </div>
    ) : (
      children
    )}
  </div>
)

const ErrorMessage = ({ children }) => (
  <div className="ErrorMessage" role="alert">
    {/* <svg width='16' height='16' viewBox='0 0 17 17'>
      <path
        fill='#ffe5f7'
        d='M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z'
      />
      <path
        fill='#6772e5'
        d='M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z'
      />
    </svg> */}
    {children}
  </div>
)

const CheckoutForm = (props) => {
  const stripe = useStripe()
  const elements = useElements()

  const [customerError, setCustomerError] = useState(null)
  const [cardError, setCardError] = useState(null)
  const [subscriptionError, setSubscriptionError] = useState(null)
  const [paymentError, setPaymentError] = useState(null)

  const [processing, setProcessing] = useState(false)
  const [subscriptionComplete, setSubscriptionComplete] = useState(null)

  async function retrieveCustomerInformation() {
    logger.log('Fetch customer info from server:')
    const results = await subscriptionService
      .getSubscriptions({
        email: props.email,
      })
      .catch((error) => {
        if (error !== undefined) {
          setCustomerError(error)
        }
        return
      })

    if (results == null) return
    const { customer, subscriptions } = results

    logger.log('customer:')
    logger.log(customer)
    logger.log('subscription:')
    logger.log(subscriptions)

    // TODO: needs to be robust. abstract this for reuse here and at initialization
    if (
      subscriptions &&
      subscriptions.size > 0 &&
      (subscriptions[0].status === 'active' ||
        subscriptions[0].status === 'trialing')
    ) {
      // displayError('show error about customer existing already')
      let e = Error('Subscription already exists for email account.')
      setCustomerError(e)
    } else {
      setCustomerError(null)
    }
    return customer
  }

  async function createCustomer() {
    logger.log('request server to create customer:')
    const customer = await retrieveCustomerInformation()
    if (customer) {
      return customer
    }

    return await subscriptionService
      .createCustomer({ email: props.email })
      .catch((error) => {
        setCustomerError(error)
        return
      })
  }

  async function createPaymentMethod(cardElement, customerId, priceId) {
    logger.log('create payment via stripe, not backend:')
    stripe
      .createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          email: props.email,
        },
      })
      .then((result) => {
        logger.log('stripe create result:')
        logger.log(result)
        setPaymentError(result.error)
        if (!result.error) {
          // if (isPaymentRetry) {
          //   // Update the payment method and retry invoice payment
          //   retryInvoiceWithNewPaymentMethod(
          //     customerId,
          //     result.paymentMethod.id,
          //     invoiceId,
          //     priceId
          //   );
          // } else {
          // Create the subscription
          createSubscription(customerId, result.paymentMethod.id, priceId)
          // }
        } else {
          setProcessing(false)
        }
      })
      .catch((error) => {
        throw error
      })
  }

  function createSubscription(customerId, paymentMethodId, priceId) {
    logger.log('create subscription via server')
    // If the card is declined, display an error to the user.
    subscriptionService
      .createSubscription({
        customerId: customerId,
        paymentMethodId: paymentMethodId,
        priceId: priceId,
      })
      .then((result) => {
        logger.log('first backend result then-statement')
        logger.log(result)
        if (result.error) {
          logger.error('card is declined')
          logger.error(result)
          setSubscriptionError(result.error)
          // The card had an error when trying to attach it to a customer
          throw result
        }
        setSubscriptionError(null)
        setProcessing(false)
        return result
      })
      // Normalize the result to contain the object returned
      // by Stripe. Add the addional details we need.
      .then((result) => {
        logger.log('creating subscription result:')
        logger.log(result)
        return {
          // Use the Stripe 'object' property on the
          // returned result to understand what object is returned.
          subscription: result,
          paymentMethodId: paymentMethodId,
          priceId: priceId,
        }
      })
      // Some payment methods require a customer to do additional
      // authentication with their financial institution.
      // Eg: 2FA for cards.
      .then(handleCustomerActionRequired)
      // If attaching this card to a Customer object succeeds,
      // but attempts to charge the customer fail. You will
      // get a requires_payment_method error.
      .then(handlePaymentMethodRequired)
      // No more actions required. Provision your service for the user.
      .then(onSubscriptionComplete)
      .catch((error) => {
        // An error has happened. Display the failure to the user here.
        // We utilize the HTML element we created.

        logger.log('issue creating subscription')
        logger.log(error)
        logger.log(error.message)

        setProcessing(false)
        if (error.error !== undefined) {
          setSubscriptionError({ message: error.error.message })
        } else if (error !== undefined) {
          let errorMessage = error.message
          if (errorMessage === '402') {
            errorMessage =
              'There was an issue creating your Subscription. Please contact us or try again later.'
          }
          setSubscriptionError({ message: errorMessage })
        }
      })
  }

  function handleCustomerActionRequired({
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    isRetry,
  }) {
    logger.log('handle customer action required.')
    logger.log(subscription)

    // Store info about subscription
    // TODO: Am I handling unfulfilled payment well?

    if (
      subscription &&
      (subscription.status === 'active' || subscription.status === 'trialing')
    ) {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId }
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    let paymentIntent = invoice
      ? invoice.payment_intent
      : subscription.latest_invoice.payment_intent

    if (
      paymentIntent.status === 'requires_action' ||
      (isRetry === true && paymentIntent.status === 'requires_payment_method')
    ) {
      return stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          if (result.error) {
            // start code flow to handle updating the payment details
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)
            throw result
          } else {
            if (result.paymentIntent.status === 'succeeded') {
              // There's a risk of the customer closing the window before callback
              // execution. To handle this case, set up a webhook endpoint and
              // listen to invoice.payment_succeeded. This webhook endpoint
              // returns an Invoice.
              setProcessing(false)
              return {
                priceId: priceId,
                subscription: subscription,
                invoice: invoice,
                paymentMethodId: paymentMethodId,
              }
            }
          }
        })
        .catch((error) => {
          logger.error(
            'There was an error processing payment. might need to store subscription still here.'
          )
          setPaymentError({ message: error.error.message })

          // There technically is a subscription at this point for the customer. So act as
          // if the user has no subscription, but store that customerId.
          const data = {
            subscription: subscription,
          }
          props.onCustomerCreatedSubscriptionFailed(data)
          setProcessing(false)
        })
    } else {
      // No customer action needed
      return { subscription, priceId, paymentMethodId }
    }
  }

  function handlePaymentMethodRequired({
    subscription,
    paymentMethodId,
    priceId,
  }) {
    logger.log('handle payment method required. No logging here yet.')
    if (
      subscription.status === 'active' ||
      subscription.status === 'trialing'
    ) {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId }
    } else if (
      subscription.latest_invoice.payment_intent.status ===
      'requires_payment_method'
    ) {
      // Using localStorage to store the state of the retry here
      // (feel free to replace with what you prefer)
      // Store the latest invoice ID and status
      localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id)
      localStorage.setItem(
        'latestInvoicePaymentIntentStatus',
        subscription.latest_invoice.payment_intent.status
      )
      throw Error('Your card was declined.')
    } else {
      return { subscription, priceId, paymentMethodId }
    }
  }

  function onSubscriptionComplete(result) {
    logger.log('on subscription complete')
    const data = {
      subscription: result.subscription,
    }
    setSubscriptionComplete(result.subscription) // TODO: may not be needed now that removing from view.
    props.onSubscriptionAdded(data)
    // Payment was successful. Provision access to your service.
    // Remove invoice from localstorage because payment is now complete.
    // clearCache()

    // Change your UI to show a success message to your customer.
    // onSubscriptionSampleDemoComplete(result)

    // Call your backend to grant access to your service based on
    // the product your customer subscribed to.
    // Get the product by using result.subscription.price.product
  }

  const handleSubmit = async (event) => {
    // tracker.trackEvent({
    //   category: 'Button',
    //   actionName: 'Submit Pressed',
    //   sendTelegram: true,
    // })
    setProcessing(true)
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      setProcessing(false)
      return
    }

    // get customer
    let customer = await createCustomer()
    if (!customer || customer.error) {
      logger.error('no customer when there should be by now.')
      setProcessing(false)
      return
    }

    const planId =
      props.subscriptionType === subscriptionTypeEnum.ANNUAL
        ? ANNUAL_PLAN
        : MONTHLY_PLAN

    createPaymentMethod(elements.getElement(CardElement), customer.id, planId)
  }
  // const getCardError = () => {
  //   if (cardError) {
  //     return <ErrorMessage>{`card: ${cardError.message}`}</ErrorMessage>
  //   }
  //   // else {
  //   //   return <div className='payment-small-spacing'></div>
  //   // }
  // }
  // const getPaymentError = () => {
  //   if (paymentError) {
  //     return <ErrorMessage>{`payment: ${paymentError.message}`}</ErrorMessage>
  //   }
  //   // else {
  //   //   return <div className='payment-small-spacing'></div>
  //   // }
  // }
  // const getCustomerError = () => {
  //   if (customerError) {
  //     return <ErrorMessage>{`customer: ${customerError.message}`}</ErrorMessage>
  //   }
  //   // else {
  //   //   return <div className='payment-small-spacing'></div>
  //   // }
  // }
  // const getSubscriptionError = () => {
  //   if (subscriptionError) {
  //     return (
  //       <ErrorMessage>{`subscription: ${subscriptionError.message}`}</ErrorMessage>
  //     )
  //   }
  //   // else {
  //   //   return <div className='payment-small-spacing'></div>
  //   // }
  // }

  const showError = () => {
    if (cardError) {
      logger.log(`cardError`)
      logger.log(cardError)
      return <ErrorMessage>{`${cardError.message}`}</ErrorMessage>
    } else if (paymentError) {
      logger.log(`paymentError`)
      logger.log(paymentError)
      return <ErrorMessage>{`${paymentError.message}`}</ErrorMessage>
    } else if (customerError) {
      logger.log(`customerError`)
      logger.log(customerError)
      return <ErrorMessage>{`${customerError.message}`}</ErrorMessage>
    } else if (subscriptionError) {
      logger.log(`subscriptionError`)
      logger.log(subscriptionError)
      return <ErrorMessage>{`${subscriptionError.message}`}</ErrorMessage>
    } else {
      return <div className="payment-small-spacing"></div>
    }
  }

  return subscriptionComplete ? (
    <div className="Result">
      <div className="ResultTitle" role="alert">
        Payment successful
      </div>
      <div className="ResultMessage">
        Thanks for trying Stripe Elements. No money was charged, but we
        generated a PaymentMethod: {subscriptionComplete.id}
      </div>
    </div>
  ) : (
    <form className="Form" onSubmit={handleSubmit}>
      {/* {getCardError()}
      {getPaymentError()}
      {getCustomerError()}
      {getSubscriptionError()} */}
      {showError()}
      <fieldset>
        <CardField
          className="form-control"
          onChange={(e) => {
            logger.log('on change')
            logger.log(e)
            setCardError(e.error)
            // setCardComplete(e.complete)
            setPaymentError(null)
            setSubscriptionError(null)
            setCustomerError(null)
          }}
        />
      </fieldset>
      <SubmitButton
        processing={processing}
        error={cardError}
        disabled={processing}
        onSubmit={handleSubmit}
      >
        Upgrade
      </SubmitButton>
    </form>
  )
}

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
    },
  ],
}

const Payment = (props) => {
  return (
    <div>
      <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
        <CheckoutForm
          onSubscriptionAdded={props.onSubscriptionAdded}
          onCustomerCreatedSubscriptionFailed={
            props.onCustomerCreatedSubscriptionFailed
          }
          email={props.email}
          subscriptionType={props.subscriptionType}
        />
      </Elements>
    </div>
  )
}

export default Payment
