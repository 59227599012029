import { updateUserName } from '../../services/DatabaseService'
import * as subscriptionService from '../../services/SubscriptionService'
import * as logger from '../../services/Logger'

import { handleConnectToEtsy } from '../../services/EtsyOAuthService'

import history from '../../history'

export class AccountPageInteractor {
  presenter
  tracker

  constructor({ tracker }) {
    this.tracker = tracker
  }

  setTrialDate(interactorData) {
    const date = new Date(interactorData.trialEnd * 1000)
    const presenterData = {
      date: date,
    }
    this.presenter.setTrialDate(presenterData)
  }

  setSubscriptionStatus = ({
    subscriptionId,
    subscriptionIsCancelled,
    subscriptionStatus,
    trialEnd,
  }) => {
    this.presenter.setSubscriptionStatus({
      subscriptionId: subscriptionId,
      subscriptionIsCancelled: subscriptionIsCancelled,
      subscriptionStatus: subscriptionStatus,
      trialEnd: trialEnd,
    })
  }

  updatedNameIsValid = ({ initialName, editableName }) => {
    return initialName !== editableName && editableName.length > 2
  }
  setUpdateButtonEnabledFlag = ({ initialName, editableName }) => {
    const isEnabled = this.updatedNameIsValid({
      initialName: initialName,
      editableName: editableName,
    })

    this.presenter.setUpdateButtonEnabledFlag(isEnabled)
  }

  handleUpdatePressed = async ({ email, initialName, editableName }) => {
    const isEnabled = this.updatedNameIsValid({
      initialName: initialName,
      editableName: editableName,
    })
    if (!isEnabled) return

    await updateUserName({
      email: email,
      newName: editableName,
    })
      .then((e) => {
        this.presenter.showSuccess('Successfully updated name')
      })
      .catch((e) => {
        this.presenter.showError(
          'Unable to update name at this time. Please try again later.'
        )
      })
  }

  handleCancelSubscription = async ({ email, subscriptionId }) => {
    // console.log('handleCancelSubscription')
    // console.log(email)

    if (subscriptionId == null) return

    const updatedSubscription = await subscriptionService
      .cancelSubscription({
        email: email,
        subscriptionId: subscriptionId,
      })
      .catch((error) => {
        logger.log('error message:')
        logger.log(error)
        this.presenter.showError(
          'Unable to update subscription at this time. Please try again later.'
        )
        return
      })

    if (updatedSubscription == null) return

    // 3. then update view state
    // this.setState({
    //   viewState: viewStateEnum.SEARCH,
    //   subscriptionCancelAt: updatedSubscription.cancel_at,
    //   hasPremiumAccess: this.determingWhetherSubscriptionIsActive({
    //     subscriptionCancelAt: updatedSubscription.cancel_at,
    //     subscriptionId: this.state.subscriptionId,
    //     searchCount: this.state.searchCount,
    //   }),
    // })
    this.tracker.trackEvent({
      category: 'Event',
      actionName: 'Account - Cancel Subscription Successful',
      sendTelegram: true,
    })
    this.presenter.removeModal()
    this.presenter.showSuccess('Successfully cancelled subscription.')
  }

  handleRenewSubscription = ({
    email,
    subscriptionId,
    subscriptionCancelAt,
  }) => {
    // console.log('handleRenewSubscription')
    if (subscriptionId == null && subscriptionCancelAt == null) return

    // 1. call backend to cancel subscription
    const updatedSubscription = subscriptionService
      .renewSubscription({ email: email, subscriptionId: subscriptionId })
      .catch((error) => {
        logger.error('renew subscription hit error.')
        logger.error(error)
        // this.setState({
        //   showError: error.message,
        //   viewState: viewStateEnum.SEARCH,
        //   needToRecheckConnectionToStripe: true,
        // })
        this.presenter.showError(
          'Unable to update subscription at this time. Please try again later.'
        )
        return
      })

    if (updatedSubscription == null) return

    // 3. then update view state
    // this.setState({
    //   viewState: viewStateEnum.SEARCH,
    //   subscriptionCancelAt: null,
    //   hasPremiumAccess: this.determingWhetherSubscriptionIsActive({
    //     subscriptionCancelAt: null,
    //     subscriptionId: this.state.subscriptionId,
    //     searchCount: this.state.searchCount,
    //   }),
    // })
    this.tracker.trackEvent({
      category: 'Event',
      actionName: 'Account - Re-Subscribe Successful',
      sendTelegram: true,
    })
    this.presenter.removeModal()
    this.presenter.showSuccess('Successfully renewed subscription.')
  }

  handleSubscribePressed = () => {
    history.push('/pricing')
  }

  handleConnectToEtsyPressed = (email) => {
    handleConnectToEtsy({
      tracker: this.tracker,
      email: email,
    })
  }
}
