import React, { PureComponent, useEffect } from 'react'

import { PricingPageInteractor } from './PricingPageInteractor'
import { PricingPagePresenter } from './PricingPagePresenter'
import { PricingPageView } from './PricingPageView'

import { useAuth } from '../../services/contexts/AuthContext'

export const Pricing = (props) => {
  const {
    userEmail,
    isLoading,
    subscriptionId,
    subscriptionIsCancelled,
    tracker,
  } = useAuth()

  useEffect(() => {
    tracker.trackEvent({
      category: 'View',
      actionName: 'Pricing',
      sendTelegram: true,
    })
  }, [tracker])

  if (isLoading) {
    return <div></div>
  }

  return (
    <PricingPageComponent
      email={userEmail}
      subscriptionId={subscriptionId}
      subscriptionIsCancelled={subscriptionIsCancelled}
    ></PricingPageComponent>
  )
}

const viewStateEnum = Object.freeze({
  NONE: 0,
  SUBSCRIBE: 1,
  RESUBSCRIBE: 2,
  // FEEDBACK: 3,
})

class PricingPageComponent extends PureComponent {
  interactor
  presenter

  loadingState = {
    showModal: viewStateEnum.NONE,
  }

  setupVIP = () => {
    this.presenter = new PricingPagePresenter()
    this.interactor = new PricingPageInteractor()

    this.interactor.presenter = this.presenter
    this.interactor.email = this.props.email

    this.presenter.view = this
  }

  constructor(props) {
    super(props)
    this.setupVIP()

    this.state = this.loadingState
  }

  // componentDidMount() {
  //   const propUrl = 'example.com' //this.props.url
  //   const interactorData = {
  //     url: propUrl,
  //   }
  //   this.interactor.getData(interactorData)
  // }

  showSubscribeModal = () => {
    this.setState({
      showModal: viewStateEnum.SUBSCRIBE,
    })
  }

  handleShowResubscribe = () => {
    this.setState({
      showModal: viewStateEnum.RESUBSCRIBE,
    })
  }

  removeModal = () => {
    this.setState({
      showModal: viewStateEnum.NONE,
    })
  }

  render() {
    return (
      <PricingPageView
        showSubscribe={this.state.showModal === viewStateEnum.SUBSCRIBE}
        showResubscribe={this.state.showModal === viewStateEnum.RESUBSCRIBE}
        onSubscriptionAdded={this.interactor.handleSubscriptionAdded}
        onCustomerCreatedSubscriptionFailed={
          this.interactor.handleCustomerCreatedSubscriptionFailed
        }
        onGetStartedPressed={this.interactor.handleGetStartedPressed}
        onRenewSubscription={() =>
          this.interactor.handleRenewSubscription({
            email: this.props.email,
            subscriptionId: this.props.subscriptionId,
            subscriptionCancelAt: this.props.subscriptionIsCancelled,
          })
        }
        onRenewPressed={this.handleShowResubscribe}
        email={this.props.email}
        onClose={this.removeModal}
      ></PricingPageView>
    )
  }
}
