import React, { Component } from 'react'
// const config = require('../config.json')

import './PremiumFeaturesComponent.css'

import checkmark from '../../../img/checkmark.png'

// const Payment = (props) => {
export class PremiumFeaturesComponent extends Component {
  render = () => {
    return (
      <div className="section">
        <div className="row">
          <img className="checkmark" src={checkmark} alt=""></img>
          <div className="line-item"> 12 Month Search-Term Forecast</div>
        </div>

        {/* <div className='row'>
          <img className='checkmark' src={checkmark} alt=''></img>
          <div className='line-item'>Search Term Competition Score</div>
        </div> */}

        <div className="row">
          <img className="checkmark" src={checkmark} alt=""></img>
          <div className="line-item">More Data!</div>
        </div>

        <div className="row">
          <img className="checkmark" src={checkmark} alt=""></img>
          <div className="line-item">Access to Tag Info!</div>
        </div>

        <div className="row">
          <img className="checkmark" src={checkmark} alt=""></img>
          <div className="line-item">Exporting to CSV</div>
        </div>
      </div>
    )
  }
}
export default PremiumFeaturesComponent
