import React, { PureComponent } from 'react'

import {
  AreaChart,
  // Line,
  XAxis,
  YAxis,
  // CartesianGrid,
  Tooltip,
  // Legend,
  Area,
} from 'recharts'

import warningIcon from './warningIcon.png'
import { Loader } from './Loader/Loader'

class CustomizedDot extends PureComponent {
  render() {
    return <div></div>
  }
}

const NoPastDataTable = () => {
  return (
    <div className="past-chart-insufficient-data">
      <img
        src={warningIcon}
        alt=""
        className="past-chart-insufficient-data-img"
      ></img>
      <div className="past-chart-insufficient-title">
        There is no Google Trend data for this search term. This is not
        necessarily a bad thing if the phrase is a more recent trend.
      </div>
    </div>
  )
}

const LoadingTable = () => {
  return (
    <div className="past-chart-placeholder ml-auto ml-lg-0 mr-auto">
      <div
        className="chart-title"
        // onClick={this.props.onGoogleChartPress}
      >
        The Past, from Google Trends
      </div>
      <div className="mt-5">
        <Loader></Loader>
      </div>
    </div>
  )
}

const PlaceholderTable = () => {
  return (
    <div className="past-chart-placeholder ml-auto ml-lg-0 mr-auto">
      <div
        className="chart-title-blurred"
        // onClick={this.props.onGoogleChartPress}
      >
        The Past, from Google Trends
      </div>
    </div>
  )
}

export const getGoogleChart = ({ searchTerm, chartsAreLoading, tableData }) => {
  if (chartsAreLoading && chartsAreLoading === true) {
    return <LoadingTable />
  } else if (searchTerm !== '' && chartsAreLoading === false) {
    if (tableData.length === 0) {
      return <NoPastDataTable />
    } else {
      return (
        <div className="past-chart ml-auto ml-lg-0 mr-auto">
          <div
            className="chart-title"
            // onClick={onGoogleChartPress}
          >
            The Past, from Google Trends
          </div>
          <AreaChart
            className="chart-body"
            width={350}
            height={100}
            data={tableData}
            margin={{
              top: 0,
              right: 60,
              left: -30,
              bottom: 0,
            }}
          >
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#889BCD" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#889BCD" stopOpacity={0} />
              </linearGradient>
            </defs>
            <Area
              dataKey={searchTerm}
              stroke="#889BCD"
              fill="url(#colorUv)"
              dot={<CustomizedDot />}
            ></Area>
          </AreaChart>
        </div>
      )
    }
  } else {
    return <PlaceholderTable />
  }
}
