import React, { PureComponent } from 'react'

import './TagSearchBar.css'
// import { SearchType } from '../SearchType'
import SearchTypeToggle from '../SeachTypeToggle/SearchTypeToggle'
import SearchCategoryPicker from '../SearchCategoryPicker/SearchCategoryPicker'
import { Container } from 'react-bootstrap'

class TagSearchBar extends PureComponent {
  constructor() {
    super()
    this.state = {
      input: '',
    }
    this.onKeyUp = this.onKeyUp.bind(this)
  }

  searchPressed = () => {
    this.props.onSearchPress(this.state.input)
  }

  onKeyUp(event) {
    if (event.key === 'Enter') {
      this.searchPressed()
    } else {
      this.setState({
        input: event.target.value,
      })
    }
  }

  getSearchButton = () => {}

  //style={{ backgroundColor: 'blue' }}
  render() {
    //w-75 mx-auto px-2 row
    return (
      <div className="search-bar-container w-100">
        <Container>
          <div className="w-75 mx-auto px-2 mt-4 row">
            <div className="col-12 col-md-8 order-1 my-auto">
              <input
                type="text"
                className="w-100 sheerba-input"
                id="inputTag"
                aria-describedby="tagInput"
                placeholder="Search Tag Here"
                onKeyUp={this.onKeyUp}
              ></input>
            </div>
            <div className="col-12 col-md-4 my-md-auto order-12 mt-3 order-md-2">
              <div
                className={`${
                  this.props.searchIsEnabled
                    ? 'btn-dark-blue'
                    : 'btn-dark-blue-disabled'
                } btn-large custom-submit-button`}
                onClick={
                  this.props.searchIsEnabled &&
                  this.props.showSearchIsLoading === false
                    ? this.searchPressed
                    : undefined
                }
              >
                {this.props.showSearchIsLoading &&
                this.props.showSearchIsLoading === true ? (
                  <div className="my-auto">
                    <div
                      className="mx-auto spinner-border spinner-border-sm text-white"
                      // style={{ width: '3rem', height: '3rem' }}
                      role="status"
                    ></div>
                  </div>
                ) : (
                  'Search'
                )}
              </div>
            </div>
            <div className="col-12 col-md-5 col-lg-3 mx-auto mt-3 text-left order-3">
              <SearchTypeToggle
                className=""
                searchType={this.props.searchType}
                onSearchTypeToggle={this.props.onSearchTypeToggle}
              ></SearchTypeToggle>
            </div>
            <div className="col-12 col-md-7 col-lg-5 col-xl-4 mt-3 text-left order-4">
              <SearchCategoryPicker
                onCategoryToggle={this.props.onCategoryToggle}
              ></SearchCategoryPicker>
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xl-2 mt-3 text-left order-5">
              <div className="txt-blue cp2"># of Results</div>
              <select
                className="selectpicker-primary txt-blue"
                onChange={(e) => this.props.onResultCountToggle(e.target.value)}
              >
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={75}>75</option>
                <option value={100}>100</option>
              </select>
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xl-3 mt-md-3 text-left order-6">
              <div className="mt-3 form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={this.props.clearOnSearchIsSelected}
                  onChange={this.props.onClearTogglePressed}
                ></input>
                <label
                  className="form-check-label txt-blue txt-clickable cp2"
                  onClick={this.props.onClearTogglePressed}
                >
                  Clear results on new search
                </label>
              </div>
            </div>
          </div>
        </Container>
      </div>
    )
  }
}
export default TagSearchBar
