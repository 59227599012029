import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.css'

import App from './App'

// setCookie('test', 'test', 1)

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
)

// function setCookie(cname, cvalue, exdays) {
//   var d = new Date()
//   d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
//   var expires = 'expires=' + d.toUTCString()
//   document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
// }
