import React, { Component, useEffect } from 'react'
import TextPage from '../components/TextPage'
import { useAuth } from '../services/contexts/AuthContext'

export const FAQ = (props) => {
  const { isLoading, tracker } = useAuth()

  useEffect(() => {
    tracker.trackEvent({
      category: 'View',
      actionName: 'FAQ',
      sendTelegram: true,
    })
  }, [tracker])

  if (isLoading) {
    return <div></div>
  }

  return <FAQComponent></FAQComponent>
}

class FAQComponent extends Component {
  render() {
    return (
      <TextPage
        title="Frequently Asked Questions"
        subtitle="Last Updated: June 24, 2020"
      >
        <h2>What is Sheerba?</h2>
        <p>
          Sheerba is a tool to help with Etsy research. If you are selling
          products on Etsy, then it is very important to keep your eye on the
          other products on Etsy -- either to see how your competition is trying
          to steal your market, or to see holes in the market that you can fill.{' '}
        </p>
        <p></p>
        <p>
          Sheerba is the best way to do this research. It collects useful
          information about products and compiles it into a nice sortable table,
          making it way easier to gain valuable insights that aren’t apparent
          when you’re just scrolling through Etsy.
        </p>
        <p></p>
        <h2>But what is the mascot, Sheerba?</h2>
        <p>
          Oh, if you were wondering about our mascot, Sheerba, he’s a Shiba dog
          dressed up like Sherlock Holmes. He’s dressed up like Sherlock because
          he gathers the information for you. He’s a Shiba because...well, no
          profound reason; I just think that they’re the most adorable breed of
          dogs!
        </p>
        <p></p>
        <h2>How do you pronounce Sheerba?</h2>
        <p>Pretty straight-forward actually: sheer - ba.</p>
        <p></p>
        <h2>Can I save the information from my searches?</h2>
        <p>
          You can! After you perform a search, just press the “Export” button at
          the top and it will export the information into a csv file which you
          can then put into a spreadsheet and even combine with other searches
          you’ve done.
        </p>
        <p></p>
        <h2>How do I install it?</h2>
        <p>
          You’ll need to go to the Chrome Store and find the extension{' '}
          <a href="https://chrome.google.com/webstore/detail/sheerba/effgadpnajejiggfiimjjnkhaggcgdfb">
            here
          </a>{' '}
          and press the “Add to Chrome” button. That’s it!
        </p>
        <p></p>
        <h2>How do I use Sheerba?</h2>
        <p>
          1. Go to Etsy, and perform a search. One of our favorites is “shiba”,
          just to see what new adorable stuff is there.
        </p>
        <p>
          2. Once you’ve performed a search and Etsy is displaying the results,
          then click the “Sheerba” button in the chrome browser bar.
        </p>
        <p>
          3. The first time you use it you will need to sign in with Google.
          This is needed so we can keep track of whether you have a subscription
          or not.
        </p>
        <p>4. Once signed in, you should see all the results appear.</p>
        <p>5. Sort by a column by clicking on it.</p>
        <p>6. You can scroll to the right to find more insights.</p>
        <p>
          6. The Detail Page link let’s you go to the detail page to look into
          comments, etc.
        </p>
        <p>
          7. The export button allows you to download and save the results of
          the search. This can be added into a spreadsheet and even combined
          with other searches to allow even further insight.
        </p>
        <p></p>
        <h2>How does it work?</h2>
        <p>
          Etsy provides some information about products that you, as a seller,
          can use as insights into how well a product is doing. Some of this
          information is in the main search page, but a lot of the information
          is on the detail pages of the search. Sheerba collects information
          from all of these pages and puts them together into a nice organized
          table for you.
        </p>
        <p></p>
        <h2>Does it only work for Chrome?</h2>
        <p>
          For the time being, yes. If you would like to request it to be made to
          work on other browsers (Safari, Firefox, Edge, etc), then feel free to
          contact us to let us know!
        </p>
        <p></p>
        <h2>Do I need to connect this tool to my Etsy account?</h2>
        <p>Nope!</p>
        <p></p>
        <h2>Do you track me at all?</h2>
        <p>
          We don’t want to be creepy. So full disclosure: we do have to do a
          little bit of tracking. For one, we keep track of what Google account
          you signed in with (for the sake of tracking whether you have a
          subscription). Then we track some errors and other things to make sure
          things stay working. If you’re ever concerned or curious you can learn
          more in our <a href="/Privacy-Policy">Privacy Policy</a>.
        </p>
        <p></p>
        <p>
          Sometimes extensions track all your tabs or even all your keystrokes.
          But that's super creepy and totally not called for. We don't do that!
          Again, we limit our tracking to what is important to give you the best
          experience.
        </p>
        <p></p>
        <h2>
          I want to use it with a different Google account; can I switch
          accounts?
        </h2>
        <p>
          There’s no built-in way to do this so just contact us and we’ll get
          that switched for you!
        </p>
        <p></p>
        {/* <h2>Does it cost anything?</h2>
        <p>
          It does, but we think the value is way higher than what you’d actually
          be paying. You can find more about this in our{' '}
          <a href='/Privacy-Policy'>pricing page</a>.
        </p>
        <p></p>
        <h2>Are there any discounts?</h2>
        <p>
          If you pay annually then you save quite a bit! You can find more about
          this in our <a href='/Privacy-Policy'>pricing page</a>.
        </p> */}
        <p></p>
        <h2>Is there a free trial?</h2>
        <p>
          There is a week free trial. Hopefully this is plenty of time for you
          to try out the tool and see how much help it is.
        </p>
        <p></p>
        <h2>Is there a limit to how much I can use the tool?</h2>
        <p>
          You can perform as many searches as you want! There is no limit to how
          many searches you can do, which is pretty crazy!
        </p>
        <p></p>
        <h2>How do I unsubscribe?</h2>
        <p>
          We’re sorry to see you go if this is the case! The unsubscribe button
          can be found in the menu. Your access will continue through what you
          paid for and you will not be charged for any future payments.
        </p>
        <p></p>
        <h2>How can I change the card I’m using on my subscription?</h2>
        <p>
          There’s not a built-in way to do this yet so if you need to do this
          then reach out to us at{' '}
          <a
            href="mailto:hello@sheerba.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            hello@sheerba.com
          </a>
          .
        </p>
        <p></p>
        <h2>How do I contact you?</h2>
        <p>
          You can contact us through the extension, or you can email us at{' '}
          <a
            href="mailto:hello@sheerba.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            hello@sheerba.com
          </a>
          .
        </p>
        <p></p>
        <h2>Is there a referral code I can send to people?</h2>
        <p>
          Not yet but it’s in the works! If you are interested in this then
          reach out to us and we’ll prioritize this for you.
        </p>
        <p></p>
        <h2>Do you do any affiliate marketing?</h2>
        <p>
          Not yet but it’s in the works! If you are interested at all, reach out
          to us and we’d love to start working with you on this!
        </p>
      </TextPage>
    )
  }
}

export default FAQ
