// import { getCustomerInfo } from '../DatabaseService'
import { getSubscriptions } from '../SubscriptionService'
import * as logger from '../Logger'

// const config = require('../../config.json')

export const getSubscriptionForUser = async (email) => {
  // TODO: This includes a duplicate call. Consolidate when confident only one customer.

  logger.log(
    'GetSubscriptionForUser. will exit-out if already present. userEmail:'
  )
  logger.log(email)

  if (email == null) {
    return
  }

  //   logger.log(this.state.needToRecheckConnectionToStripe === false)

  //   if (this.state.needToRecheckConnectionToStripe === false) {
  //     logger.log('Get subscription, but already received.')
  //     return
  //   }

  // This used to be used, but it doesn't seem necessary. commenting out for now to see if any impact
  // 1. Get stored customerIds for the userEmail
  //   const userRecords = await getCustomerInfo({
  //     email: userEmail,
  //   })

  //   logger.log('fetched userRecords:')
  //   logger.log(userRecords)

  //   // If there is no customer at all, then set as no subscription
  //   if (userRecords == null || userRecords.length <= 0) {
  //     logger.error(
  //       'They should be logged in which means there should be a record'
  //     )
  //     return
  //   }
  //   // Send error if there is more than one customer
  //   else if (userRecords && userRecords.length > 1) {
  //     logger.error(
  //       'There is more than one customer for the email, which was assumed impossible.',
  //       userEmail
  //     )
  //   }

  // 2. Fetch subscriptions for that customer
  // TODO: the subscriptions endpoint wasn't working but would make this more efficient.
  const results = await getSubscriptions({ email: email }).catch((error) => {
    logger.error('error getting subscription at getSubscriptionForUser')
    logger.error('error message:')
    logger.error(error)
    // this.setState({
    //   subscriptionQueried: true,
    //   showError: error.message,
    //   viewState: this.initialViewState,
    //   // needToRecheckConnectionToStripe: true,
    // })
    return
  })

  logger.log('get Subscriptions. results:')
  if (results) {
    logger.log(results)
    logger.log(results.subscriptions)
    logger.log(results.subscriptions.length)
  }

  if (results == null || results.subscriptions.length === 0) {
    logger.log(`No subscription found for email: ${email}`)
    // this.setState({
    //   subscriptionQueried: true,
    //   subscriptionId: null,
    //   subscriptionCancelAt: null,
    //   subscriptionStatus: null,
    //   hasPremiumAccess: determingWhetherSubscriptionIsActive({
    //     subscriptionCancelAt: null,
    //     subscriptionId: null,
    //     searchCount: this.state.searchCount,
    //   }),
    // })
    return
  }

  // 3. Set the subscription to an active one.
  const subscriptions = results.subscriptions
  for (let i = 0; i < subscriptions.length; i++) {
    logger.log('get subscriptions')
    logger.log(subscriptions[i])

    // trialing
    // active
    // incomplete
    // incomplete_expired
    // past_due
    // canceled
    // unpaid
    if (
      subscriptions[i].status === 'active' ||
      subscriptions[i].status === 'trialing'
    ) {
      logger.log('found active subscription')

      //   this.setState({
      //     subscriptionQueried: true,
      //     subscriptionCancelAt: subscriptions[i].cancel_at, // 1591250892 - 86400 * 2,
      //     subscriptionId: subscriptions[i].id,
      //     subscriptionStatus: subscriptions[i].status,
      //     hasPremiumAccess: determingWhetherSubscriptionIsActive({
      //       subscriptionCancelAt: subscriptions[i].cancel_at,
      //       subscriptionId: subscriptions[i].id,
      //       searchCount: this.state.searchCount,
      //     }),
      //   })
      return subscriptions[i]
    }
  }
}

// const determingWhetherSubscriptionIsActive = ({
//   subscriptionCancelAt,
//   subscriptionId,
//   searchCount,
// }) => {
//   logger.log('subscription status:')
//   logger.log(subscriptionCancelAt)
//   logger.log(subscriptionId)
//   logger.log(`search count: ${searchCount}`)

//   if (
//     searchCount < this.FREE_SEARCH_COUNT ||
//     config.USE_FREE_SEARCH_LIMIT[config.VALUE_KEY] === false
//   ) {
//     return true
//   }

//   if (config.SUBSCRIPTION_FEATURE_TOGGLE_TESTING[config.VALUE_KEY] === true) {
//     if (subscriptionId != null) {
//       if (subscriptionCancelAt) {
//         return false
//       } else {
//         return true
//       }
//     } else {
//       return false
//     }
//   }

//   if (subscriptionId != null) {
//     // If there is a subscription, then they may want to unsubscribe or resubscribe.
//     // TODO: make this a call at the beginning of running to keep things updated better.
//     // If there is a cancelAt time, and the current time is beyond the cancel-time, then it is no longer active
//     const nowInSeconds = new Date().getTime() / 1000
//     if (subscriptionCancelAt != null && nowInSeconds > subscriptionCancelAt) {
//       return false
//     }
//     // If there is not a cancelAt time or it is in the future, then they can have full access.
//     else {
//       return true
//     }
//   }
//   // if there is no subscription, then should have limited access
//   else {
//     return false
//   }
// }
