import React, { PureComponent } from 'react'
// import LoggedOut from '../../Components/LoggedOut/LoggedOut'

import lockImg from '../../../img/lockedIcon.png'

// import * as logger from '../../../services/Logger'

import RatingBadge from '../../Duplicated/RatingBadge/RatingBadge'
import './AnalyticDataComponent.css'
import { Container } from 'react-bootstrap'

import { getGoogleChart } from './GoogleCharts'
import { getFutureChart } from './FutureCharts'
import { Loader } from './Loader/Loader'

class AnalyticDataComponent extends PureComponent {
  currentSearch = ''
  previousSearch = ''

  // initialGoogleData = [
  //   { name: 'Oct 2016', 'dog shirt': 0 },
  //   { name: 'Nov 2016', 'dog shirt': 0 },
  //   { name: 'Dec 2016', 'dog shirt': 0 },
  //   { name: 'Jan 2017', 'dog shirt': 0 },
  //   { name: 'Feb 2017', 'dog shirt': 0 },
  //   { name: 'Mar 2017', 'dog shirt': 0 },
  //   { name: 'Apr 2017', 'dog shirt': 0 },
  //   { name: 'May 2017', 'dog shirt': 0 },
  //   { name: 'Jun 2017', 'dog shirt': 0 },
  //   { name: 'Jul 2017', 'dog shirt': 0 },
  //   { name: 'Aug 2017', 'dog shirt': 0 },
  //   { name: 'Sep 2017', 'dog shirt': 0 },
  //   { name: 'Oct 2017', 'dog shirt': 0 },
  //   { name: 'Nov 2017', 'dog shirt': 0 },
  //   { name: 'Dec 2017', 'dog shirt': 0 },
  //   { name: 'Jan 2018', 'dog shirt': 0 },
  //   { name: 'Feb 2018', 'dog shirt': 0 },
  //   { name: 'Mar 2018', 'dog shirt': 0 },
  //   { name: 'Apr 2018', 'dog shirt': 0 },
  //   { name: 'May 2018', 'dog shirt': 0 },
  //   { name: 'Jun 2018', 'dog shirt': 0 },
  //   { name: 'Jul 2018', 'dog shirt': 0 },
  //   { name: 'Aug 2018', 'dog shirt': 0 },
  //   { name: 'Sep 2018', 'dog shirt': 0 },
  //   { name: 'Oct 2018', 'dog shirt': 0 },
  //   { name: 'Nov 2018', 'dog shirt': 0 },
  //   { name: 'Dec 2018', 'dog shirt': 0 },
  //   { name: 'Jan 2019', 'dog shirt': 0 },
  //   { name: 'Feb 2019', 'dog shirt': 0 },
  //   { name: 'Mar 2019', 'dog shirt': 0 },
  //   { name: 'Apr 2019', 'dog shirt': 0 },
  //   { name: 'May 2019', 'dog shirt': 0 },
  //   { name: 'Jun 2019', 'dog shirt': 0 },
  //   { name: 'Jul 2019', 'dog shirt': 0 },
  //   { name: 'Aug 2019', 'dog shirt': 0 },
  //   { name: 'Sep 2019', 'dog shirt': 0 },
  //   { name: 'Oct 2019', 'dog shirt': 0 },
  //   { name: 'Nov 2019', 'dog shirt': 0 },
  //   { name: 'Dec 2019', 'dog shirt': 0 },
  //   { name: 'Jan 2020', 'dog shirt': 0 },
  //   { name: 'Feb 2020', 'dog shirt': 0 },
  //   { name: 'Mar 2020', 'dog shirt': 0 },
  //   { name: 'Apr 2020', 'dog shirt': 0 },
  //   { name: 'May 2020', 'dog shirt': 0 },
  //   { name: 'Jun 2020', 'dog shirt': 0 },
  //   { name: 'Jul 2020', 'dog shirt': 0 },
  //   { name: 'Aug 2020', 'dog shirt': 0 },
  //   { name: 'Sep 2020', 'dog shirt': 0 },
  // ]
  // initialPredictionData = [
  //   { name: 'Oct 2020', 'dog shirt': 0 },
  //   { name: 'Nov 2020', 'dog shirt': 0 },
  //   { name: 'Dec 2020', 'dog shirt': 0 },
  //   { name: 'Jan 2021', 'dog shirt': 0 },
  //   { name: 'Feb 2021', 'dog shirt': 0 },
  //   { name: 'Mar 2021', 'dog shirt': 0 },
  //   { name: 'Apr 2021', 'dog shirt': 0 },
  //   { name: 'May 2021', 'dog shirt': 0 },
  //   { name: 'Jun 2021', 'dog shirt': 0 },
  //   { name: 'Jul 2021', 'dog shirt': 0 },
  //   { name: 'Aug 2021', 'dog shirt': 0 },
  //   { name: 'Sep 2021', 'dog shirt': 0 },
  // ]

  checkSearchTerm = () => {}

  getCompetitionScoreComponent = () => {
    if (this.props.ratingIsLoading && this.props.ratingIsLoading === true) {
      return (
        <div
          className="info-section-placeholder"
          onClick={this.props.onBadgePress}
        >
          <div className="competition-score-text"> Rating</div>
          <div className="mt-3">
            <Loader></Loader>
          </div>
        </div>
      )
    } else if (
      this.props.searchTerm !== '' &&
      this.props.ratingIsLoading === false
    ) {
      if (this.props.hasPremiumAccess) {
        return (
          <div className="info-section" onClick={this.props.onBadgePress}>
            <div className="competition-score-text"> Rating</div>
            <RatingBadge value={this.props.rating}></RatingBadge>
          </div>
        )
      } else {
        return (
          <div
            className="info-section"
            onClick={this.props.onSubscribeButtonPressed}
          >
            <div className="blur-score-area">
              <img
                src={lockImg}
                alt="locked"
                className="competition-score-locked-icon"
              ></img>
            </div>
            <div className="competition-score-text-unsubscribed">Rating</div>

            <RatingBadge value={'?'}></RatingBadge>
          </div>
        )
      }
    } else {
      return (
        <div
          className="info-section-placeholder"
          onClick={this.props.onBadgePress}
        >
          <div className="competition-score-text-placeholder"> Rating</div>
        </div>
      )
    }
  }

  searchStatsComponent = () => {
    if (
      this.props.averagesAreLoading &&
      this.props.averagesAreLoading === true
    ) {
      return (
        <div className="stats-area">
          <div className="stats-area-info-area">
            <div className="stat-titles-row">
              <div className="stat-title-fav">AVG FAVORITES</div>
              <div className="stat-title-price">AVG PRICE</div>
              <div className="stat-title-sale">ITEMS ON PAGE ON SALE</div>
            </div>
            <div className="mt-4">
              <Loader></Loader>
            </div>
          </div>
        </div>
      )
    } else if (
      this.props.searchTerm !== '' &&
      this.props.averagesAreLoading === false
    ) {
      if (this.props.hasPremiumAccess) {
        return (
          <div className="stats-area">
            <div className="stats-area-info-area">
              <div className="stat-titles-row">
                <div className="stat-title-fav">AVG FAVORITES</div>
                <div className="stat-title-price">AVG PRICE</div>
                <div className="stat-title-sale">ITEMS ON PAGE ON SALE</div>
              </div>
              <div className="stat-data-row">
                <div className="stat-data-fav">
                  {this.props.averageFavorers}
                </div>
                <div className="stat-data-price">{this.props.averagePrice}</div>
                <div className="stat-data-sale">{this.props.totalSales}</div>
              </div>
            </div>
          </div>
        )
      } else {
        return (
          <div
            className="stats-area-unsubscribed"
            onClick={this.props.onSubscribeButtonPressed}
          >
            <div className="stats-area-info-area">
              <div className="stat-titles-row">
                <div className="stat-title-fav">AVG FAVORITES</div>
                <div className="stat-title-price">AVG PRICE</div>
                <div className="stat-title-sale">ITEMS ON PAGE ON SALE</div>
              </div>
              <img
                src={lockImg}
                alt="locked"
                className="stat-title-locked-icon"
              ></img>
            </div>
          </div>
        )
      }
    } else {
      return (
        <div className="stats-area">
          <div className="stats-area-info-area">
            <div className="stat-titles-row">
              <div className="stat-title-placeholder">AVG FAVORITES</div>
              <div className="stat-title-placeholder">AVG PRICE</div>
              <div className="stat-title-placeholder">
                ITEMS ON PAGE ON SALE
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  analyticToolComponents = () => {
    return (
      <div
        className="analytic-tools-wrapper row"
        // style={{ backgroundColor: 'red' }}
      >
        {/* {this.props.isAuthenticated && ( */}
        <div
          className="col-12 col-lg-6 col-xl-4 ml-lg-auto"
          // style={{ backgroundColor: 'blue' }}
        >
          {getGoogleChart({
            searchTerm: this.props.searchTerm,
            chartsAreLoading: this.props.chartsAreLoading,
            tableData: this.props.tableData,
          })}
        </div>
        <div
          className="col-12 col-lg-6 col-xl-4 mr-lg-auto"
          // style={{ backgroundColor: 'blue' }}
        >
          {getFutureChart({
            searchTerm: this.props.searchTerm,
            chartsAreLoading: this.props.chartsAreLoading,
            tableData: this.props.tableData,
            hasPremiumAccess: this.props.hasPremiumAccess,
            predictedData: this.props.predictedData,
            onSubscribeButtonPressed: this.props.onSubscribeButtonPressed,
          })}
        </div>
        {/* <div className="col-lg-6 col-xl-0"></div> */}
        <div
          className="col-12 col-lg-6 col-xl-4 my-auto ml-auto non-chart-analytics"
          // style={{ backgroundColor: 'blue' }}
        >
          <div className="ml-auto mr-1">
            {this.getCompetitionScoreComponent()}
          </div>
          <div className="ml-1 mr-auto mr-lg-0">
            {this.searchStatsComponent()}
          </div>
        </div>
      </div>
    )
  }

  placeholderView = () => {
    return (
      <div>
        <div
          className="spinner-border spinner-border-sm information"
          role="status"
          aria-hidden="true"
        ></div>
        {this.placeholderSearchView()}
      </div>
    )
  }

  placeholderSearchView = () => {
    return (
      <div className="content-functions">{this.analyticToolComponents()}</div>
    )
  }

  render() {
    return (
      <Container>
        <div className="analytics-wrapper">{this.analyticToolComponents()}</div>
      </Container>
    )
  }
}

export default AnalyticDataComponent
