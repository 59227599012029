import * as logger from './Logger'
import * as backend from './BackendService'
import * as database from './DatabaseService'

const config = require('../config.json')

const endpointEnum = Object.freeze({
  CANCEL: 'cancel-subscription',
  CUSTOMERS: 'customers',
  CREATE_CUSTOMER: 'create-customer',
  CREATE_SUBSCRIPTION: 'create-subscription',
  RESUBSCRIBE: 'reenable-subscription',
  DELETE_CUSTOMER: 'delete-customer',
})

export const getSubscriptions = async ({ email }) => {
  // note the flip from test to prod in the flag
  const useStripeProdKey =
    config.USE_TEST_STRIPE_KEYS[config.VALUE_KEY] === false

  const paramString = `${email}&${useStripeProdKey}`

  return await backend
    .getWithParams({
      endpoint: endpointEnum.CUSTOMERS,
      paramString: paramString,
      method: 'GET',
    })
    .then((response) => {
      logger.log('response')
      logger.log(response)
      return response
    })
    .then((result) => {
      logger.log(`Return from get: ${email}`)
      logger.log(result)
      return result
    })
    .catch((error) => {
      logger.log('Error getting from backend:')
      logger.log(email)
      logger.log(error)
      throw error
    })
}

export const saveSubscription = async ({
  email,
  customerId,
  subscriptionId,
  subscriptionCancelAt,
}) => {
  const querySnapshot = await database.getUserInfoForEmail({ email: email })

  if (querySnapshot == null || querySnapshot.size === 0) {
    const writeData = {
      email: email,
      customerId: customerId,
      subscriptionId: subscriptionId,
      subscriptionCancelAt: subscriptionCancelAt,
    }
    logger.log('writing new customer from saveSubscription. writeData:')
    logger.log(writeData)
    database.writeToUserDatabase({ writeData: writeData })
    // } else if (querySnapshot.size > 1) {
    //   logger.error('more than one for email at saveSubscription', email)
    //   deleteExtraCustomers(querySnapshot)
  } else {
    querySnapshot.forEach((doc) => {
      logger.log(doc.id, '=> ', doc.data())
      database.update({
        collection: 'users',
        doc: doc.id,
        customerId: customerId,
        subscriptionId: subscriptionId,
        subscriptionCancelAt: subscriptionCancelAt,
      })
    })
    // This is here to clean up database records. Not sure if needed anymore.
    database.getOldestRecordAndDeleteMoreRecent(querySnapshot)
  }
}

export const writeFailedSubscription = async ({ email, customerId }) => {
  const querySnapshot = await database.getUserInfoForEmail({ email: email })

  if (config.WRITE_TO_DATABASE[config.VALUE_KEY] === false) {
    logger.log('was going to write failed subscription')
    return
  }

  if (querySnapshot == null || querySnapshot.size === 0) {
    const writeData = {
      email: email,
      customerId: customerId,

      // Technically there is a subscriptionId, but I'm not sure how to handle
      // it properly. Setting this to null will make the app act properly in
      // terms of user access to functionality. It might break in regards to
      // allowing the user to subcribe properly.
      subscriptionId: null,

      subscriptionCancelAt: null,
    }
    logger.log('writing new customer from failedSubscription')
    logger.log(writeData)
    database.writeToUserDatabase({ writeData: writeData }).catch(() => {
      logger.error(
        'unsuccessful db write. Check if the viewState is correct when this is reached, as we may need to adjust the setting of viewState.'
      )
    })
  } else if (querySnapshot.size > 1) {
    logger.error(
      'more than one for email at writeFailedSubsription. which should have been handled',
      email
    )
    //deleteExtraCustomers(querySnapshot)
  } else {
    querySnapshot.forEach((doc) => {
      logger.log(doc.id, '=> ', doc.data())
      database.updateCustomerId({
        doc: doc.id,
        customerId: customerId,
      })
    })
  }
}

export const cancelSubscription = async ({ email, subscriptionId }) => {
  // note the flip from test to prod in the flag
  const useStripeProdKey =
    config.USE_TEST_STRIPE_KEYS[config.VALUE_KEY] === false

  logger.log('cancel subscription:::')
  const bodyData = {
    email: email,
    subscriptionId: subscriptionId,
    useStripeProdKey: useStripeProdKey,
  }
  logger.log(bodyData)
  const updatedSubscription = await backend
    .post({ endpoint: endpointEnum.CANCEL, bodyData: bodyData })
    .then((result) => {
      return result.subscription
    })
    .catch((error) => {
      throw error
    })

  logger.log('result')
  logger.log(updatedSubscription)
  if (updatedSubscription == null) return

  // Update Database
  let querySnapshot = await database.getUserInfoForEmail({ email: email })

  querySnapshot.forEach((doc) => {
    logger.log(doc.id, '=> ', doc.data())
    database.updateSubscriptionCancelAt({
      doc: doc.id,
      subscriptionCancelAt: updatedSubscription.cancel_at,
    })
  })

  return updatedSubscription
}

export const renewSubscription = async ({ email, subscriptionId }) => {
  // note the flip from test to prod in the flag
  const useStripeProdKey =
    config.USE_TEST_STRIPE_KEYS[config.VALUE_KEY] === false

  const bodyData = {
    subscriptionId: subscriptionId,
    useStripeProdKey: useStripeProdKey,
  }
  logger.log('renew subscription:::')
  logger.log(bodyData)
  logger.log(email)

  const updatedSubscription = await backend
    .post({
      endpoint: endpointEnum.RESUBSCRIBE,
      bodyData: bodyData,
    })
    .then((result) => {
      logger.log('reenable subscription server response:')
      const updatedSubscription = result.subscription
      logger.log(updatedSubscription)
      return updatedSubscription
    })
    .catch((error) => {
      throw error
    })

  // Update Database
  let querySnapshot = await database.getUserInfoForEmail({ email: email })

  querySnapshot.forEach((doc) => {
    logger.log(doc.id, '=> ', doc.data())
    database.updateSubscriptionCancelAt({
      doc: doc.id,
      subscriptionCancelAt: null,
    })
  })

  return updatedSubscription
}

export const deleteCustomer = async ({ email }) => {
  // note the flip from test to prod in the flag
  const useStripeProdKey =
    config.USE_TEST_STRIPE_KEYS[config.VALUE_KEY] === false

  const bodyData = {
    email: email,
    useStripeProdKey: useStripeProdKey,
  }
  const result = await backend
    .post({
      endpoint: endpointEnum.DELETE_CUSTOMER,
      bodyData: bodyData,
    })
    .then((result) => {
      return result
    })
    .catch((error) => {
      throw error
    })

  logger.log('delete customer. got result:')
  logger.log(result)
  return result
}

export const createCustomer = async ({ email }) => {
  // note the flip from test to prod in the flag
  const useStripeProdKey =
    config.USE_TEST_STRIPE_KEYS[config.VALUE_KEY] === false

  const bodyData = {
    email: email,
    useStripeProdKey: useStripeProdKey,
  }
  const result = await backend
    .post({
      endpoint: endpointEnum.CREATE_CUSTOMER,
      bodyData: bodyData,
    })
    .then((result) => {
      const customer = result.customer
      if (customer == null) {
        logger.error('did not find customer in result')
        return null
      }
      return customer
    })
    .catch((error) => {
      throw error
    })
  logger.log('create customer. got result:')
  logger.log(result)
  return result
}

export const createSubscription = async ({
  customerId,
  paymentMethodId,
  priceId,
}) => {
  // note the flip from test to prod in the flag
  const useStripeProdKey =
    config.USE_TEST_STRIPE_KEYS[config.VALUE_KEY] === false

  const bodyData = {
    customerId: customerId,
    paymentMethodId: paymentMethodId,
    priceId: priceId,
    useStripeProdKey: useStripeProdKey,
  }
  const result = await backend
    .post({
      endpoint: endpointEnum.CREATE_SUBSCRIPTION,
      bodyData: bodyData,
    })
    .catch((error) => {
      throw error
    })
  logger.log('create subscription. got result:')
  logger.log(result)
  return result
}
