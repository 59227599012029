import * as logger from '../Logger'

export class DetailPageParser {
  parseDetailPageDoc(doc) {
    logger.log('parseDetailPageDoc')
    logger.log(doc)
    const productMap = {}

    const imageSrc = this.getImage(doc)
    productMap.dp_image = imageSrc

    const reviewDict = this.parseReviewSection(doc)
    const reviewKeys = Object.keys(reviewDict)
    reviewKeys.forEach((key) => {
      productMap[key] = reviewDict[key]
    })

    const cartDict = this.parsePageCartSection(doc)
    const cartKeys = Object.keys(cartDict)
    cartKeys.forEach((key) => {
      productMap[key] = cartDict[key]
    })

    const indicators = this.parseIndicators(doc)
    const keys = Object.keys(indicators)
    keys.forEach((key) => {
      productMap[key] = indicators[key]
    })

    // getFavorersCount(productMap, doc)

    return productMap
  }

  // get image
  getImage(doc) {
    const imageLocation =
    'image-wrapper wt-position-relative carousel-container-responsive'
    const imageArea = doc.getElementsByClassName(imageLocation)
    logger.log('getElementsByClassName2')
    logger.log(imageArea)
    if (imageArea == null) {
      logger.error('didnt find image properly:')
      logger.error(imageLocation)
      logger.error(doc)
      return
    } else {
      const imageDiv = imageArea[0].getElementsByClassName(
        'wt-max-width-full wt-horizontal-center wt-vertical-center carousel-image wt-rounded'
      )
      const imageSrc = imageDiv[0].src
      return imageSrc
    }
  }

  // parse review section

  parseReviewSection(doc) {
    const productMap = {}
    const reviewsSection = doc.getElementById('reviews')
    if (reviewsSection) {
      const storeRatingCount = this.getStoreRatingCount(reviewsSection)
      productMap['dp_storeRatingCount'] = storeRatingCount
      productMap['dp_storeRating'] = this.getStoreRating(reviewsSection)
    }

    const itemReviewCountSection = doc.getElementById(
      'same-listing-reviews-tab'
    )
    if (itemReviewCountSection) {
      productMap['dp_listingRatingCount'] = this.getNumberOfReviewsForListing(
        itemReviewCountSection
      )
    }

    return productMap
  }

  getStoreRating(reviewsSection) {
    logger.log('getStoreRating')
    const inputs = reviewsSection.getElementsByClassName(
      'wt-display-inline-block wt-mr-xs-1'
    )[0]
    let text = inputs.innerHTML
    const start = text.indexOf('value=')
    text = text.substr(start + 7)
    const end = text.indexOf('"')
    text = text.substr(0, end)
    return text
  }

  getStoreRatingCount(reviewsSection) {
    logger.log('get store rating')
    const totalElement = reviewsSection.getElementsByTagName('h3')[0]
    if (totalElement) {
      let text = totalElement.innerHTML

      // first possibility: `12 shop reviews`
      let end = text.indexOf(' shop reviews')
      if (end >= 0) {
        let count = text.substr(0, end)
        count = count.replace(',', '')
        return count
      }

      // second possibility: `123 reviews`
      end = text.indexOf(' reviews')
      if (end >= 0) {
        const count = text.substr(0, end)
        return count.replace(',', '')
      }
    }
    return ''
  }

  getNumberOfReviewsForListing(itemReviewCountSection) {
    logger.log('getNumberOfReviewsForListing')
    if (!itemReviewCountSection) {
      return ''
    }
    const innerElement = itemReviewCountSection.getElementsByClassName(
      'wt-badge wt-badge--status-02 wt-ml-xs-2'
    )

    if (innerElement.length === 0) {
      return ''
    }
    const text = innerElement[0].innerHTML.trim()

    return text
  }

  // PageCartSection

  parsePageCartSection(doc) {
    const productMap = {}
    const cartSection = doc.getElementById('listing-page-cart')
    if (cartSection) {
      productMap['dp_title'] = this.getTitle(cartSection)
      productMap['dp_sales'] = this.getSales(cartSection)
      productMap['dp_shopName'] = this.getStore(cartSection)
      productMap['dp_bestseller'] = this.determineBestsellerFlag(cartSection)
      productMap['dp_price'] = this.getPrice(cartSection)
      productMap['dp_originalPrice'] = this.getOriginalPrice(cartSection)
      productMap['dp_salePercentage'] = this.getSalePercentage(cartSection)
    }

    return productMap
  }

  getTitle(cartSection) {
    logger.log('get title')
    const titleElement = cartSection.getElementsByTagName('h1')[0]

    if (titleElement) {
      let title = titleElement.innerHTML.trim()

      const spanIndex = title.lastIndexOf('</span>')
      if (spanIndex > 0) {
        title = title.substr(spanIndex + 7)
      }

      return title
    } else {
      logger.log('didnt find title element in cartSection:')
      logger.log(cartSection)
    }
  }

  getSales(cartSection) {
    // const salesOuterElement = cartSection.getElementsByClassName(
    //   'wt-text-link-no-underline wt-display-inline-flex-xs wt-align-items-center'
    // )
    let salesElement = cartSection.getElementsByClassName(
      'wt-screen-reader-only'
    )
    if (salesElement.length > 0) {
      salesElement = salesElement[0]
      // logger.log('salesElement')
      // logger.log(salesElement)
      const salesText = salesElement.outerText
      const endingIndex = salesText.indexOf(' ')
      const sales = salesText.substr(0, endingIndex)
      // logger.log(`|${sales}|`)
      return sales
    } else {
      return ''
    }
  }

  getStore(cartSection) {
    const aTag = cartSection.getElementsByTagName('a')[0]

    let link = aTag.href
    let endPosition = link.indexOf('?ref')
    link = link.substr(0, endPosition)

    let position = link.indexOf('shop/')
    const shopName = link.substr(position + 5)

    return shopName
  }

  determineBestsellerFlag(cartSection) {
    let bestseller = cartSection.getElementsByClassName(
      'wt-badge wt-badge--status-03  wt-mr-xs-1'
    )[0]
    if (bestseller !== undefined) {
      return true
    } else {
      return false
    }
  }
  getPrice(cartSection) {
    logger.log('getPrice')
    const price = cartSection.getElementsByClassName(
      'wt-text-title-03 wt-mr-xs-2'
    )[0]
    if (price) {
      let priceText = price.innerHTML
      const priceStart = priceText.indexOf('$')
      priceText = priceText.substr(priceStart + 1)
      const priceEnd = priceText.indexOf('.')
      priceText = priceText.substr(0, priceEnd + 3)
      return priceText
    } else {
      return ''
    }
  }

  getOriginalPrice(cartSection) {
    logger.log('getOriginalPrice')
    const originalPrice = cartSection.getElementsByClassName(
      'wt-text-strikethrough wt-text-caption wt-text-gray wt-mr-xs-1'
    )[0]
    if (originalPrice) {
      let priceText = originalPrice.innerHTML
      const priceStart = priceText.indexOf('$')
      priceText = priceText.substr(priceStart + 1)
      const priceEnd = priceText.indexOf('.')
      priceText = priceText.substr(0, priceEnd + 3)
      return priceText
    } else {
      return ''
    }
  }

  getSalePercentage(cartSection) {
    logger.log('getSalePercentage')
    const saleDiv = cartSection.getElementsByClassName(
      'wt-text-caption wt-text-slime'
    )
    // logger.log(saleDiv)
    // logger.log(saleDiv.length)
    if (saleDiv.length === 1) {
      let saleText = saleDiv[0].innerHTML
      const priceStart = saleText.indexOf('(')
      saleText = saleText.substr(priceStart + 1)
      const priceEnd = saleText.indexOf('%')
      saleText = saleText.substr(0, priceEnd)

      return saleText
    } else {
      return ''
    }
  }

  // parseIndicators

  parseIndicators(doc) {
    const productMap = {}

    let indicators = doc.body.getElementsByClassName(
      'wt-position-relative wt-text-caption'
    )
    // logger.log('getting indicators')
    // logger.log(indicators)
    for (let indicator of indicators) {
      let text = indicator.textContent.trim()

      // logger.log('text')
      // logger.log(text)

      let over_twenty = text.indexOf('Other people want this. Over 20') === 0
      let people_interested = text.indexOf('Other people want this.') === 0
      let free_us_shipping_35 =
        text.indexOf(
          'Nice choice! Enjoy free shipping to the US when you spend $35+ at this shop.'
        ) === 0
      let free_us_shipping =
        text.indexOf('Hooray! This item ships free to the US.') === 0
      let free_shipping = text.indexOf('Hooray! This item ships free.') === 0
      let almost_gone = text.indexOf("Almost gone. There's only ") === 0
      let on_time_shipping =
        text.indexOf(
          'Shipping on schedule. This shop has been shipping their orders on time.'
        ) === 0
      let rare_find = text.indexOf("Rare find — there's only") === 0
      let dontMissOut =
        text.indexOf('other people have this in their cart right now.') > -1
      let dontMissOutTwentyPlus =
        text.indexOf('and over 20 people have this in their cart right now.') >
        -1
      let quickShipping =
        text.indexOf(
          'Shipping quickly. This shop has been shipping orders within 3 days.'
        ) > -1

      if (over_twenty) {
        // logger.log('\thot item 20+')
        productMap['over_twenty'] = true
      } else if (people_interested) {
        let parts = text.split(' ')
        for (let part of parts) {
          let number = Number(part)
          if (isNaN(number) === false) {
            // logger.log(`\tinterested: ${number}`)
            productMap['interested_count'] = number
          }
        }
      } else if (free_us_shipping_35) {
        // logger.log('\tus shipping at 35')
        productMap['free_us_shipping_35'] = true
        //                     product_listing.us_shipping_at_35 = True
      } else if (free_us_shipping) {
        // logger.log('\tFree shipping in US.')
        productMap['free_us_shipping'] = true
        //                     product_listing.free_us_shipping = True
      } else if (free_shipping) {
        // logger.log('\tFree shipping')
        productMap['free_shipping'] = true
        // product_listing.free_shipping = True
      } else if (almost_gone) {
        logger.log('almost_gone')

        const innerElements = indicator.getElementsByClassName('nudge-number')
        logger.log(innerElements)

        if (innerElements.length === 0) {
          // logger.log('get from indicator text')
          // logger.log(indicator)
          // logger.log(indicator.innerHTML)
          let text = indicator.innerHTML

          // <b>Almost gone.</b> There's only --the almost gone is optional
          let beginningIndex = text.indexOf("There's only")
          let numberText = text
          if (beginningIndex > 0) {
            numberText = text.substr(beginningIndex)
          }

          let leftIndex = numberText.indexOf(' left')
          // " There's only # left..."
          const toStartOfNumber = "There's only ".length

          // first set few left:
          let fewLeft = numberText.substring(toStartOfNumber, leftIndex)
          logger.log(`chopping: |${fewLeft}|`)
          productMap['few_left'] = fewLeft

          // then set number in cart

          if (
            numberText.indexOf("and it's in more than 20 people's carts") > 0
          ) {
            productMap['over_twenty'] = true
          } else {
            // " ... left and # other people ..."
            let otherPeopleIndex = numberText.indexOf(' other people')
            if (otherPeopleIndex === -1) {
              otherPeopleIndex = numberText.indexOf(' other person')
            }
            if (otherPeopleIndex === -1) {
              otherPeopleIndex = numberText.indexOf(" people's")
            }
            if (otherPeopleIndex === -1) {
              otherPeopleIndex = numberText.indexOf(" it's in more than ")
            }
            if (otherPeopleIndex === -1) {
              otherPeopleIndex = numberText.indexOf(" it's in ")
            }
            logger.log(`other people: ${otherPeopleIndex}`)

            let cartNumber = numberText.substring(
              leftIndex + 10,
              otherPeopleIndex
            )
            logger.log(`cart number: ${cartNumber}`)
            productMap['dont_miss_out-number_in_cart'] = cartNumber
          }
        } else {
          logger.log('and now we are here')
          let number = innerElements[0].innerHTML
          if (isNaN(number) === false) {
            // logger.log(`\tfew left: ${number}`)
            productMap['few_left'] = number
          }
        }
      } else if (on_time_shipping) {
        // logger.log('\tShipping on time.')
        productMap['on_time_shipping'] = true
      } else if (rare_find) {
        let parts = text.split(' ')
        for (let part of parts) {
          let number = Number(part)
          if (isNaN(number) === false) {
            // logger.log(`\trare. remaining: ${number}`)
            productMap['rare_remaining'] = number
          }
        }
      } else if (dontMissOut) {
        let number = indicator.getElementsByClassName('nudge-number')[0]

        if (number !== undefined) {
          logger.log('number dont miss out')
          number = number.innerHTML

          if (isNaN(number) === false) {
            // logger.log(`\tfew left: ${number}`)
            productMap['dont_miss_out-number_left'] = number
          }

          // logger.log(text)
          let firstChop = text.substr(text.indexOf("        Don't miss out. "))
          // logger.log(firstChop)

          let beginningIndex = firstChop.indexOf('available, and ')
          let endingIndex = firstChop.indexOf(
            ' other people have this in their cart right now.'
          )
          let numberText = firstChop.substring(beginningIndex + 15, endingIndex)
          // logger.log(`chopping: |${numberText}|`)

          if (isNaN(numberText) === false && typeof numberText != 'undefined') {
            // logger.log(`\tpeople with this in cart: ${numberText}`)
            productMap['dont_miss_out-number_in_cart'] = numberText
          }
        }
      } else if (dontMissOutTwentyPlus) {
        let number = indicator.getElementsByClassName('nudge-number')[0]

        if (number !== undefined) {
          logger.log('number dont miss 20 plus')
          number = number.innerHTML

          if (isNaN(number) === false) {
            // logger.log(`\tfew left but 20plus have it: ${number}`)
            productMap['few_left_but_20_plus_have_it'] = number
          }
        }
      } else if (quickShipping) {
        productMap['quickShipping'] = true
      } else {
        // logger.log(`UNHANDLED: ${text}`)
        // logger.log(productMap)
        // const errorData = {
        //   table: 'unhandledIndicators',
        //   writeData: {
        //     className: text,
        //     listingLink: productMap['dps_link'],
        //   },
        // }
        // // writeToUserDatabase(writeData)
        // const event = new CustomEvent('NotifyContentWriteErrorToDatabase', {
        //   detail: errorData,
        // })
        // window.dispatchEvent(event)
      }
    }
    return productMap
  }

  // function getFavorersCount(productMap, doc) {
  //   logger.log('get favs')
  //   productMap['dp_favorers'] = 0

  //   const segment = doc.getElementsByClassName(
  //     'wt-display-flex-xs wt-justify-content-space-between wt-align-items-baseline wt-flex-direction-row-xs wt-mb-md-4'
  //   )[0]
  //   logger.log(segment)
  //   const favs = segment.getElementsByTagName('a')

  //   if (favs.length === 0) {
  //     return
  //   }

  //   let text = favs[0].innerText.trim()
  //   logger.log(text)
  //   const index = text.indexOf(' favorites')

  //   if (index >= 0) {
  //     const amountText = text.substr(0, index)
  //     const favorers = parseInt(amountText)
  //     productMap['dp_favorers'] = favorers
  //   } else if (text === 'One favorite') {
  //     productMap['dp_favorers'] = 1
  //   }
  // }
}
