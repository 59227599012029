import React, { PureComponent } from 'react'

import { TagsInteractor } from './TagsInteractor'
import { TagsPresenter } from './TagsPresenter'
import { TagsView } from './TagsView'

// TODO: Move to interactor
import lockedIconSrc from '../../../img/lockedIcon.png'

export class TagsComponent extends PureComponent {
  interactor
  presenter

  loadingState = {
    urlString: 'Loading...',
    copyAllButtonText: 'Copy All',
  }

  setupVIP = () => {
    this.presenter = new TagsPresenter()
    this.interactor = new TagsInteractor()

    this.interactor.presenter = this.presenter
    this.presenter.view = this
  }

  constructor(props) {
    super(props)
    this.setupVIP()

    this.state = this.loadingState
  }

  componentDidMount() {
    const propUrl = 'example.com' //this.props.url
    const interactorData = {
      url: propUrl,
    }
    this.interactor.getData(interactorData)
  }

  //   componentDidUpdate(previousProps) {
  //     if (previousProps.url !== this.props.url) {
  //       this.setState(this.initialState)
  //       this.interactor.getData(this.props.url)
  //     }
  //   }

  setViewState(viewProps) {
    this.setState({
      urlString: viewProps.urlString,
    })
  }

  setCopyButtonText(buttonText) {
    this.setState({
      copyAllButtonText: buttonText,
    })
  }

  render() {
    return (
      <TagsView
        hasPremiumAccess={this.props.hasPremiumAccess}
        tags={this.props.tags}
        onTagPressed={this.interactor.handleTagPressed}
        handleCopyAllPressed={this.interactor.handleCopyAllPressed}
        copyAllButtonText={this.state.copyAllButtonText}
        onSubscribePress={this.props.onSubscribePress}
        lockedIconSrc={lockedIconSrc}
      ></TagsView>
    )
  }
}
