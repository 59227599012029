import React, { Component } from 'react'
import { Button, Form } from 'react-bootstrap'

import ReactGA from 'react-ga'

import * as backend from '../../../services/BackendService'

export default class AffiliatePopup extends Component {
  constructor(props) {
    super(props)

    this.state = {
      emailIsValid: false,
      email: '',
      name: '',
      info: '',
      submitted: false,
    }
  }

  handleNameChange = (text) => {
    this.setState({
      name: text,
    })
  }

  handleEmailChange = (text) => {
    // console.log(text)
    const emailIsValid = this.validateEmail(text)
    this.setState({
      emailIsValid: emailIsValid,
      email: text,
    })
  }

  handleInfoChange = (text) => {
    this.setState({
      info: text,
    })
  }

  validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  handleSubmit = (event) => {
    event.preventDefault()

    if (this.validateEmail(this.state.email)) {
      const button = document.getElementById('download')
      button.click()
    }
  }

  handleApplyClick = () => {
    // ReactGA.event({
    //   category: 'Button',
    //   action: 'Apply for Affiliation',
    // })

    // console.log('handleapplyclick')
    // console.log(this.state.email)
    // console.log(this.state.name)
    // console.log(this.state.info)

    backend.sendAffilateRequestTelegram({
      email: this.state.email,
      name: this.state.name,
      info: this.state.info,
    })

    // const writeData = {
    //   email: this.state.email,
    //   date: new Date().toISOString(),
    // }

    // writeToUserDatabase({ writeData: writeData })

    this.setState({
      submitted: true,
    })
  }

  handleCloseClick = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Close Affiliate Form',
    })
    this.props.onClose()
  }

  form = () => {
    return (
      <div>
        <p className="pt-5">
          Thanks for your interest in our affiliate program. Tell us about
          yourself and we'll reach out to you as soon as we can!
        </p>
        <Form
          onSubmit={(event) => {
            this.handleSubmit(event)
          }}
        >
          <Form.Group className="pt-5" controlId="formBasicEmail">
            <Form.Control
              type="name"
              placeholder="Your name"
              onChange={(event) => {
                this.handleNameChange(event.target.value)
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="email"
              placeholder="Your email"
              onChange={(event) => {
                this.handleEmailChange(event.target.value)
              }}
            />
          </Form.Group>
          <div className="form-group">
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              placeholder="Tell us a bit about yourself, including links to any relevant social platforms, websites, etc."
              rows="5"
              onChange={(event) => {
                this.handleInfoChange(event.target.value)
              }}
            ></textarea>
          </div>
        </Form>
        <Button
          className="mt-3 mb-4"
          id="download"
          variant="primary"
          type="submit"
          onClick={this.handleApplyClick}
          disabled={
            !this.state.emailIsValid ||
            this.state.name === '' ||
            this.state.info === ''
          }
        >
          Apply
        </Button>
      </div>
    )
  }

  formSubmissionConfirmation = () => {
    return (
      <div>
        <p className="pt-5">
          Thanks again for your interest in our affiliate program.
        </p>
        <p className="pt-4 pb-4">
          Your information has been submitted and we will reach out to you
          shortly!
        </p>

        {/* <Button
          className="mt-4 mb-4"
          id="download"
          variant="primary"
          type="submit"
          onClick={this.handleCloseClick}
        >
          Done
        </Button> */}
      </div>
    )
  }

  getPage = () => {
    if (this.state.submitted) {
      return this.formSubmissionConfirmation()
    } else {
      return this.form()
    }
  }

  render() {
    return (
      <div>
        <div className="background-fade"></div>
        <div className="affiliateForm">
          <div className="video-modal-close" onClick={this.handleCloseClick}>
            ✕
          </div>
          <div className="container text-center my-auto affiliate-popup-body-width">
            <h1 className="pt-3">Become an Affiliate</h1>
            {this.getPage()}
          </div>
        </div>
      </div>
    )
  }
}
