import * as logger from '../../../services/Logger' //'../../Services/Logger'

export class TagsInteractor {
  presenter

  // constructor({ etsyToken, etsySecret, url, onDetailPage }) {
  //     this.etsyToken = etsyToken
  //     this.etsySecret = etsySecret
  //     this.onDetailPage = onDetailPage
  //   }

  sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  // Load data

  getData(interactorData) {
    // ... do interactor things

    const presenterData = {
      url: interactorData.url,
    }

    this.presenter.getData(presenterData)
  }

  // Handle actions

  handleCopyAllPressed = async (tags) => {
    logger.log('copy all pressed')
    logger.log(tags)
    navigator.clipboard.writeText(tags)

    this.presenter.setCopyButtonText('Copied all tags to clipboard!')

    await this.sleep(2000)

    this.presenter.setCopyButtonText('Copy All')
  }

  handleTagPressed = (tag) => {
    logger.log('tag pressed')
    logger.log(tag)
  }
}
