export class AccountPagePresenter {
  view

  // constructor({ etsyToken, etsySecret, url, onDetailPage }) {
  //     this.etsyToken = etsyToken
  //     this.etsySecret = etsySecret
  //     this.onDetailPage = onDetailPage
  //   }

  setTrialDate = (presenterData) => {
    const viewProps = {
      dateString: presenterData.date,
    }

    this.view.setViewState(viewProps)
  }

  capitalize(s) {
    return s[0].toUpperCase() + s.slice(1)
  }

  wait = (seconds) =>
    new Promise((resolve) => setTimeout(() => resolve(true), seconds * 1000))

  setSubscriptionStatus = ({
    subscriptionId,
    subscriptionIsCancelled,
    subscriptionStatus,
    trialEnd,
  }) => {
    let subscriptionStatusString

    if (subscriptionId == null) {
      subscriptionStatusString = 'No Subscription'
    } else if (subscriptionIsCancelled === true) {
      subscriptionStatusString = 'Cancelled'
    } else if (subscriptionStatus) {
      subscriptionStatusString = this.capitalize(subscriptionStatus)
    }

    this.view.setSubscriptionStatus(subscriptionStatusString)
  }

  showError = async (message) => {
    this.view.showError(message)

    if (await this.wait(3)) {
      this.view.showError()
    }
  }

  showSuccess = async (message) => {
    this.view.showSuccess(message)

    if (await this.wait(3)) {
      this.view.showSuccess()
    }
  }

  setUpdateButtonEnabledFlag = (isEnabled) => {
    this.view.setUpdateButtonEnabledFlag(isEnabled)
  }

  removeModal = () => {
    this.view.removeModal()
  }
}
