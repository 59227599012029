import * as logger from './Logger'

import { SearchType } from '../pages/TagSearch/SearchType'

const config = require('../config.json')

const endpointEnum = Object.freeze({
  EBOOK_SIGNUP: 'send-ebook-download',
  AFFILIATE_SIGNUP: 'send-affiliate-signup',
  LOGIN: 'login',
  REGISTER: 'register',
  GET_TAG_API_SEARCH: 'tag-search',
  GET_KEYWORD_API_SEARCH: 'keyword-search',
  GET_PAGE: 'get-page',
  GET_MAIN_PAGE: 'get-main-page',
  GET_TREND_DATA: 'get-trend-data',
  TERM_ANALYSIS: 'term-analysis',
  OAUTH: 'begin-oauth',
  SEND_FEEDBACK: 'send-feedback',
  SEND_EVENT: 'send-web-event',
  VALIDATE: 'validate',
})

export let baseUrl
if (config.USE_DEV_BACKEND[config.VALUE_KEY] === true) {
  baseUrl = 'http://localhost:5000/sheerba-9de25/us-central1/app'
} else {
  baseUrl = 'https://us-central1-sheerba-9de25.cloudfunctions.net/app'
}

export async function post({ endpoint, bodyData }) {
  return await fetchWithInfo({
    endpoint: endpoint,
    bodyData: bodyData,
    method: 'POST',
  }).catch((error) => {
    throw error
  })
}

async function getUrl(url) {
  logger.log(`get url: ${url}`)
  return await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      logger.log('gettings response data:')
      logger.log(response)
      if (!response.ok) {
        throw Error(response.status)
      }
      return response.json()
    })
    .then((result) => {
      logger.log(`Return from get:`)
      logger.log(result)
      return result
    })
    .catch((error) => {
      if (error.toString() === 'Error: 403') {
        throw error
      }
      logger.error('Error getting from backend:')
      logger.error(error)
      logger.error(url)
      throw error
    })
}

export async function getWithParams({ endpoint, paramString }) {
  logger.log('backend get request')
  logger.log(endpoint)
  logger.log(paramString)
  const url = `${baseUrl}/${endpoint}/${paramString}`
  logger.log(url)
  return await getUrl(url).catch((error) => {
    throw error
  })
}

export async function fetchWithInfo({ endpoint, bodyData, method }) {
  const body = JSON.stringify(bodyData)
  return await fetch(`${baseUrl}/${endpoint}`, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
    },
    body: body,
  })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.status)
      }

      const result = response.json()
      // console.log(`Return from post of endpoint: ${endpoint}`)
      // console.log(`and body: ${body}`)
      // console.log(result)
      return result
    })
    .catch((error) => {
      console.error('Error Posting to backend:')
      console.error(endpoint)
      console.error(body)
      console.error(error)
      throw error
    })
}

export async function sendAffilateRequestTelegram({ email, name, info }) {
  const bodyData = {
    email: email,
    name: name,
    info: info,
  }

  //SEND_UPDATE
  return await post({
    endpoint: endpointEnum.AFFILIATE_SIGNUP,
    bodyData: bodyData,
  }).catch((error) => {
    // console.log('error received')
    // console.log(error)
    throw error
  })
}

export async function sendEbookDownloadTelegram({ email }) {
  const bodyData = {
    email: email,
  }

  //SEND_UPDATE
  return await post({
    endpoint: endpointEnum.EBOOK_SIGNUP,
    bodyData: bodyData,
  }).catch((error) => {
    // console.log('error received')
    // console.log(error)
    throw error
  })
}

export async function login({ email, password }) {
  // console.log('login backend request')
  const bodyData = {
    email: email,
    password: password,
  }

  //SEND_UPDATE
  return await post({
    endpoint: endpointEnum.LOGIN,
    bodyData: bodyData,
  }).catch((error) => {
    // console.log('error received')
    // console.log(error)
    throw error
  })
}

export async function register({ name, email, password }) {
  // console.log('register backend request')
  const bodyData = {
    name: name,
    email: email,
    password: password,
  }

  //SEND_UPDATE
  return await post({
    endpoint: endpointEnum.REGISTER,
    bodyData: bodyData,
  }).catch((error) => {
    // console.log('error received')
    // console.log(error)
    throw error
  })
}

// Tag Search Related:

//GET_TAG_SEARCH
export const getAPISearchData = async ({
  search,
  categoryNumber,
  type,
  limit,
  offset = 0,
  etsyToken,
  etsySecret,
}) => {
  // const spaceEncoded = '%20'
  const endpoint =
    type === SearchType.TAG
      ? endpointEnum.GET_TAG_API_SEARCH
      : endpointEnum.GET_KEYWORD_API_SEARCH

  const searchTerm = encodeURIComponent(`${search}`)
  const params = `${searchTerm}&${categoryNumber}&${limit}&${offset}&${etsyToken}&${etsySecret}`
  const url = `${baseUrl}/${endpoint}/${params}`
  logger.log('getAPISearchData')
  logger.log(url)

  return await getUrl(url).catch((error) => {
    throw error
  })
}

export const getDetailPage = async ({ url }) => {
  const params = encodeURIComponent(url)
  // const backendUrl = `${baseUrl}/${endpointEnum.GET_PAGE}`
  const backendUrl = `${baseUrl}/${endpointEnum.GET_PAGE}/${params}`
  logger.log('get detail page')

  return await getUrl(backendUrl).catch((error) => {
    logger.log('hit error')
    logger.log(error)
    throw error
  })
  // }

  // export const getHTMLForUrl = async (url) => {
  // logger.log('gethtml')
  // return await fetch(url)
  //   .then((response) => {
  //     // logger.log('got response')
  //     // logger.log(response)
  //     if (!response.ok) {
  //       throw Error(response.status)
  //     }
  //     return response.text()
  //   })
  //   .then(async (html) => {
  //     // logger.log('then hereasdf')
  //     // logger.log(html)
  //     return html
  //   })
  //   .catch(async (error) => {
  //     logger.log(url)
  //     logger.log(error)
  //     logger.log(`${error}`)
  //     return null
  //   })
}

export const getMainPage = async ({ searchTerm }) => {
  const encodedTerm = encodeURIComponent(`${searchTerm}`)
  logger.log(encodedTerm)

  // const backendUrl = `${baseUrl}/${endpointEnum.GET_PAGE}`
  const backendUrl = `${baseUrl}/${endpointEnum.GET_MAIN_PAGE}/${encodedTerm}`
  logger.log('get main page')

  return await getUrl(backendUrl).catch((error) => {
    logger.log('hit error')
    logger.log(error)
    throw error
  })

  // }

  // export const getHTMLForUrl = async (url) => {
  // logger.log('gethtml')
  // return await fetch(url)
  //   .then((response) => {
  //     // logger.log('got response')
  //     // logger.log(response)
  //     if (!response.ok) {
  //       throw Error(response.status)
  //     }
  //     return response.text()
  //   })
  //   .then(async (html) => {
  //     // logger.log('then hereasdf')
  //     // logger.log(html)
  //     return html
  //   })
  //   .catch(async (error) => {
  //     logger.log(url)
  //     logger.log(error)
  //     logger.log(`${error}`)
  //     return null
  //   })
}

// Trend info

export async function getTrendData({ currentSearch, previousSearch }) {
  const param = `${currentSearch}/${previousSearch}`
  return await getWithParams({
    endpoint: endpointEnum.GET_TREND_DATA,
    paramString: param,
  }).catch((error) => {
    throw error
  })
}

// analyzeTitleInformation
export async function analyzeTitleInformation({
  count,
  ads,
  searchTerm,
  views,
  favorers,
}) {
  searchTerm = encodeURIComponent(`${searchTerm}`)
  const params = `${count}&${ads}&${searchTerm}&${views}&${favorers}`

  return await getWithParams({
    endpoint: endpointEnum.TERM_ANALYSIS,
    paramString: params,
  }).catch((error) => {
    throw error
  })
}

// ETSY OAUTH
export async function doOauth({ email }) {
  const url = `${baseUrl}/${endpointEnum.OAUTH}/${email}`
  return await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  })
    .then((response) => {
      logger.log('gettings response data of oAuth:')
      logger.log(response)
      logger.log(response.message)
      logger.log(response.error)
      if (!response.ok) {
        throw Error(response.status)
      }
      return response.json()
    })
    .then((result) => {
      logger.log(`Return from get  of oAuth:`)
      logger.log(result)
      return result
    })
    .catch((error) => {
      logger.log('Error getting from backend  of oAuth:')
      logger.log(error)
      throw error
    })
}

export async function submitCouponCode(coupon) {
  return new Promise((resolve, reject) => {
    logger.log('backend')
    logger.log(coupon)

    // assuming successful
    let data = {
      discountPercentage: '20%',
      newPrice: '$6.66',
    }
    return resolve(data)

    // assuming unsuccessful
    // return reject(Error('Invalid Code'))
  })
}

export async function sendTelegramFeedbackEvent({
  feedback,
  userName,
  userEmail,
}) {
  logger.log('sending feedback to telegram:')
  logger.log(feedback)
  const bodyData = {
    feedback: feedback,
    userName: userName,
    userEmail: userEmail,
  }
  //SEND_UPDATE
  return await post({
    endpoint: endpointEnum.SEND_FEEDBACK,
    bodyData: bodyData,
  }).catch((error) => {
    logger.log('error received')
    logger.log(error)
    throw error
  })
}

export async function sendEventTelegram({
  category,
  event,
  displayName,
  email,
}) {
  logger.log('send event to telegram')
  const bodyData = {
    category: category,
    event: `${event}`,
    displayName: displayName,
    email: email,
  }
  logger.log(bodyData)
  //SEND_UPDATE
  return await post({
    endpoint: endpointEnum.SEND_EVENT,
    bodyData: bodyData,
  })
    .then((resp) => {
      // logger.log('send telegram got a response')
      // logger.log(resp)
    })
    .catch((error) => {
      logger.log('error received')
      logger.log(error)
      throw error
    })
}

export async function validateEtsyOAuth({
  tempToken,
  tempSecret,
  newToken,
  verifier,
  email,
}) {
  const param = `${tempToken}&${tempSecret}&${newToken}&${verifier}&${email}`
  return await getWithParams({
    endpoint: endpointEnum.VALIDATE,
    paramString: param,
  })
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('validate etsy oauth error')
      console.error(error)
      throw error
    })
}
