import React, { Component } from 'react'

import './PriceComponent.css'

import PremiumFeaturesComponent from '../PremiumFeaturesComponent/PremiumFeaturesComponent'
import Payment from './Payment'

import * as backend from '../../../services/BackendService'
import * as logger from '../../../services/Logger'

import checkmark from '../../../img/checkmark.png'
import paymentBackground from '../../../img/paymentBackground.png'
import mascot from '../../../img/mascot.png'

class PriceComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      monthly: true,
      page: 1,
      code: undefined,
      couponError: undefined,
      discountDollars: undefined,
      discountCents: undefined,
    }
  }

  onMonthlyPress = () => {
    this.setState({
      monthly: true,
    })
  }

  onAnnuallyPress = () => {
    this.setState({
      monthly: false,
    })
  }

  onUpgradePress = () => {
    this.setState({
      page: 2,
    })
  }

  handleBack = () => {
    this.setState({
      page: 1,
    })
  }

  firstPageText = () => {
    return (
      <div className="text-area-page1">
        <div className="free-trial-text">1 Week Free Trial!</div>
        <div className="free-trial-explanation-text">
          Try for a week before paying anything. Cancel before that and never be
          charged.
        </div>
        <PremiumFeaturesComponent></PremiumFeaturesComponent>
        {this.cost()}
      </div>
    )
  }

  couponEntered = async () => {
    logger.log('info')
    logger.log(this.state.code)
    backend
      .submitCouponCode(this.state.code)
      .then((results) => {
        logger.log('got discount')
        logger.log(results)
        this.setState({
          couponError: undefined,
          discountDollars: 3,
          discountCents: 33,
        })
      })
      .catch((error) => {
        logger.log('error')
        logger.log(error)
        logger.log(error.message)
        this.setState({
          couponError: error.message,
          discountDollars: undefined,
          discountCents: undefined,
        })
      })
  }

  couponEntryForm = () => {
    return (
      <form
        className="coupon-form"
        onSubmit={async (event) => {
          logger.log('here')
          event.preventDefault()
          await this.couponEntered()
        }}
      >
        {this.state.couponError && (
          <div className="coupon-error">{this.state.couponError}</div>
        )}
        <input
          type="text"
          className="coupon-text"
          placeholder="Coupon Code"
          onChange={(e) => {
            logger.log(e.target.value)
            this.setState({
              code: e.target.value,
            })
          }}
        ></input>
        <input type="submit" value="Apply" className="coupon-submit"></input>
      </form>
    )
  }

  couponEnteredSection = () => {
    return (
      <div>
        <div className="coupon-success-msg">Coupon Code has been applied</div>
        <div className="coupon-success-area-positioning">
          <div className="coupon-success-area">
            <img
              className="coupon-success-checkmark"
              src={checkmark}
              alt=""
            ></img>
            <div className="coupon-success-code">123</div>
          </div>
        </div>
      </div>
    )
  }

  secondPageText = () => {
    return (
      <div className="text-area-page2">
        <div className="free-trial-text">1 Week Free Trial!</div>
        <div className="free-trial-explanation-text">
          Try for a week before paying anything. Cancel before that and never be
          charged.
        </div>
        {this.state.discountDollars ? this.discountedCost() : this.cost()}
        {/* {this.state.discountDollars
          ? this.couponEnteredSection()
          : this.couponEntryForm()} */}
        <Payment
          onSubscriptionAdded={this.props.onSubscriptionAdded}
          onCustomerCreatedSubscriptionFailed={
            this.props.onCustomerCreatedSubscriptionFailed
          }
          email={this.props.email}
          subscriptionType={this.state.subscriptionType}
        ></Payment>
      </div>
    )
  }

  cost = () => {
    return (
      <div>
        {this.state.monthly ? (
          <div>
            <div className="cost">
              <div className="dollar"> $ 7</div>
              <div className="cents">.99</div>
            </div>
            <div className="pay-frequency">per month</div>
            <div className="annual-cost-hidden"> $59.99 Billed Annually</div>
          </div>
        ) : (
          <div>
            <div className="cost">
              <div className="dollar"> $ 4</div>
              <div className="cents">.99</div>
            </div>
            <div className="pay-frequency">per month</div>
            <div className="annual-cost"> $59.99 Billed Annually</div>
          </div>
        )}
      </div>
    )
  }

  // discountedCost = () => {
  //   return (
  //     <div>
  //       {this.state.monthly ? (
  //         <div>
  //           <div className='cross-out-line'></div>
  //           <div className='cost'>
  //             <div className='reg-price'>
  //               <div className='reg-price-dollar'> $ 9</div>
  //               <div className='reg-price-cents'>.99</div>
  //             </div>
  //             <div className='discount-price'>
  //               <div className='discount-price-dollar'> $ 7</div>
  //               <div className='discount-price-cents'>.99</div>
  //             </div>
  //           </div>
  //           <div className='pay-frequency'>per month</div>
  //           <div className='annual-cost-hidden'> $100 Billed Annually</div>
  //         </div>
  //       ) : (
  //         <div>
  //           <div className='cross-out-line'></div>
  //           <div className='cost'>
  //             <div className='reg-price'>
  //               <div className='reg-price-dollar'> $ 8</div>
  //               <div className='reg-price-cents'>.33</div>
  //             </div>
  //             <div className='discount-price'>
  //               <div className='discount-price-dollar'> $ 6</div>
  //               <div className='discount-price-cents'>.66</div>
  //             </div>
  //           </div>
  //           <div className='pay-frequency'>per month</div>
  //           <div className='annual-cross-out-line'></div>
  //           <div className='discounted-annual-cost'>
  //             <div className='discounted-annual-original-cost'>$100</div>
  //             <div className='discounted-annual-discounted-text'>
  //               $80 Billed Annually
  //             </div>
  //           </div>
  //         </div>
  //       )}
  //     </div>
  //   )
  // }

  annualUpgradeButton = () => {
    return (
      <div className="price-upgrade-button-annual">
        <div className="upgrade-button-cost" onClick={this.onUpgradePress}>
          <div className="upgrade-text-1">Upgrade</div>
          <div className="upgrade-text-2">40% Savings</div>
        </div>
      </div>
    )
  }
  monthlyUpgradeButton = () => {
    return (
      <div className="price-upgrade-button-monthly">
        <div className="upgrade-button-cost" onClick={this.onUpgradePress}>
          <div className="upgrade-text-1">Upgrade</div>
        </div>
      </div>
    )
  }

  page = () => {
    return (
      <div>
        <div
          className="price-modal-area w-100 w-sm-auto"
          style={{
            backgroundImage: `url(${paymentBackground})`,
            backgroundPositionY: 'center',
          }}
        >
          {this.state.page === 2 && (
            <div className="price-modal-close" onClick={this.handleBack}>
              {'Back'}
            </div>
          )}
          {/* <div className='background-top'></div> */}
          <img className="mascot-on-price" src={mascot} alt="" />
          <div className="price-header-text">Full Unlimited Access</div>
          <div className="price-plan-buttons">
            <div
              className={
                this.state.monthly
                  ? 'selected-plan-button'
                  : 'unselected-plan-button'
              }
              onClick={this.onMonthlyPress}
            >
              Billed Monthly
            </div>
            <div
              className={
                this.state.monthly
                  ? 'unselected-plan-button'
                  : 'selected-plan-button'
              }
              onClick={this.onAnnuallyPress}
            >
              Billed Annually
            </div>
          </div>

          {this.state.page === 1 ? this.firstPageText() : this.secondPageText()}
          {this.props.isSubscribed == null && this.state.page === 1 ? (
            this.state.monthly ? (
              this.monthlyUpgradeButton()
            ) : (
              this.annualUpgradeButton()
            )
          ) : (
            <div></div>
          )}
        </div>
      </div>
    )
  }

  render = () => {
    return this.page()
  }
}

export default PriceComponent
