import React, { useState, useEffect } from 'react'

import '../../css/stylish-portfolio.min.css'
import '../../vendor/simple-line-icons/css/simple-line-icons.css'
import { Header } from './Header/Header'
import Features from './Features/Features'
import { BodyCallout } from './BodyCTA/BodyCallout'
import FeatureCTA from './FeatureCTA/FeatureCTA'
import { FooterCallout } from './HomeFooter/FooterCallout'
import { Footer } from '../../components/Footer/Footer'
import Circles from './FurtherFeatures/Circles'
import EmailForm from './EmailForm/EmailForm'
import { useAuth } from '../../services/contexts/AuthContext'
import { VideoModal } from '../../components/VideoModal/VideoModal'

const ModalStateEnum = Object.freeze({
  NONE: 1,
  EBOOK: 2,
  VIDEO: 3,
  // OAUTH_DONE: 12,
  // EXPLAIN_FUTURE_CHART: 13,
  // EXPLAIN_GOOGLE_CHART: 14,
  // FEEDBACK: 15,
})

export const Home = () => {
  const { tracker } = useAuth()

  useEffect(() => {
    tracker.trackEvent({
      category: 'View',
      actionName: 'Home',
      sendTelegram: true,
    })
  }, [tracker])

  return <HomeComponent></HomeComponent>
}

const HomeComponent = () => {
  const [viewState, setViewState] = useState(ModalStateEnum.NONE)

  const handleShowForm = () => {
    setViewState(ModalStateEnum.EBOOK)
  }
  const handleCloseModal = () => {
    setViewState(ModalStateEnum.NONE)
  }

  const handleWatchVideoPressed = () => {
    setViewState(ModalStateEnum.VIDEO)
  }

  return (
    <div>
      {viewState === ModalStateEnum.EBOOK && (
        <EmailForm onFormSubmitted={handleCloseModal}></EmailForm>
      )}
      <Header
        onShowForm={handleShowForm}
        onWatchVideoPressed={handleWatchVideoPressed}
      ></Header>
      <FeatureCTA></FeatureCTA>
      <Features></Features>
      <BodyCallout></BodyCallout>
      <Circles></Circles>
      <FooterCallout></FooterCallout>
      <Footer></Footer>
      {viewState === ModalStateEnum.VIDEO && (
        <VideoModal onClose={handleCloseModal}></VideoModal>
      )}
    </div>
  )
}
