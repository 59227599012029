import React, { Component } from 'react'

export default class Feature extends Component {
  render() {
    return (
      <div className="card shadow bg-white rounded col-12 col-sm-5 col-md-3 m-1">
        <div className="card-block px-auto">
          <img className="mt-4" src={this.props.imageSrc} alt=""></img>
          <h3 className="mt-4 px-2">{this.props.title}</h3>
          <p className="px-2 pt-2">{this.props.description}</p>
        </div>
      </div>
    )
  }
}
