import React, { PureComponent, useEffect } from 'react'

import { AccountPageInteractor } from './AccountPageInteractor'
import { AccountPagePresenter } from './AccountPagePresenter'
import { AccountPageView } from './AccountPageView'

import { useAuth } from '../../services/contexts/AuthContext'
import { FeedbackModalComponent } from '../../components/Duplicated/FeedbackModal/FeedbackModalController'

export const Account = () => {
  const {
    isLoading,
    logout,
    userName,
    userEmail,
    subscriptionId,
    subscriptionIsCancelled,
    subscriptionStatus,
    trialEnd,
    etsyToken,
    tracker,
  } = useAuth()

  useEffect(() => {
    tracker.trackEvent({
      category: 'View',
      actionName: 'Account',
      sendTelegram: true,
    })
  }, [tracker])

  if (isLoading) {
    return <div></div>
  }
  return (
    <AccountPageComponent
      tracker={tracker}
      logout={logout}
      userName={userName}
      userEmail={userEmail}
      subscriptionId={subscriptionId}
      subscriptionIsCancelled={subscriptionIsCancelled}
      subscriptionStatus={subscriptionStatus}
      trialEnd={trialEnd}
      etsyToken={etsyToken}
    ></AccountPageComponent>
  )
}

const viewStateEnum = Object.freeze({
  NONE: 0,
  UNSUBSCRIBE: 1,
  RESUBSCRIBE: 2,
  FEEDBACK: 3,
})

class AccountPageComponent extends PureComponent {
  interactor
  presenter

  editableName

  loadingState = {
    showModal: viewStateEnum.NONE,
    trialEndDate: '',
    updateButtonIsEnabled: false,
    errorMessage: undefined,
    successMessage: undefined,
  }

  setupVIP = () => {
    this.presenter = new AccountPagePresenter()
    this.interactor = new AccountPageInteractor({ tracker: this.props.tracker })

    this.interactor.presenter = this.presenter
    this.presenter.view = this
  }

  constructor(props) {
    super(props)
    this.setupVIP()

    this.state = this.loadingState
    this.editableName = this.props.userName
  }

  handleShowUnsubscribe = () => {
    this.props.tracker.trackEvent({
      category: 'Event',
      actionName: 'Account - Show Unsubscribe',
      sendTelegram: true,
    })
    this.setState({
      showModal: viewStateEnum.UNSUBSCRIBE,
    })
  }

  handleShowResubscribe = () => {
    this.props.tracker.trackEvent({
      category: 'Event',
      actionName: 'Account - Show Resubscribe',
      sendTelegram: true,
    })
    this.setState({
      showModal: viewStateEnum.RESUBSCRIBE,
    })
  }

  handleFeedbackButtonPress = () => {
    this.props.tracker.trackEvent({
      category: 'Event',
      actionName: 'Account - Contact Us Pressed',
      sendTelegram: true,
    })
    this.setState({
      showModal: viewStateEnum.FEEDBACK,
    })
  }

  removeModal = () => {
    this.setState({
      showModal: viewStateEnum.NONE,
    })
  }

  componentDidMount() {
    const interactorData = {
      trialEnd: this.props.trialEnd,
    }
    this.interactor.setTrialDate(interactorData)
    this.interactor.setSubscriptionStatus({
      subscriptionId: this.props.subscriptionId,
      subscriptionIsCancelled: this.props.subscriptionIsCancelled,
      subscriptionStatus: this.props.subscriptionStatus,
      trialEnd: this.props.trialEnd,
    })
    this.editableName = this.props.userName
  }

  componentDidUpdate(previousProps) {
    // console.log('did update')

    if (
      previousProps.subscriptionId !== this.props.subscriptionId ||
      previousProps.subscriptionIsCancelled !==
        this.props.subscriptionIsCancelled ||
      previousProps.subscriptionStatus !== this.props.subscriptionStatus ||
      previousProps.trialEnd !== this.props.trialEnd
    ) {
      this.interactor.setSubscriptionStatus({
        subscriptionId: this.props.subscriptionId,
        subscriptionIsCancelled: this.props.subscriptionIsCancelled,
        subscriptionStatus: this.props.subscriptionStatus,
        trialEnd: this.props.trialEnd,
      })
    }

    if (previousProps.userName !== this.props.userName) {
      this.editableName = this.props.userName
      this.setState({
        updateButtonIsEnabled: false,
      })
    }
  }

  setViewState(viewProps) {
    this.setState({
      trialEndDate: viewProps.dateString,
    })
  }

  setSubscriptionStatus(subscriptionStatusString) {
    this.setState({
      subscriptionStatusString: subscriptionStatusString,
    })
  }

  showError = (message) => {
    this.setState({
      errorMessage: message,
    })
  }
  showSuccess = (message) => {
    this.setState({
      successMessage: message,
    })
  }

  handleLogout = () => {
    this.props.tracker.trackEvent({
      category: 'Event',
      actionName: 'Account - Logout',
      sendTelegram: true,
    })
    this.props.logout()
  }

  onNameKeyUp = (e) => {
    // console.log('on email key up')
    this.editableName = e.target.value

    this.interactor.setUpdateButtonEnabledFlag({
      initialName: this.props.userName,
      editableName: this.editableName,
    })
  }
  setUpdateButtonEnabledFlag = (isEnabled) => {
    this.setState({
      updateButtonIsEnabled: isEnabled,
    })
  }
  onUpdatePressed = () => {
    this.interactor.handleUpdatePressed({
      email: this.props.userEmail,
      initialName: this.props.userName,
      editableName: this.editableName,
    })
  }

  render() {
    return (
      <div>
        {this.state.showModal === viewStateEnum.FEEDBACK && (
          <FeedbackModalComponent
            onClose={this.removeModal}
            userName={this.props.userName}
            email={this.props.userEmail}
          ></FeedbackModalComponent>
        )}
        <AccountPageView
          showUnsubscribe={this.state.showModal === viewStateEnum.UNSUBSCRIBE}
          showResubscribe={this.state.showModal === viewStateEnum.RESUBSCRIBE}
          email={this.props.userEmail}
          name={this.editableName}
          onNameKeyUp={this.onNameKeyUp}
          onUpdatePressed={this.onUpdatePressed}
          updateButtonIsEnabled={this.state.updateButtonIsEnabled}
          logout={this.handleLogout}
          subscriptionIsCancelled={this.props.subscriptionIsCancelled}
          subscriptionStatus={this.props.subscriptionStatus}
          subscriptionStatusString={this.state.subscriptionStatusString}
          subscriptionId={this.props.subscriptionId}
          trialEnd={this.state.trialEndDate}
          etsyIsConnected={this.props.etsyToken ? 'Yes' : 'No'}
          etsyToken={this.props.etsyToken}
          errorMessage={this.state.errorMessage}
          successMessage={this.state.successMessage}
          onClose={this.removeModal}
          onCancelSubscription={() =>
            this.interactor.handleCancelSubscription({
              email: this.props.userEmail,
              subscriptionId: this.props.subscriptionId,
            })
          }
          onRenewSubscription={() =>
            this.interactor.handleRenewSubscription({
              email: this.props.userEmail,
              subscriptionId: this.props.subscriptionId,
              subscriptionCancelAt: this.props.subscriptionIsCancelled,
            })
          }
          onSubscribePressed={this.interactor.handleSubscribePressed}
          onConnectToEtsyPressed={() =>
            this.interactor.handleConnectToEtsyPressed(this.props.userEmail)
          }
          onShowUnsubscribe={this.handleShowUnsubscribe}
          onShowResubscribe={this.handleShowResubscribe}
          onFeedbackButtonPress={this.handleFeedbackButtonPress}
        ></AccountPageView>
      </div>
    )
  }
}
