import React, { useState } from 'react'

import { useAuth } from '../../../services/contexts/AuthContext'

import * as logger from '../../../services/Logger'
import SignInModalView from './SignInModalView'
import * as firebase from '../../../services/Authentication/firebase'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
const config = require('../../../config.json')

const viewTypeEnum = Object.freeze({
  SIGNIN: 1,
  REGISTER: 2,
})

export const SignInModal = (props) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [viewType, setViewType] = useState(
    props.beginAtSignInState ? viewTypeEnum.SIGNIN : viewTypeEnum.REGISTER
  )
  const [loginError, setLoginError] = useState('')
  const [nameError, setNameError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [message, setMessage] = useState('')

  const { login, signup, handleSuccessfulRegistration, tracker } = useAuth()

  const loginWrapper = async () => {
    let loginError = ''
    logger.log('logging in')

    await login(email, '').catch((e) => {
      logger.log('error signing in')
      logger.log(e)
      loginError = e.message

      tracker.trackEvent({
        category: 'WEB Error',
        actionName: `Login: ${loginError}`,
        sendTelegram: true,
      })

      setLoginError(loginError)
    })
  }

  const registerWrapper = async () => {
    let loginError = ''

    await signup(email, password).catch((e) => {
      logger.log('error registering')
      logger.log(e)
      loginError = e.message

      tracker.trackEvent({
        category: 'WEB Error',
        actionName: `Signup: ${loginError}`,
        sendTelegram: true,
      })

      setLoginError(loginError)
    })
    logger.log('finished registering. time to save data')
    handleSuccessfulRegistration({ email: email, name: name })
  }

  const handleSubmit = () => {
    if (submitButtonIsDisabled()) {
      return
    }

    if (viewType === viewTypeEnum.SIGNIN) {
      // Check if the user has logged in before
      const usersRef = firebase.firestore.collection('users')
      const userQuery = usersRef.where('email', '==', email)

      userQuery
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            // User has never logged in before
            toast.warn('After register your account, Sign in here!', {
              position: 'top-right',
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            })
          } else {
            // User has logged in before, do sign-in

            var url = ''
            if (config.USE_LIVE_SITE_REDIRECT[config.VALUE_KEY] === true) {
              url = `https://${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}/login`
            } else {
              url = `http://localhost:3000/login`
            }

            const actionCodeSettings = {
              url: url,
              handleCodeInApp: true,
            }
            firebase.auth
              .sendSignInLinkToEmail(email, actionCodeSettings)
              .then(() => {
                console.log(
                  `An email has been sent to ${email}. Please follow the link to sign in.`
                )
                setMessage(
                  `An email has been sent to ${email}. Please follow the link to sign in.`
                )
                window.localStorage.setItem('emailForSignIn', email)
              })
              .catch((error) => {
                console.log(`Error sending email: ${error.message}`)
                setMessage(`Error sending email: ${error.message}`)
              })
          }
        })
        .catch((error) => {
          console.error('Error checking user data:', error)
          toast.error(`Error checking user data: ${error}`)
        })
    }

    if (viewType === viewTypeEnum.REGISTER) {
      registerWrapper()
    } else {
      loginWrapper()
    }
  }

  const nameIsValid = (text) => {
    if (text.length < 2) {
      return 'Name must be at least two characters'
    }
    return
  }

  const emailIsValid = (email) => {
    if (viewType === viewTypeEnum.SIGNIN) {
      return
    }

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (re.test(String(email).toLowerCase())) {
      return
    } else {
      return 'Please enter a valid email'
    }
  }

  const passwordIsValid = (password) => {
    if (viewType === viewTypeEnum.SIGNIN) {
      return
    }

    // const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/
    // if (re.test(String(password))) {
    if (password.length >= 6) {
      return
    } else {
      return 'Password must be at least 6 characters long'
    }
  }

  const handleNameChange = (text) => {
    const validationError = nameIsValid(text)
    setName(text)
    setNameError(validationError ? validationError : '')
    setLoginError('')
  }
  const handleEmailChange = (text) => {
    const validationError = emailIsValid(text)

    setEmail(text)
    setEmailError(validationError ? validationError : '')
    setLoginError('')
  }
  const handlePasswordChange = (text) => {
    const validationError = passwordIsValid(text)

    setPassword(text)
    setPasswordError(validationError ? validationError : '')
    setLoginError('')
  }

  const errorNotificationText = () => {
    if (nameError !== '') {
      return nameError
    } else if (emailError !== '') {
      return emailError
    } else if (passwordError !== '') {
      return passwordError
    } else if (loginError !== '') {
      return loginError
    }

    return ''
  }

  const toggleState = () => {
    let newState
    if (viewType === viewTypeEnum.REGISTER) {
      newState = viewTypeEnum.SIGNIN
    } else {
      newState = viewTypeEnum.REGISTER
    }

    setViewType(newState)
    setLoginError('')
    setNameError('')
    setEmailError('')
    setPasswordError('')
  }

  const submitButtonIsDisabled = () => {
    if (viewType === viewTypeEnum.REGISTER) {
      const disabled =
        name === '' ||
        nameError !== '' ||
        email === '' ||
        emailError !== '' ||
        password === '' ||
        passwordError !== ''
      // logger.log(`submit should be disabled: ${disabled}`)
      return disabled
    } else {
      const disabled = email === ''
      // logger.log(`submit should be disabled: ${disabled}`)
      return disabled
    }
  }

  return (
    <SignInModalView
      viewType={viewType}
      nameError={nameError}
      emailError={emailError}
      passwordError={passwordError}
      submitButtonIsDisabled={submitButtonIsDisabled()}
      errorNotificationText={errorNotificationText()}
      onClose={props.handleClose}
      onNameChange={handleNameChange}
      onEmailChange={handleEmailChange}
      onPasswordChange={handlePasswordChange}
      onSubmit={handleSubmit}
      onToggleState={toggleState}
      signInWithGoogle={props.signInWithGoogle}
      message={message}
    ></SignInModalView>
  )
}
