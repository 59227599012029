import React, { PureComponent } from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import lockIcon from '../../../img/lockedIcon-2.png'

import * as logger from '../../../services/Logger'
import './TableButtons.css'

class TableButtonsComponent extends PureComponent {
  handleExport = () => {
    logger.log('export pressed')

    this.props.tracker.trackEvent({
      category: 'Button',
      actionName: 'Export Pressed',
      sendTelegram: true,
    })

    if (this.props.hasPremiumAccess) {
      this.props.onSaveToCSVPressed()
    } else {
      this.props.onSubscribeButtonPressed()
    }
  }

  handleExtractTags = () => {
    logger.log('export pressed')
    this.props.tracker.trackEvent({
      category: 'Button',
      actionName: 'Extract Tags Pressed',
      sendTelegram: true,
    })

    if (this.props.hasPremiumAccess) {
      this.props.onExtractTagsPressed()
    } else {
      this.props.onSubscribeButtonPressed()
    }
  }

  extractTagsButton = () => {
    return (
      <div
        className={`action-button ${
          this.props.buttonsEnabled ? 'blue-button' : 'blue-button-disabled'
        }`}
        onClick={this.props.buttonsEnabled ? this.handleExtractTags : undefined}
      >
        Extract Tags{' '}
        {this.props.hasPremiumAccess === false &&
          this.props.shouldShowPlaceholder === false && (
            <img src={lockIcon} alt="" className="extract-tags-lock-icon"></img>
          )}
      </div>
    )
  }

  exportButton = () => {
    return (
      <div
        className={`action-button ${
          this.props.buttonsEnabled ? 'blue-button' : 'blue-button-disabled'
        }`}
        onClick={this.props.buttonsEnabled ? this.handleExport : undefined}
        disabled
      >
        Export{' '}
        {this.props.hasPremiumAccess === false &&
          this.props.shouldShowPlaceholder === false && (
            <img src={lockIcon} alt="" className="extract-tags-lock-icon"></img>
          )}
      </div>
    )
  }

  render() {
    return (
      <div className="">
        <div className="action-buttons mx-auto">
          {
            this.extractTagsButton() //config.ENABLE_ETSY_API_USE[config.VALUE_KEY] === true &&
          }
          {this.exportButton()}
        </div>
      </div>
    )
  }
}

export default TableButtonsComponent
