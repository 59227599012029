import React from 'react'

import sheerba from '../../../img/sheerba2.png'
import detective from '../../../img/Detective_Shiba.png'
import play from './play.png'
import playLine from './play_line.png'

import ReactGA from 'react-ga'

import { Button, Container } from 'react-bootstrap'
import { useAuth } from '../../../services/contexts/AuthContext'

import './Header.css'

export const Header = (props) => {
  const { tracker } = useAuth()

  const handleOnClick = () => {
    tracker.trackEvent({
      category: 'Event',
      actionName: 'Get Extension Pressed',
      sendTelegram: true,
    })
    ReactGA.event({
      category: 'Button',
      action: 'Header CTA',
    })
  }
  const handleEBookClick = () => {
    tracker.trackEvent({
      category: 'Event',
      actionName: 'Show Get Ebook Pressed',
      sendTelegram: true,
    })
    ReactGA.event({
      category: 'Button',
      action: 'Get ebook',
    })
    props.onShowForm()
  }
  const handleOnVideoLearnMoreClick = () => {
    tracker.trackEvent({
      category: 'Event',
      actionName: 'Watch Video Pressed',
      sendTelegram: true,
    })
    ReactGA.event({
      category: 'Button',
      action: 'Learn More Button',
    })
    props.onWatchVideoPressed()
  }
  return (
    <section className="home-header">
      <Container>
        <row className="row justify-content-center">
          <div className="col-11 col-md-5 col-lg-6 order-first order-md-last text-center my-auto">
            <img alt="" src={detective} className="home-header-detective" />
          </div>
          <div className="col-12 col-md-7 col-lg-6 text-center pt-3">
            <div className="col-12 col-sm-8 text-center  mt-5 mb-3 pt-5 mx-auto">
              <img alt="" src={sheerba} className="w-100" />
            </div>
            {/* <div className="home-deader-subtitle ch1 txt-dark-blue mt-5 pt-5">
          Sheerba
        </div> */}
            <div className="home-deader-subtitle ch3 txt-blue mb-5">
              The secret to successful Etsy product research
            </div>
            <div className="col-12 col-md-8 mx-auto">
              <div
                className="btn-dark-blue btn-large"
                onClick={handleEBookClick}
              >
                Learn How to Research Free Ebook
              </div>
            </div>
            <div
              className="row my-4 pt-4 justify-content-center txt-blue header-video-section"
              // href="https://www.youtube.com/watch?v=xSagtxtVU5M&t=1s&ab_channel=Sheerba"
              // target="_blank"
              // rel="noopener noreferrer"
              onClick={handleOnVideoLearnMoreClick}
            >
              <img alt="" src={play} className="" />
              <img
                alt=""
                src={playLine}
                className="my-auto mx-2 d-none d-xl-block col-md-4"
              />
              <div className="my-3 my-sm-auto col-auto">WATCH VIDEO DEMO</div>
            </div>
            {/* <div className="row my-4 pt-4 justify-content-center" onClick> */}
            {/* </div> */}
          </div>
        </row>
      </Container>
    </section>
  )
}

// <div className="container text-center my-auto">
// <img
//   alt=""
//   src={detective}
//   height="300"
//   className="d-inline-block align-top"
// />
// <div height="300">
//   <img
//     alt=""
//     src={sheerba}
//     className="d-inline-block align-top w-responsive w-50"
//   />
// </div>

//   <h3 className="mb-5">
// <em>The secret to successful Etsy product research</em>
//   </h3>
// <a
//   className="btn btn-cta-secondary btn-xl js-scroll-trigger urgent m-1"
//   onClick={handleOnClick}
//   href="https://chrome.google.com/webstore/detail/sheerba/effgadpnajejiggfiimjjnkhaggcgdfb"
//   target="_blank"
//   rel="noopener noreferrer"
// >
//   Get the Tool Today Free!
//   {/* 🥳 */}
// </a>
//   <Button
//     className="btn btn-xl js-scroll-trigger m-1"
//     onClick={handleEBookClick}
//   >
//     Learn How to Research - Free eBook
//     {/* <span role="img">😎</span> */}
//   </Button>
//   {props.size === 'lg' ? (
// <a
//   className="btn btn-dark btn-xl js-scroll-trigger my-3"
//   href="https://www.youtube.com/watch?v=xSagtxtVU5M&t=1s&ab_channel=Sheerba"
//   target="_blank"
//   rel="noopener noreferrer"
//   onClick={handleOnVideoLearnMoreClick}
// >
//   Watch Video Demo
// </a>
//   ) : (
//     <div></div>
//   )}
// </div>
// <div className="overlay "></div>
