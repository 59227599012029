import React, { PureComponent } from 'react'

import { Footer } from '../../components/Footer/Footer'
import { PricingHeader } from './Pricing/PricingHeader'
import PricingFeatures from './Pricing/PricingFeatures'
import PricingFAQ from './Pricing/PricingFAQ'
import SubscribeModal from '../../components/Duplicated/Payment/PriceComponent'

import './PricingPageView.css'
import ResubscribeModal from '../AccountPage/Resubscribe/ResubscribeModal'

import background from '../../img/payment_background.png'
import FurtherQuestionsComponent from './Pricing/FurtherQuestions'

export class PricingPageView extends PureComponent {
  render() {
    return (
      <div>
        {this.props.showSubscribe && (
          <SubscribeModal
            onSubscriptionAdded={this.props.onSubscriptionAdded}
            onCustomerCreatedSubscriptionFailed={
              this.props.onCustomerCreatedSubscriptionFailed
            }
            email={this.props.email}
            onClose={this.props.onClose}
          ></SubscribeModal>
        )}
        {this.props.showResubscribe && (
          <ResubscribeModal
            onClose={this.props.onClose}
            renewSubscription={this.props.onRenewSubscription}
          ></ResubscribeModal>
        )}
        {/* <img
          src={background}
          alt=""
          // className='past-chart-insufficient-data-img'
        ></img> */}
        <PricingHeader
          onGetStartedPressed={this.props.onGetStartedPressed}
          onRenewPressed={this.props.onRenewPressed}
          onSubscriptionAdded={this.props.onSubscriptionAdded}
        ></PricingHeader>
        <PricingFeatures></PricingFeatures>
        <PricingFAQ></PricingFAQ>
        <FurtherQuestionsComponent></FurtherQuestionsComponent>
        <Footer></Footer>
      </div>
    )
  }
}
