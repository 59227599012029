import React, { Component } from 'react'
import { Container } from 'react-bootstrap'

import './Features.css'

import shuttle from './shuttle 1.png'
import trophy from './trophy 1.png'
import computer from './XMLID 1988.png'

export default class Features extends Component {
  feature = (icon, title, description) => {
    return (
      <div className="col-12 col-sm-10 col-md-7 col-lg-5 col-xl-4 feature-item">
        <div className="mx-auto">
          <img
            src={icon}
            alt={`${icon}`}
            className="mt-4 feature-icon px-3"
          ></img>
          <div className="mt-4 mb-3 feature-title">{title}</div>
          <div className="feature-description mb-0 mx-3">{description}</div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <section className="feature-section text-center" id="features">
        <Container>
          <div className="text-left text-center text-sm-left ml-sm-5 mb-4 my-sm-0">
            <div className="ch2 ml-0 ml-sm-5 txt-white">
              Get ahead of the competition
            </div>
            <div className="mt-3 ml-0 ml-sm-5 mb-5 cp txt-white">
              Now you'll know your efforts will pay off
            </div>
            <div className="row justify-content-center mt-5">
              {this.feature(
                shuttle,
                'Crush it with Data',
                "See what products are selling and what's not. Then focus on what will dominate."
              )}
              {this.feature(
                trophy,
                'Scope Out New Markets',
                'Quickly get the pulse on a new market and the opportunity to focus on.'
              )}
              {this.feature(
                computer,
                'Easy to Use',
                'It could not be easier. Simply perform your search and then click the Chrome Extension button!'
              )}
            </div>
          </div>
        </Container>
      </section>
    )
  }
}
