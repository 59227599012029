import React, { Component } from 'react'

import './RatingBadge.css'

class RatingBadge extends Component {
  extraClass = () => {
    if (this.props.extraClass) {
      return this.props.extraClass
    } else {
      return ''
    }
  }

  render() {
    if (this.props.value === '') {
      return <div></div>
    }

    let badgeType

    if (this.props.value === 10) {
      badgeType = 'ten'
    } else if (this.props.value === 9) {
      badgeType = 'nine'
    } else if (this.props.value === 8) {
      badgeType = 'eight'
    } else if (this.props.value === 7) {
      badgeType = 'seven'
    } else if (this.props.value === 6) {
      badgeType = 'six'
    } else if (this.props.value === 5) {
      badgeType = 'five'
    } else if (this.props.value === 4) {
      badgeType = 'four'
    } else if (this.props.value === 3) {
      badgeType = 'three'
    } else if (this.props.value === 2) {
      badgeType = 'two'
    } else {
      badgeType = 'one'
    }
    return (
      <div
        onClick={this.props.onBadgePress}
        className={`${badgeType} ${this.extraClass()}`}
      >
        {this.props.value}
      </div>
    )
  }
}

export default RatingBadge
