export class LoggedOutInteractor {
  presenter

  // constructor({ etsyToken, etsySecret, url, onDetailPage }) {
  //     this.etsyToken = etsyToken
  //     this.etsySecret = etsySecret
  //     this.onDetailPage = onDetailPage
  //   }

  getData(interactorData) {
    // ... do interactor things

    const presenterData = {
      url: interactorData.url,
    }

    this.presenter.getData(presenterData)
  }
}
