const TAG_COUNT = 13

export default function exportToCSV(dataArray) {
  // console.log('export to csv')
  // console.log(dataArray)
  let csv = []

  let titles = []
  let fields = []

  titles.push(
    'No.',
    'Title',
    'Number of Days Listed',
    'Monthly Estimated Sales',
    'Monthly Estimated Revenue',
    'Average Views Per Day',
    'Number of Times Viewed',
    'Bestseller',
    'Number in Carts',
    'Favorers',
    'Ratings for Listing',
    'Price',
    'Original Price',
    'Sale Percentage',
    'Number Available',
    'Is Ad',
    'Shipping Special',
    'Shop Name',
    'Shop Rating',
    'Ratings for Shop',
    'Image Link',
    'Detail Page Link'
  )
  fields.push(
    'rowNumber',
    'title',
    'daysSinceCreated',
    'monthlySalesEstimate',
    'monthlyRevenueEstimate',
    'averageViewsPerDay',
    'views',
    'bestseller',
    'interestedCount',
    'num_favorers',
    'listingRatingCount',
    'price',
    'originalPrice',
    'salePercentage',
    'quantity',
    'isAd',
    'shippingSpecial',
    'shopName',
    'storeRating',
    'storeRatingCount',
    'image',
    'link'
  )

  // console.log('lists:')
  // console.log(titles)
  // console.log(fields)

  addTagHeadersToTitles(titles)
  csv.push(titles.join(','))

  //loops through rows
  dataArray.forEach((row) => {
    let dataRow = []
    fields.forEach((field) => {
      let parsedValue = row[field]

      if (field === 'interestedCount' && parsedValue === '20+') {
        parsedValue = '>20'
      }
      if (Object.prototype.toString.call(parsedValue) === '[object String]') {
        // Replace all double quotes with two double quotes
        parsedValue = parsedValue.replace(/"/g, `""`)
        // if (parsedValue === '20+') {
        //   parsedValue = '"20+'
        // }

        // If value contains comma, new-line or double-quote, enclose in double quotes
        parsedValue = /[",\n]/.test(parsedValue)
          ? `"${parsedValue}"`
          : parsedValue
      }

      dataRow.push(parsedValue)
    })

    addTagsToDataRow(dataRow, row)

    var combinedRow = dataRow.join(',')

    if (combinedRow !== '') {
      csv.push(`${combinedRow}`)
    }
  })

  let joinedCSV = csv.join('\n')
  downloadCSV(joinedCSV, 'Results.csv')

  function downloadCSV(csv, filename) {
    let csvFile
    let downloadLink

    // CSV file
    csvFile = new Blob([csv], {
      type: 'text/csv',
    })

    // Download link
    downloadLink = document.createElement('a')

    // File name
    downloadLink.download = filename

    // Create a link to the file
    downloadLink.href = window.URL.createObjectURL(csvFile)

    // Hide download link
    downloadLink.style.display = 'none'

    // Add the link to DOM
    document.body.appendChild(downloadLink)

    // Click download link
    downloadLink.click()
  }
}

const addTagHeadersToTitles = (titles) => {
  for (let i = 1; i <= TAG_COUNT; i++) {
    titles.push(`Tag ${i}`)
  }
}

const addTagsToDataRow = (dataRow, row) => {
  for (let i = 0; i < TAG_COUNT; i++) {
    if (i < row.tags.length) {
      dataRow.push(`${row.tags[i]}`)
    } else {
      dataRow.push(`""`)
    }
  }
}
