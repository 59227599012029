import React from 'react'

import './FeatureCTA.css'
import circle from './circle.png'
import illustration from './illustration.png'
import { Container } from 'react-bootstrap'

const FeatureCTA = (props) => {
  return (
    <section>
      <Container>
        <div className="my-5">
          <div className="feature-cta-area m-auto row justify-content-center">
            {/* Image area */}
            <div className="col-12 col-md-4 col-lg-4 my-5 order-first order-md-last text-center d-none d-md-block">
              <img
                className="feature-circle my-0 "
                src={circle}
                alt="circle"
              ></img>
              <img
                className="feature-illustration my-0 "
                src={illustration}
                alt="illustration"
              ></img>
            </div>
            {/* Text Area */}
            <div className="col-12 col-md-8 col-lg-8 text-center mt-md-3">
              <div className="txt-white ch3 mt-4 mb-3 ">
                Find Winning Products
              </div>
              <div className="mt-2 mb-4 txt-white cp">
                Bring the best products to the top and immediately find what's
                selling
              </div>
              <a
                href="#features"
                className="m-auto w-75 w-md-25  d-none d-lg-block btn-white"
              >
                <div className="">Features</div>
              </a>
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default FeatureCTA
