import React, { Component, useEffect } from 'react'
import { AffiliateTopCTA } from './AffiliateTopCTA/AffiliateTopCTA'
import AffiliateDescription from './AffiliateDescription/AffiliateDescription'
import AffiliateCommission from './AffiliateCommission/AffiliateCommission'
import AffiliateFAQ from './AffiliateFAQ'
import { AffiliateFooterCTA } from './GetStarted/AffiliateFooterCTA'
import { Footer } from '../../components/Footer/Footer'
import { useAuth } from '../../services/contexts/AuthContext'

export const Affiliate = (props) => {
  const { isLoading, tracker } = useAuth()

  useEffect(() => {
    tracker.trackEvent({
      category: 'View',
      actionName: 'Affiliate',
      sendTelegram: true,
    })
  }, [tracker])

  if (isLoading) {
    return <div></div>
  }

  return <AffiliateComponent></AffiliateComponent>
}

class AffiliateComponent extends Component {
  render() {
    return (
      <div>
        <AffiliateTopCTA></AffiliateTopCTA>
        <AffiliateDescription></AffiliateDescription>
        <AffiliateCommission></AffiliateCommission>
        <AffiliateFAQ></AffiliateFAQ>
        <AffiliateFooterCTA></AffiliateFooterCTA>
        <Footer></Footer>
      </div>
    )
  }
}
