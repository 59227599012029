import React, { useState } from 'react'

import { useAuth } from '../../../services/contexts/AuthContext'

import becomeAffiliate from './BecomeAffiliate.png'
import './AffiliateTopCTA.css'

import AffiliatePopup from '../Affiliate/AffiliatePopup'
import { Container } from 'react-bootstrap'

export const AffiliateTopCTA = () => {
  const { tracker } = useAuth()
  const [showForm, setShowForm] = useState(false)

  const handleOnClick = () => {
    tracker.trackEvent({
      category: 'Event',
      actionName: 'Top CTA - Affiliate Apply',
      sendTelegram: true,
    })
    setShowForm(true)
  }

  const handleAffiliatePopupClose = () => {
    setShowForm(false)
  }

  const form = () => {
    return <AffiliatePopup onClose={handleAffiliatePopupClose}></AffiliatePopup>
  }

  const page = () => {
    return (
      <section className="content-page affiliate-top-cta">
        <Container>
          <div className="row">
            <div className="col-12 col-lg-6 my-auto">
              <img
                src={becomeAffiliate}
                alt="affiliate"
                className="affiliate-top-image"
              ></img>
            </div>
            <div className="col-12 col-lg-6 text-center text-lg-left mx-auto my-auto">
              <div className="ch1 txt-dark-blue">
                Become a Sheerba Affiliate
              </div>
              <div className="cp txt-dark-blue mt-2 pb-4">
                Earn 50% recurring commission for as long as your referral
                remains a Sheerba customer.
              </div>
              <div className="mt-4">
                <a
                  className="btn-orange btn-orange-old-text mt-4"
                  href="#apply"
                  onClick={handleOnClick}
                >
                  Apply Now
                </a>
              </div>
            </div>
          </div>
        </Container>
      </section>
    )
  }

  return (
    <div>
      {showForm && form()}
      {page()}
    </div>
  )
}
