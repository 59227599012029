import React, { useState } from 'react'

import { useAuth } from '../../../services/contexts/AuthContext'

import illustration from './Illustration.png'

import AffiliatePopup from '../Affiliate/AffiliatePopup'
import { Container } from 'react-bootstrap'

export const AffiliateFooterCTA = () => {
  const { tracker } = useAuth()
  const [showForm, setShowForm] = useState(false)

  const handleOnClick = () => {
    tracker.trackEvent({
      category: 'Event',
      actionName: 'Footer CTA - Affiliate Apply',
      sendTelegram: true,
    })
    setShowForm(true)
  }

  const handleAffiliatePopupClose = () => {
    setShowForm(false)
  }

  const form = () => {
    return <AffiliatePopup onClose={handleAffiliatePopupClose}></AffiliatePopup>
  }

  const page = () => {
    return (
      <div className="my-5">
        <Container>
          <div className="further-questions-area m-auto row text-center">
            <div className="col-12 col-lg-4 col-xl-3 my-5 mb-sm-5">
              <img className="" src={illustration} alt="illustration"></img>
            </div>
            <div className="col-12 col-lg-8 col-xl-9 my-auto">
              <div className="txt-white ch3 mb-4 mt-lg-2 text-center">
                Let's Get Started!
              </div>
              <div className="txt-white cp mb-4 mt-lg-2 text-center">
                Earn 50% recurring commission for as long as your referral
                remains a Sheerba Customer.
              </div>
              <div className="m-auto w-75 w-sm-25 text-center">
                <a
                  className="btn-black mt-5"
                  href="#apply"
                  onClick={handleOnClick}
                >
                  Apply Now
                </a>
              </div>
            </div>
          </div>
        </Container>
      </div>
    )
  }

  return (
    <div>
      {showForm && form()}
      {page()}
    </div>
  )
}
// <section className="affiliate-footer-thing bg-light">
//   <div className="container text-center">
//     <div className="row">
//       <div className="col-lg-10 mx-auto">
//         <h3>LET'S GETS STARTED</h3>
//         <h2 className="pt-4">Become a Sheerba Affiliate Today</h2>
// <a
//   className="btn btn-dark btn-xl js-scroll-trigger mt-5"
//   href="#apply"
//   onClick={handleOnClick}
// >
//   Apply Now
// </a>
//       </div>
//     </div>
//   </div>
// </section>
