// import ReactGA from 'react-ga'
import * as backend from '../BackendService'

// const trackingId = 'UA-167165096-1'
// ReactGA.initialize(trackingId, {
//   gaOptions: {
//     allowLinker: true
//   }
// })
// ReactGA.ga('require', 'linker')

let email
let name

export const set = ({ userName, userEmail }) => {
  // if (user) {
  //   ReactGA.set({
  //     userId: user.uid,
  //     // any data that is relevant to the user session
  //     // that you would like to track with google analytics
  //   })
  // } else {
  //   ReactGA.set({
  //     userId: null,
  //   })
  // }
  name = userName
  email = userEmail
}

export const sendTelegram = ({ event }) => {
  if (name) {
    backend.sendEventTelegram({
      event: event,
      displayName: name,
      email: email,
    })
  } else {
    backend.sendEventTelegram({
      event: event,
    })
  }
}

export const trackEvent = ({ category, actionName, sendTelegram }) => {
  // ReactGA.event({
  //   category: category,
  //   action: actionName,
  // })

  if (sendTelegram) {
    if (name) {
      backend.sendEventTelegram({
        category: category,
        event: actionName,
        displayName: name,
        email: email,
      })
    } else {
      backend.sendEventTelegram({
        category: category,
        event: actionName,
      })
    }
  }
}

export const pageview = (page) => {
  // ReactGA.pageview(page)
}
