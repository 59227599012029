import React, { Component } from 'react'

// import * as tracker from '../../../Services/Tracker'
import { PremiumFeaturesComponent } from '../../../components/Duplicated/PremiumFeaturesComponent/PremiumFeaturesComponent'

import backgroundSrc from '../../../img/SignInBackground.png'

import './ResubscribeModal.css'

class ResubscribeModal extends Component {
  render = () => {
    // tracker.pageview('resubscribe-modal')
    return (
      <div>
        <div className="resubscribe-modal-background"></div>
        <div className="resubscribe-modal-container">
          <div
            className="resubscribe-modal"
            style={{
              backgroundImage: backgroundSrc,
              backgroundPositionY: 'left',
            }}
          >
            <div className="resubscribe-close" onClick={this.props.onClose}>
              ✕
            </div>
            <div className="resubscribe-title">Resubscribe</div>
            <div className="resubscribe-body-1">
              This will continue your subscription with no lapse of access. You
              will not be billed until the next pay-period.
            </div>
            <div className="resubscribe-body-2">
              You will re-gain access to all these Premium Features:
            </div>
            <div className="features-background">
              <PremiumFeaturesComponent></PremiumFeaturesComponent>
            </div>
            <div
              className="resubscribe-button blue-button"
              onClick={this.props.renewSubscription}
            >
              Yes, I want to resubscribe.
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ResubscribeModal
