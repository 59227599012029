import React, { PureComponent } from 'react'
import TagComponent from '../TagComponent/TagComponent'

import './TagsView.css'

export class TagsView extends PureComponent {
  render() {
    const {
      hasPremiumAccess,
      tags,
      onTagPressed,
      handleCopyAllPressed,
      copyAllButtonText,
      onSubscribePress,
      lockedIconSrc,
    } = this.props

    if (hasPremiumAccess) {
      if (tags.length <= 0) {
        return (
          <div>
            <div className='tagComponentTagTitleArea'>
              <div className='tagComponentTagTitleText'>SEARCH TAGS</div>
            </div>
            <div className='tagComponentTagEmptyText'>No Tags Used on this listing.</div>
          </div>
        )
      }

      let tagComponents = []

      const numberOfPossibleTags = 13
      for (let i = 0; i < numberOfPossibleTags; i++) {
        const tag = tags[i]
        if (tag) {
          tagComponents.push(
            <TagComponent
              tag={tag}
              key={tag}
              onClick={onTagPressed}
              copyOnClick={true}
            ></TagComponent>
          )
        }
      }

      return (
        <div>
          <div className='tagComponentTagTitleArea'>
            <div className='tagComponentTagTitleText'>SEARCH TAGS</div>
          </div>
          <div className='tagComponent-tag-section'>{tagComponents}</div>
          <div
            className='btn tagComponentTagCopyAllButton'
            onClick={() => handleCopyAllPressed(tags)}
          >
            {copyAllButtonText}
          </div>
        </div>
      )
    } else {
      let tagComponents = []

      const numberOfPossibleTags = 13
      for (let i = 0; i < numberOfPossibleTags; i++) {
        tagComponents.push(
          <TagComponent
            tag={'Subscribe for Tags'}
            key={i}
            onClick={onSubscribePress}
            copyOnClick={false}
          ></TagComponent>
        )
      }

      return (
        <div className='tagComponentMainArea'>
          <div className='tagComponentTagTitleArea'>
            <div className='tagComponentTagTitleText'>
              SEARCH TAGS{' '}
              <img
                src={lockedIconSrc}
                alt=''
                className='tagComponent-tags-locked-icon'
              ></img>
            </div>
          </div>
          <div className='tagComponent-tag-section'>{tagComponents}</div>
          <div
            className='btn tagComponentTagCopyAllButton'
            onClick={onSubscribePress}
          >
            {/* <div className='tagComponent-tag-section-blur'></div> */}
            {copyAllButtonText}
          </div>
        </div>
      )
    }
  }
}
