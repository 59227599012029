export class FeedbackModalPresenter {
  view;

  // constructor({ etsyToken, etsySecret, url, onDetailPage }) {
  //     this.etsyToken = etsyToken
  //     this.etsySecret = etsySecret
  //     this.onDetailPage = onDetailPage
  //   }

  getData = (presenterData) => {
    // ... do presenter things

    const viewProps = {
      messages: presenterData.messages,
    };

    this.view.setViewState(viewProps);
  };

  updateText = (text) => {
    this.view.setText(text)
  }
}
