import React, { useState } from 'react'

import './ExpanderTextComponent.css'

import minus from '../../img/minus.png'
import plus from '../../img/plus.png'

const ExpanderTextComponent = (props) => {
  const [expanded, setExpanded] = useState(props.expanded ?? false)

  const handlePress = () => {
    setExpanded(!expanded)
  }

  return (
    <div className="m-auto text-left">
      <div className="cp expander-header" onClick={handlePress}>
        <img
          className="mx-3 expander-image"
          src={expanded ? minus : plus}
          alt={expanded ? 'minus' : 'plus'}
        ></img>
        {props.header}
      </div>
      {expanded && <div className="cp2 expander-body">{props.children}</div>}
    </div>
  )
}

export default ExpanderTextComponent
