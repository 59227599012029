import {
  makeDisplayObject,
  numberWithCommas,
  currencyFormatter,
} from '../../services/Duplicated/DisplayObjectFactory'

import * as logger from '../../services/Logger'

export class TagSearchPresenter {
  view

  // Example Loading Flow
  // constructor({ etsyToken, etsySecret, url, onDetailPage }) {
  //     this.etsyToken = etsyToken
  //     this.etsySecret = etsySecret
  //     this.onDetailPage = onDetailPage
  //   }
  // getData = (presenterData) => {
  //   // ... do presenter things
  //   const viewProps = {
  //     urlString: presenterData.url,
  //   }
  //   this.view.setViewState(viewProps)
  // }

  clearResults = () => {
    this.view.clearResults()
  }

  indicateRatingIsLoading = (isLoading) => {
    this.view.setRatingLoading({ isLoading: isLoading })
  }

  indicateChartsAreLoading = (areLoading) => {
    this.view.setChartLoading({ areLoading: areLoading })
  }
  indicateAveragesAreLoading = (areLoading) => {
    this.view.setAveragesLoading(areLoading)
  }

  showResult = (result) => {
    const formatted = makeDisplayObject(result)
    this.view.showResult(formatted)
  }

  showChartData = ({ pastChartData, predictedChartData }) => {
    this.view.showChartData({
      pastChartData: pastChartData,
      predictedChartData: predictedChartData,
      areLoading: false,
    })
  }

  showLoadingCounts = ({ totalCount, totalProcessed }) => {
    const loadingString = `Retrieving data: ${totalProcessed} of ${totalCount}`
    this.view.setLoadingString({
      loadingString: loadingString,
    })
    this.view.showResultsAndAveragesInTable(false)
  }
  hideLoadingCount = () => {
    this.view.setLoadingString({ loadingString: '' })
    this.view.showResultsAndAveragesInTable(true)
  }

  showMainPageData = (mainPageData) => {
    if (
      mainPageData.totalSales === 0 &&
      mainPageData.averageFavorers === 0 &&
      mainPageData.averagePrice === 0
    ) {
      const displayPageData = {
        averageFavorers: '-',
        averagePrice: '-',
        totalSales: '-',
      }

      this.view.showPageData(displayPageData)
    }

    const avgFavs = numberWithCommas(Math.round(mainPageData.averageFavorers))
    const avgPrice = currencyFormatter.format(
      mainPageData.averagePrice.toFixed(2)
    )
    const salesString = `${mainPageData.totalSales}`

    // logger.log('presenter data')
    // logger.log(mainPageData.averagePrice)
    // logger.log(mainPageData.averagePrice.toFixed(2))
    // logger.log(avgPrice)

    const displayPageData = {
      averageFavorers: avgFavs,
      averagePrice: avgPrice,
      totalSales: salesString,
    }

    this.view.showPageData(displayPageData)
  }

  setMainSearchRating = ({ rating }) => {
    this.view.showMainSearchTermRating({ rating: rating })
    this.view.setRatingLoading({ isLoading: false })
  }

  setError = (error) => {
    this.view.setError(error)
  }
}
