import React, { useEffect } from 'react'

import { validateEtsyOAuth } from '../services/BackendService.js'
import { useAuth } from '../services/contexts/AuthContext.js'

const queryString = require('query-string')

export const OAuthComplete = (props) => {
  const { userEmail } = useAuth()

  useEffect(() => {
    const submit = async () => {
      // console.log('oauth complete page')
      if (userEmail == null) return

      // console.log(userEmail)

      const query = props.location
      // console.log(props)

      if (query) {
        // console.log('query')
        // console.log(query)
        const parsed = queryString.parse(query.search)
        // console.log(parsed)

        // console.log('get local storage items')
        const tempToken = localStorage.getItem('etsy_temp_token')
        const tempSecret = localStorage.getItem('etsy_temp_secret')
        // console.log(tempToken)
        // console.log(tempSecret)

        const response = await validateEtsyOAuth({
          tempToken: tempToken,
          tempSecret: tempSecret,
          newToken: parsed.oauth_token,
          verifier: parsed.oauth_verifier,
          email: userEmail,
        })
        // console.log('got response on success')
        // console.log(response)

        if (response && response.success) {
          localStorage.removeItem('etsy_temp_token')
          localStorage.removeItem('etsy_temp_secret')
        }
      } else {
        console.log('query not found')
      }
    }
    submit()
  }, [props, userEmail])

  return <div>Done</div>
}
